<div class="{blockClass} {className}">
    {#if label}
        <label class='{element(blockClass, "text-input-label")} {labelClassName}'>{label}</label> 
    {/if}
    <div class={element(blockClass, "control")}>
        <div class='{element(blockClass, "text-input")} {statusClass}'>
            <i class="{element(blockClass, "icon")} fas fa-user-alt"></i> 
            <div class={element(blockClass, 'labels')}>
                {#if contacts && contacts.length > 0}
                    {#each contacts as contact, index}
                        <Label test_tag={`${test_tag}-${index}`} className={element(blockClass, 'label')}>
                            {displayText(displayType, contact)}
                            <i data-test={`${test_tag}-remove-${index}`} class='fas fa-times' on:click='remove(event, contact.id)' />
                        </Label>
                    {/each}
                {:else}
                    <div class={element(blockClass, 'placeholder')}>{placeholder}</div>
                {/if}
            </div>
        </div>
        <Button :test_tag on:click="showDialog(event)" color="normal" className={element(blockClass, 'add-button')}>
            <i class='fas fa-user-plus'></i>
        </Button>
    </div>
    {#if statusMessage}
    <div data-test={`${test_tag}-error`} class='{element(blockClass, "status-message")} {statusClass}'>
        {#if iconOutside}
        <i class="{calculatedIconClass} {statusClass}"></i> 
        {/if}
        {statusMessage}
    </div>
    {/if}
</div>

{#if showAddContact}
<Modal ref:modal {trapFocus} {initialFocusElement} on:result="hideDialog(event)" on:hidden="set({ showAddContact: false })">
    <AddContact {filterPhoneNumber} {selected} on:dismiss="refs.modal.dismiss(event)" on:cancel="refs.modal.dismiss(event)" />
</Modal>
{/if}


<script>
 import { ContactDisplayType } from '@shanewwarren/aqlcloud-shared-types';
 import { element } from 'Services/bem';
 import { getClasses } from 'Services/colors';
 import AddContact from './AddContact';
 import { Button, Modal, Label } from 'Shared';

 export default {
 	components: {
 		AddContact,
 		Button,
 		Label,
 		Modal,
 	},
 	computed: {
 		selected: ({ contacts }) => {
			const map = {};
 			contacts.forEach(contact => {
				map[contact.id] = true;
			});
			return map;
 		},
 		classes: ({ className }) => {
 			return getClasses(className, 'icon-text', 'icon-text-left');
 		},
 		calculatedIconClass: ({ statusClass }) => {
 			if (statusClass === 'success') {
 				return 'fas fa-check';
 			} else if (statusClass === 'error') {
 				return 'fas fa-times';
 			}

 			return '';
 		},
 	},
 	data() {
 		return {
 			blockClass: 'contact-group',
 			contacts: [],
 			displayType: ContactDisplayType.EmailAddress,
 			containerClassName: '',
 			labelClassName: '',
 			className: '',
 			id: '',
 			disabled: false,
 			label: '',
 			statusClass: '',
 			statusMessage: '',
 			placeholder: 'Choose one or more contacts',
			showAddContact: false,
			filterPhoneNumber: false,
			test_tag: ''
 		};
 	},
 	helpers: {
 		element,
 		displayText: (displayType, contact) => {
 			return contact.fullName;
 		},
 	},
 	methods: {
 		remove(event, contactId) {
 			const { contacts } = this.get();
 			const filtered = contacts.filter(c => c.id !== contactId);
 			this.set({ contacts: filtered });
 		},
 		showDialog(event) {
 			event.preventDefault();
 			this.set({ showAddContact: true });
 		},
 		hideDialog(payload) {
 			if (!payload) {
				const { contacts } = this.get();
 				this.set({ contacts, showAddContact: false });
 				return;
 			}

 			const contactIds = Object.keys(payload).filter(key => payload[key]);
 			const updatedContacts = this.store.getContactsFromIds(contactIds);
 			this.set({ contacts: updatedContacts, showAddContact: false });
 		},
 	},
 };
</script>