<div class='table'>
    <div class='table-legend'>
        <div class='table-filter'>
            <TextInput bind:value="filter" 
                        test_tag='filter'
                        containerClassName='table-search-filter'
                        iconClass='fas fa-search' 
                        placeholder='Search' />
           
        </div>
        {#if !selectOnly}
        <div class='table-unselected-panel'>
            <Button iconClass="fas fa-plus" 
            className="contacts__add-btn"
                    color='primary' 
                    on:click="fire('add', event)">Add Contact</Button>
        </div>
        {/if}
    </div>
    <div class={selectOnly ? "contact-table select" : "contact-table highlight"}>
        <div class='list-header'>
            {#if selectOnly}
            <div class='list-header-column enable'>
                <Checkbox test_tag='select-all' 
                    on:change='selectAll(event.target.checked)' 
                    hideLabels={true} 
                    checked={allSelected} />
            </div>
            {/if}

            <div on:click='sort("fullName")' class='list-header-column'>
                Name
                <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.Name.key} />
            </div>

            <div on:click='sort("emailAddress")'
                class='list-header-column'>
                Email
                <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.Email.key} />
            </div>

            <div class='list-header-column'>
                Phone Number
            </div>


            {#if !selectOnly}
            <div class='list-header-column'>
            </div>
            {/if}
        </div>
        <div class='list-body'>
            {#if filtered.length > 0}
            {#each filtered as item, index}
            <div class="{classes('list-row', active(selected[item.id], true))}">
                {#if selectOnly}
                <div class='list-column enable'>
                    <Checkbox on:change='selectItem(event, item.id)' 
                        test_tag={`contact-${index}`}
                        hideLabels={true} 
                        checked={selected[item.id]} />
                </div>
                {/if}

                <div title={item.fullName} class='list-column full-name'>
                    {#if selectOnly}
                        {item.fullName}
                    {:else}
                        <a href='#' on:click="fire('edit', { event: event, id: item.id})">{item.fullName}</a>
                    {/if}
                </div>

                <div title={item.emailAddress} class='list-column email'>
                    {item.emailAddress}
                </div>


                <div title={formatPhoneNumber(item.phoneNumber)} class='list-column'>
                    {formatPhoneNumber(item.phoneNumber)}
                </div>

                
                {#if !selectOnly}
                <div class='list-column'>
                    {#if !item.isUser}
                    <i on:click="fire('delete', { id: item.id })" class='row-delete-btn fas fa-trash-alt'></i>
                    {/if}
                </div>
                {/if}

            </div>
            {/each}
            {:else}
            <div class='no-search-results'>
            No contacts were found.
            </div>
            {/if}
        </div>
    </div>
</div>

<script>
    import { classes } from 'Services/classes';
    import { active } from 'Services/helpers';
    import {
    	ColumnType,
    	filterItems,
    	getSelectedCount,
    	getAllSelected,
    	selectItem,
    	selectAll,
    	sort,
    } from 'Services/table';
    import { formatPhoneNumber } from 'Services/formatters';

    import {
    	Button,
    	Checkbox,
    	TextInput,
    	TableHeaderSortable
    } from 'Shared';

    const Keys = {
    	Name: 'fullName',
    	PhoneNumber: 'phoneNumber',
    	Email: 'emailAddress',
    	IsUser: 'isUser',
    };

    const SortableColumns = {
    	Name: {
    		key: 'fullName',
    		type: ColumnType.String,
    	},
    	PhoneNumber: {
    		key: 'phoneNumber',
    		type: ColumnType.String,
    	},
    	Email: {
    		key: 'emailAddress',
    		type: ColumnType.String,
    	},
    	IsUser: {
    		key: 'isUser',
    		type: ColumnType.String,
    	},
    };

    export default {
    	components: {
    		Button,
    		Checkbox,
    		TextInput,
    		TableHeaderSortable
    	},
    	computed: {
    		selectedCount: ({ selected }) => getSelectedCount(selected),
    		allSelected: ({ filtered, selected }) => getAllSelected(filtered, selected),
    		filtered: ({ items, filter, sortKey, sortAsc, sortColumns }) =>
    			filterItems(
    				items,
    				SortableColumns.Name.key,
    				filter,
    				sortKey,
    				sortAsc,
    				sortColumns
    			),
    	},
    	data() {
    		return {
    			blockClass: 'device-table',
    			filter: '',
    			selected: {},
    			showCreateButton: true,
    			items: [],
    			selectOnly: false,
    			selectedMap: {},
    			sortKey: Keys.Name,
    			sortAsc: true,
    			sortColumns: SortableColumns,
    		};
    	},
    	methods: {
    		selectItem,
    		selectAll,
    		sort,
    	},
    	helpers: {
    		classes,
    		active,
    		formatPhoneNumber
    	},
    };
</script>