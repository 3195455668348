import { IReport, IUpdateReport } from '@shanewwarren/aqlcloud-shared-types';
import { EmptyStringValidator } from '../validators';
import { Form, FormField, Field } from '../field';

export interface ReportNameForm extends Form {
    values: {
        name: string;
    },
    fields: {
        name: Field<string>;
    }
}

export const fromModel = (model?: IReport): ReportNameForm => {
    let name = '';
    if (model) {
        name = model.name;
    }

    return {
        valid: false,
        type: 'form',
        values: {
            name
        },
        fields: {
            name: FormField({
                key: 'name',
                validators: [EmptyStringValidator()]
            })
        }
    };
}

export function toModel(model: IUpdateReport, form: ReportNameForm) {
    model.name = form.values.name;
    return model;
}