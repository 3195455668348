import { ITrigger, IUpdateAlert } from '@shanewwarren/aqlcloud-shared-types';
import { NumberItemsValidator, NumberIdSelect } from '../validators';
import { Form, FormField, Field } from '../field';

export interface ChannelSelectForm extends Form {
	values: {
		channelIds: NumberIdSelect;
	};
	fields: {
		channelIds: Field<NumberIdSelect>;
	};
}

export const fromModel = (model?: ITrigger): ChannelSelectForm => {
	let channelIds = {};
	if (model) {
		model.channels.forEach(channel => (channelIds[channel.id] = true));
	}

	return {
		valid: false,
		type: 'form',
		values: {
			channelIds,
		},
		fields: {
			channelIds: FormField({
				key: 'channelIds',
				validators: [NumberItemsValidator()],
			}),
		},
	};
};

export function toModel(model: IUpdateAlert, form: ChannelSelectForm) {
	let trigger: any = {};
	if (model.trigger) {
		trigger = model.trigger;
	}

	trigger.channelIds = Object.keys(form.values.channelIds)
		.filter(key => form.values.channelIds[key])
		.map(key => parseInt(key));

	model.trigger = trigger;

	return model;
}
