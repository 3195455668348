const AlertDetails = require("./AlertDetails.html").default;
const { ChannelSelect, ChannelTrigger } = require('./Channel/index');
const { Notification } = require('./Notification/index');
const { SystemTrigger, SystemSelect } = require('./System/index');

export {
    AlertDetails,
    ChannelSelect, ChannelTrigger,
    SystemTrigger, SystemSelect,
    Notification
};
