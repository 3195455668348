import * as distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import * as format from 'date-fns/format';

import {
	AckStatus,
	CommandStatus,
	CommandType,
	EventType,
	ICommand,
	ReportScheduleType,
	ReportType,
} from '@shanewwarren/aqlcloud-shared-types';

import { SessionType } from '@shanewwarren/aqlcloud-shared-types/dist/types/constants';
import { hasValue } from './number';
import { isNumber } from 'util';
import { format as phoneFormat } from 'phone-formatter';

const NO_VALUE = '---';

export function formatPhoneNumber(phoneNumber: string | null) {
	if (!phoneNumber) {
		return NO_VALUE;
	}

	return phoneFormat(phoneNumber, '(NNN) NNN-NNNN');
}

export function formatTruthyToYesOrNo(value: any | null | undefined) {
	if (value) {
		return 'Yes';
	}
	return 'No';
}

export function formatAckStatus(value: AckStatus) {
	if (!value) {
		return NO_VALUE;
	}

	switch(value) {
		case AckStatus.Yes:
			return 'Yes';
		case AckStatus.No: 
			return 'No';
		case AckStatus.Pending:
			return "Pending";
		case AckStatus.Overriden: 
			return "Overridden"
		default:
			return NO_VALUE;
	}


	return value === AckStatus.Yes ? 'Yes' : 'No';
}

export function scheduleTypeFormatter(scheduleType) {
	switch (scheduleType) {
		case ReportScheduleType.Finite:
			return 'Discrete count';
		case ReportScheduleType.Infinite:
			return 'Continously';
		case ReportScheduleType.Download:
			return 'Download';
	}
}

export function reportTypeFormatter(reportType) {
	if (reportType === ReportType.FTP) {
		return 'FTP';
	} else if (reportType === ReportType.Email) {
		return 'Email';
	} else {
		return 'Download';
	}
}

export function formatFileSize(bytes, decimalPoint) {
	if (bytes == 0) return '0 Bytes';
	var k = 1024,
		dm = decimalPoint || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatDate(date) {
	return format(date, 'MM/DD/YY');
}

export function formatTime(date) {
	return format(date, 'h:mm:ss A');
}

export function formatLogFile(date) {
	return format(date, 'MM-DD-YY_h-mm-ssA');
}

export function formatLogDate(date) {
	return format(date, 'YYYY/MM/DD HH:mm:ss.SSS');
}
export function formatLogDateFile(date, serialNumber: string | undefined) {
	if (serialNumber) {
		return `${serialNumber}-${format(date, 'YYYY-MM-DD-HH-mm-ss-SSS')}.dlf`;
	}
	return format(date, 'YYYY-MM-DD-HH-mm-ss-SSS.dlf');
}

export function toFixed(num, precision) {
	return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(
		precision
	);
}

export function reverse(str) {
	return str
		.split('.')
		.reverse()
		.join('.');
}

export function truncateNumber(
	num: number | string | null,
	places: number = 2
) {
	if (!hasValue(num)) {
		return NO_VALUE;
	}

	if (typeof num === 'string') {
		const intNumber = parseInt(num);
		let number = parseFloat(num);
		if (intNumber !== number) {
			let value = toFixed(number, places);
			if (value === '-0.00') {
				value = '0.00';
			}
			return value;
		}

		return intNumber;
	}

	if (!isNumber(num)) {
		return '--';
	}

	return num.toFixed(places);
}
export function nullNumber(number: number | null) {
	return hasValue(number) ? number : NO_VALUE;
}

export function formatNullDateTime(date?: Date) {
	if (!date) {
		return NO_VALUE;
	}
	return `${formatDate(date)} ${formatTime(date)}`;
}

export function logCategory(value: EventType) {
	switch (value) {
		case EventType.DataTransfer:
			return 'Data';
		case EventType.Alert:
			return 'Alert';
		case EventType.ControlStatus:
			return 'Control / Status';
		case EventType.Reprogramming:
			return 'Reprogramming';
		case EventType.Report:
			return 'Report';
		case EventType.Exceptions:
			return 'Exceptions';
		case EventType.Debug:
			return 'Debug';
		case EventType.RemoteControl:
			return 'Remote Control';
		default:
			return NO_VALUE;
	}
}

export function handleNull(value: any) {
	if (!hasValue(value)) {
		return NO_VALUE;
	}
	return value;
}

export function relativeDate(date) {
	return (
		distanceInWordsToNow(date, {
			includeSeconds: true,
		}) + ' ago'
	);
}

export function capitalizeFirstLetter(word: string) {
	return word.toLowerCase().replace(/^\w/, c => c.toUpperCase());
}

export function getSaveVerb(id?: any, pastTense: boolean = false) {
	const verb: string = id ? 'update' : 'create';
	return pastTense ? verb + 'd' : verb;
}

export function getSaveMessage(noun: string, id?: any) {
	const verb = getSaveVerb(id, true);
	return `${capitalizeFirstLetter(noun)} ${verb}`;
}

export function getSaveErrorMessage(noun: string, id?: any) {
	const verb = getSaveVerb(id, false);
	return `Failed to ${verb} ${noun.toLowerCase()}.  Please contact the system administrator.`;
}

export function getGenericSaveMessage(verb: string, noun: string) {
	return `Failed to ${verb} ${noun.toLowerCase()}.  Please contact the system administrator.`;
}

export function formatAqlTime(
	timestamp: Date,
	timezone: number,
	isAql: boolean
) {
	if (!timestamp) {
		return NO_VALUE;
	}

	if (isAql) {
		return `${formatDate(timestamp)} ${formatTime(timestamp)}`;
	}

	const now = new Date(timestamp);
	const timezoneDiff = timezone - now.getTimezoneOffset();
	const updated = new Date(now.getTime() + -1 * timezoneDiff * 60 * 1000);
	return `${formatDate(updated)} ${formatTime(updated)}`;
}

export function getCommandStatus(command?: ICommand): string {
	if (!command) {
		return NO_VALUE;
	}

	switch (command.status) {
		case CommandStatus.Completed:
			return 'Completed';
		case CommandStatus.Failed:
			return 'Failed';
		case CommandStatus.Queued:
			return 'Queued';
		case CommandStatus.Sent:
			return 'Sent';
	}
}

export function getCommandText(command?: ICommand): string {
	if (!command) {
		return NO_VALUE;
	}

	switch (command.eventType) {
		case EventType.ControlStatus: {
			if (command.commandType === CommandType.SendStopSession) {
				return 'Stop Session';
			} else if (command.commandType === CommandType.SendStartSession) {
				return 'Start Session';
			}
			
		}
		case EventType.Debug: {
			if (command.commandType === CommandType.SendGetDebugFlags) {
				return 'Debug Log Download'
			}
			if (command.commandType === CommandType.SendClearDebugLogs) {
				return 'Clear Debug Logs';
			}
		}
		case EventType.RemoteControl: {
			if (command.commandType === CommandType.SendUpdateRemoteVariables) {
				return "Update Remote Controls";
			}
		}
		case EventType.Reprogramming: {
			if (command.commandType === CommandType.SendProgramNet) {
				return "Upload Program Net"
			}

			if (command.commandType === CommandType.SendFirmware) {
				return "Upload Firmware"
			}
		}
		case EventType.Exceptions: {
			if (command.commandType === CommandType.ReceiveExceptionAsserted) {
				return "Exception Asserted";
			} else if (command.commandType === CommandType.ReceiveExceptionNegated) {
				return "Exception Negated";
			} else if (command.commandType === CommandType.SendUpdatedAssertExceptionStatus) {
				return "Assert Exception";
			}  else if (command.commandType === CommandType.SendUpdatedBlockExceptionStatus) {
				return "Block Exception";
			}
		}
	}

	return '--- (not populated)';
}

export function getLastAlertDisplay(
	alertStatus: {
		awaitingAcks?: number;
		lastAlert?: string;
		lastAlertWaitingAck?: boolean;
		lastAlertDate?: Date;
	}
) {
	if (!alertStatus) {
		return NO_VALUE;
	}
	const { lastAlert, lastAlertDate, lastAlertWaitingAck} = alertStatus;
	if (lastAlert && lastAlertDate && lastAlertWaitingAck) {
		return "Alert";
	} else if (lastAlertDate) {
		return "OK";
	} 
	return NO_VALUE;
}


export const formatTemperature = (temperature?: number) => {
	if (!temperature && temperature !== 0) {
		return NO_VALUE;
	}

	return `${(1.8 * temperature + 32).toFixed(2)}° F`;
}

export const formatVoltage = (voltage?: number) => {
	if (!voltage && voltage !== 0) return NO_VALUE;

	return `${voltage.toFixed(2)} Vdc`;
}

export const formatMinutes = (minutes?: number) => {
	if (!minutes && minutes !== 0) return NO_VALUE;
	if (minutes === 1) {
		return `${minutes} minute`;
	}
	return `${minutes} minutes`;
}

export const formatMemoryMode = (memoryMode?: SessionType) => {
	if (!memoryMode) return NO_VALUE;
	if (memoryMode === SessionType.LinearTime) {
		return "Log to Full";
	}
	return "Rotary";
}