
const Route = {
	Alerts: '/alerts',
	CreateSystemAlert: '/alerts/system/new',
	CreateChannelAlert: '/alerts/channel/new',
	EditAlert: '/alerts/:id/edit',
	Groups: '/groups',
	CreateGroup: '/groups/new',
	EditGroup: '/groups/:id/edit',
	Devices: '/devices',
	Reports: '/reports',
	CreateReport: '/reports/new',
	EditReport: '/reports/:id/edit',
	Log: '/log',
	Accounts: '/accounts*',
	AccountsContacts: '/accounts/contacts',
	AccountsCompany: '/accounts/company',
	AccountsCustomize: '/accounts/customize',
	AccountsBilling: '/accounts/billing',
	AccountsStorage: '/accounts/storage',
	Dashboard: '/dashboard',
};

export default Route;