import {
	IAlert,
	ITrigger,
	TriggerType,
} from '@shanewwarren/aqlcloud-shared-types';
import {
	AlertDetails,
	Notification,
	SystemTrigger,
	SystemSelect,
	ChannelSelect,
	ChannelTrigger,
} from '../components/App/Alerts/steps';

import {
	fromModel as deviceSelectFromModel,
	toModel as deviceSelectToModel,
} from './forms/trigger/deviceSelect';
import {
	fromModel as deviceTriggerFromModel,
	toModel as deviceTriggerToModel,
} from './forms/trigger/deviceTrigger';
import {
	fromModel as channelSelectFromModel,
	toModel as channelSelectToModel,
} from './forms/trigger/channelSelect';
import {
	fromModel as channelTriggerFromModel,
	toModel as channelTriggerToModel,
} from './forms/trigger/channelTrigger';
import {
	fromModel as notificationFromModel,
	toModel as notificationToModel,
} from './forms/trigger/notification';
import {
	fromModel as alertDetailsFromModel,
	toModel as alertDetailsToModel,
} from './forms/trigger/alertDetails';

export function isChannelAlert(trigger: ITrigger): boolean {
	return (
		(trigger && trigger.type && trigger.type === TriggerType.Threshold) ||
		trigger.type == TriggerType.WindowComparator
	);
}

const ChannelSelectKey = 'channelSelect';
const ChannelTriggerKey = 'channelTrigger';
const SystemSelectKey = 'systemSelect';
const SystemTriggerKey = 'systemTrigger';
const NotificationKey = 'notification';
export const AlertDetailsKey = 'alertDetails';

const AlertDetailsTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Alert Name',
			key: AlertDetailsKey,
			component: AlertDetails,
		}
	);
const SelectDevicesTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Select Devices',
			key: SystemSelectKey,
			component: SystemSelect,
		}
	);

const SystemTriggerTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Trigger',
			key: SystemTriggerKey,
			component: SystemTrigger,
		}
	);

const NotificationTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Notification',
			key: NotificationKey,
			component: Notification,
		}
	);

const SelectChannelsTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Select Channels',
			key: ChannelSelectKey,
			component: ChannelSelect,
		}
	);

const ChannelTriggerTab = () =>
	Object.assign(
		{},
		{
			displayName: 'Trigger',
			key: ChannelTriggerKey,
			component: ChannelTrigger,
		}
	);

const ChannelTabs = () => [
	AlertDetailsTab(),
	SelectChannelsTab(),
	ChannelTriggerTab(),
	NotificationTab(),
];

const SystemTabs = () => [
	AlertDetailsTab(),
	SelectDevicesTab(),
	SystemTriggerTab(),
	NotificationTab(),
];

export function getTabs(alertType: string) {
	return isSystemAlert(alertType) ? SystemTabs() : ChannelTabs();
}

// Steps
const AlertDetailsStep = () =>
	Object.assign(
		{},
		{
			label: 'Alert Name',
			description: 'Specify a name for the alert.',
			component: AlertDetails,
			valid: false,
		}
	);

const SelectDevicesStep = () =>
	Object.assign(
		{},
		{
			label: 'Select Devices',
			description: 'Pick one or more devices to alert on.',
			component: SystemSelect,
			valid: false,
		}
	);

const SystemTriggerStep = () =>
	Object.assign(
		{},
		{
			label: 'Trigger',
			description: 'Specify the trigger type.',
			component: SystemTrigger,
			valid: false,
		}
	);

const SelectChannelsStep = () =>
	Object.assign(
		{},
		{
			label: 'Select Channels',
			description: 'Pick one or more channels to alert on.',
			component: ChannelSelect,
			valid: false,
		}
	);

const ChannelTriggerStep = () =>
	Object.assign(
		{},
		{
			label: 'Trigger',
			description: 'Specify the trigger type.',
			component: ChannelTrigger,
			valid: false,
		}
	);

const NotificationStep = () =>
	Object.assign(
		{},
		{
			label: 'Notification',
			description: 'Specify the notification type.',
			component: Notification,
			valid: false,
		}
	);

export const ChannelSteps = () => [
	AlertDetailsStep(),
	SelectChannelsStep(),
	ChannelTriggerStep(),
	NotificationStep(),
];

export const SystemSteps = () => [
	AlertDetailsStep(),
	SelectDevicesStep(),
	SystemTriggerStep(),
	NotificationStep(),
];

export function getSteps(alertType: string) {
	return isSystemAlert(alertType) ? SystemSteps() : ChannelSteps();
}

export function isSystemAlert(alertType: string) {
	return alertType === 'system';
}

export function tabSelectedForm(
	tab,
	detailsForm,
	selectForm,
	triggerForm,
	notificationForm
) {
	switch (tab.key) {
		case AlertDetailsKey:
			return detailsForm;
		case SystemSelectKey:
		case ChannelSelectKey:
			return selectForm;
		case SystemTriggerKey:
		case ChannelTriggerKey:
			return triggerForm;
		case NotificationKey:
			return notificationForm;
	}
}

export function getAlertForms(alert: IAlert) {
	const systemConfig = alert.trigger.systemConfig
		? alert.trigger.systemConfig
		: undefined;

	const detailsForm = alertDetailsFromModel(alert);
	const notificationForm = notificationFromModel(alert.notification);

	const selectForm = isChannelAlert(alert.trigger)
		? channelSelectFromModel(alert.trigger)
		: deviceSelectFromModel(alert.trigger);

	const triggerForm = isChannelAlert(alert.trigger)
		? channelTriggerFromModel(alert.trigger)
		: deviceTriggerFromModel(systemConfig);

	return { detailsForm, notificationForm, selectForm, triggerForm };
}

export function newAlertForms(alertType: string) {
	const detailsForm = alertDetailsFromModel();
	const notificationForm = notificationFromModel();

	const selectForm = isSystemAlert(alertType)
		? deviceSelectFromModel()
		: channelSelectFromModel();

	const triggerForm = isSystemAlert(alertType)
		? deviceTriggerFromModel()
		: channelTriggerFromModel();

	return { detailsForm, notificationForm, selectForm, triggerForm };
}

export function getAlert(
	alertType,
	detailsForm,
	notificationForm,
	selectForm,
	triggerForm
) {
	const alert: any = {};

	alertDetailsToModel(alert, detailsForm);

	isSystemAlert(alertType)
		? deviceSelectToModel(alert, selectForm)
		: channelSelectToModel(alert, selectForm);

	isSystemAlert(alertType)
		? deviceTriggerToModel(alert, triggerForm)
		: channelTriggerToModel(alert, triggerForm);

	notificationToModel(alert, notificationForm);

	return alert;
}

export function updateAlert(
	alertType,
	alert,
	key,
	detailsForm,
	notificationForm,
	selectForm,
	triggerForm
) {
	switch (key) {
		case AlertDetailsKey:
			alertDetailsToModel(alert, detailsForm);
			break;
		case SystemSelectKey:
			deviceSelectToModel(alert, selectForm);
			break;
		case ChannelSelectKey:
			channelSelectToModel(alert, selectForm);
			break;
		case SystemTriggerKey:
			deviceTriggerToModel(alert, triggerForm);
			break;
		case ChannelTriggerKey:
			channelTriggerToModel(alert, triggerForm);
			break;
	}
	notificationToModel(alert, notificationForm);

	// normalize the trigger and notification values.
	if (key !== ChannelSelectKey) {
		alert.trigger.channelIds = alert.trigger.channels.map(
			channel => channel.id
		);
	}

	if (key !== SystemSelectKey) {
		alert.trigger.deviceIds = alert.trigger.devices.map(device => device.id);
	}

	return alert;
}
