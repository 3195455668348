export function loadComponent(dynamicModule, newParams): any {
    let component = undefined;
    const params = newParams || {};
    if (dynamicModule.preload) {
        dynamicModule.preload({ params }).then(() => {
            component = dynamicModule.default;
        });
    } else {
        component = dynamicModule.default;
    }
    return { component, params };
}

export function trackRoute(route: any) {
    return route.state || route.uri;
}