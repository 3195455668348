export function modifier(element, modifier) {
    return `${element}--${modifier}`;
}

export function modifierValue(element, modifier, value) {
    return `${modifier(element, modifier)}-${value}`;
}

export function element(block, element, ...extras) {
    if (extras && extras.length > 0) {
        return `${block}__${element}-${extras.join('-')}`;
    }
    else {
        return `${block}__${element}`;
    }
}