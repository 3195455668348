import { IChannel, IDevice, IGroup } from '@shanewwarren/aqlcloud-shared-types';

export type GroupChannelResult = {
	channel: IChannel;
	device: IDevice;
	group: IGroup;
};

export type DeviceChannelResult = {
	device: IDevice;
	channel: IChannel;
};

export type GroupDeviceResult = {
	device: IDevice;
	groups: IGroup[];
};

export interface IDeviceWithGroupNames extends IDevice {
	groupNames?: string[];
}

export function getChannelDeviceMap(devices: IDevice[]) {
	const map: { [channelId: string]: IDevice } = {};
	getDeviceChannelResults(devices).forEach(
		({ channel, device }) => (map[channel.id.toString()] = device)
	);
	return map;
}

export function getDeviceGroupNameMap(
	devices: IDevice[],
	groups: IGroup[]
): { [deviceId: string]: string[] } {
	return devices.reduce((acc, prev) => {
		const names = groups
			.filter(group => !group.isSpecial && group.devices.findIndex(d => d.id === prev.id) >= 0)
			.map(group => group.name);

		return {
			...acc,
			[prev.id]: names,
		};
	}, {});
}

export function getGroupChannelResult(group: IGroup): GroupChannelResult[] {
	const results: GroupChannelResult[] = [];
	for (const device of group.devices) {
		if (device.activeSeries) {
			for (const channel of device.activeSeries.channels) {
				results.push({ channel, device, group });
			}
		}
	}
	return results;
}

export function getGroupChannelResults(groups: IGroup[]) {
	let results: GroupChannelResult[] = [];
	for (const group of groups) {
		results = results.concat(getGroupChannelResult(group));
	}
	return results;
}

export function getDeviceChannelResults(devices: IDevice[]) {
	let results: DeviceChannelResult[] = [];
	for (const device of devices) {
		results = results.concat(getDeviceChannelResult(device));
	}
	return results;
}

export function getDeviceChannelResult(device: IDevice) {
	const results: DeviceChannelResult[] = [];
	if (device.activeSeries) {
		for (const channel of device.activeSeries.channels) {
			results.push({ channel, device });
		}
	}
	return results;
}

export function allSelected(items, selected) {
	const results = getDeviceChannelResults(items);

	const matches = Object.keys(selected)
		.filter(key => selected[key])
		.map(key => {
			return results.some(result => {
				return result.channel.id.toString() === key;
			});
		})
		.filter(val => val);

	return matches.length === results.length && results.length > 0;
}

export function allSelectedClick(items, selected, checked) {
	const results = getDeviceChannelResults(items);
	results.forEach(({ channel }) => (selected[channel.id] = checked));

	return Object.assign({}, selected);
}

export function combineGroups(groups: IGroup[], regex: RegExp | null = null) {
	const deviceMap: { [deviceId: string]: GroupDeviceResult } = {};
	groups.forEach(group => {
		group.devices.forEach(device => {
			if (!deviceMap[device.id]) {
				deviceMap[device.id] = { device, groups: [] };
			}
			deviceMap[device.id].groups.push(group);
		});
	});

	return Object.keys(deviceMap).map(key => {
		const { device, groups } = deviceMap[key];
		const groupNames = groups.map(g => g.name);
		const deviceCopy = Object.assign({ groupNames }, device);
		if (device.activeSeries) {
			deviceCopy.activeSeries = Object.assign({}, device.activeSeries);
			deviceCopy.activeSeries.channels = device.activeSeries.channels.filter(
				channel => {
					if (regex === null) {
						return true;
					}
					return (
						device.loggerName.match(regex) !== null ||
						channel.pointName.match(regex) !== null ||
						groupNames.some(groupName => groupName.match(regex) !== null)
					);
				}
			);
		}

		return deviceCopy;
	});
}

export function combineGroupsNoChannels(
	groups: IGroup[],
	regex: RegExp | null = null
) {
	const deviceMap: { [deviceId: string]: GroupDeviceResult } = {};

	// Assign each group the device belongs to.
	groups.forEach(group => {
		group.devices.forEach(device => {
			if (!deviceMap[device.id]) {
				deviceMap[device.id] = { device, groups: [] };
			}
			deviceMap[device.id].groups.push(group);
		});
	});

	// Get unique groups
	const uniqueGroups: { [key: string]: IDeviceWithGroupNames[] } = {};
	Object.keys(deviceMap).forEach(key => {
		const { device, groups } = deviceMap[key];
		const groupNames = groups
			.map(g => g.name)
			.sort((g1, g2) => g1.localeCompare(g2))
			.join(',');
		if (!uniqueGroups[groupNames]) {
			uniqueGroups[groupNames] = [];
		}
		uniqueGroups[groupNames].push(device);
	});

	const deviceList = Object.keys(uniqueGroups)
		.map(groupNames => {
			const devices = uniqueGroups[groupNames];
			const deviceCopy: IDeviceWithGroupNames = Object.assign(
				{ groupNames: groupNames.split(',') },
				devices[0]
			) as IDeviceWithGroupNames;
			return [deviceCopy].concat(devices.slice(1));
		})
		.reduce((flat, val) => flat.concat(val), []);

	return deviceList.filter(device => {
		if (regex === null) {
			return true;
		}
		return device.loggerName.match(regex) !== null;
	});
}

export function filterGroupChannels(groups: IGroup[], regex: RegExp) {
	return groups.map(group => {
		const groupCopy = Object.assign({}, group);
		groupCopy.devices = group.devices.map(device => {
			const deviceCopy = Object.assign({}, device);
			if (device.activeSeries) {
				deviceCopy.activeSeries = Object.assign({}, device.activeSeries);
				deviceCopy.activeSeries.channels = device.activeSeries.channels.filter(
					channel => {
						return channel.pointName.match(regex) !== null;
					}
				);
			}
			return deviceCopy;
		});
		return groupCopy;
	});
}
