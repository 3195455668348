export enum AlertType {
    Device = 'system',
    Channel = 'channel'
};

export const ErrorDelay = 6000;

export const DevicesEventType = {
    ProgramNet: 'ProgramNet',
    Firmware: 'Firmware',
    RemoteVariables: 'RemoteVariables',
    Debug: 'Debug',
    Session: 'Session',
    ViewTriggers: 'ViewTriggers',
    DeviceInfo: 'DeviceInfo'
};

export const TimeType = {
    AqlTime: false,
    PcTime: true
};