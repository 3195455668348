import { IUpdateTheme, ITheme } from '@shanewwarren/aqlcloud-shared-types';
import { EmptyStringValidator } from '../validators';
import { FormField, Field, Form } from '../field';


export interface ThemeForm extends Form {
    values: {
        bannerLineOne: string;
        bannerLineTwo: string;
    },
    fields: {
        bannerLineOne: Field<string>;
        bannerLineTwo: Field<string>;
    }
}

export const fromModel = (model: ITheme): ThemeForm => {

    let bannerLineOne: string = model ? model.bannerLineOne : '';
    let bannerLineTwo: string = model ? model.bannerLineTwo : '';
    return {
        type: 'form',
        valid: false,
        values: {
            bannerLineOne,
            bannerLineTwo
        },
        fields: {
            bannerLineOne: FormField({
                key: 'bannerLineOne',
                validators: [EmptyStringValidator()]
            }),
            bannerLineTwo: FormField({
                key: 'bannerLineTwo',
                validators: [EmptyStringValidator()]
            })
        }
    };
}

export function toModel(form: ThemeForm) {
    const model: IUpdateTheme = {
        bannerLineOne: form.values.bannerLineOne,
        bannerLineTwo: form.values.bannerLineTwo
    };
    return model;
}
