<div class={blockClass}>
    {#await promise}
        <div class='spinner-block'>
            <Spinner />
        </div>
    {:then items}
		<Notification className='margin-notification'
			test_tag="channel-select-error"
			open={!fields.channelIds.valid}
			color="error">{fields.channelIds.error}</Notification>
			
        <GroupChannelTable 
            selectedChannels={values.channelIds}
            groups={$groups} 
            on:select='select(event)' 
            on:selectAll='selectAll(event)' />
    {/await}
</div>

<script>
  import { Spinner, Notification } from 'Shared';
  import GroupChannelTable from '../../Channels/GroupChannelTable';
  import { validate } from 'Services/forms/validators';
  import { getGroupChannelResults } from 'Services/model-helpers';

  export default {
  	oncreate() {
  		this.set({
  			promise: Promise.all([this.store.getDevices(), this.store.getGroups()]),
  		});
  	},
  	onupdate({ changed, current, previous }) {
  		validate(this, changed, current, previous, true, current.isEditing);
  	},
  	data() {
  		return {
  			isEditing: false,
  			form: ({ $selectedAlert }) => $selectedAlert.select,
  		};
  	},
  	computed: {
  		form: ({ selectForm }) => selectForm,
  		values: ({ form }) => form.values,
  		fields: ({ form }) => form.fields,
  	},
  	methods: {
  		select(event) {
  			const id = event.id;
  			const checked = event.checked;

  			const { values } = this.get();
  			values.channelIds[id] = checked;
  			this.set({ values });
  		},

  		selectAll(event) {
  			const { ids } = event;
  			const { values } = this.get();
  			values.channelIds = ids;
  			this.set({ values });
  		},
  	},
  	components: {
  		Spinner,
  		Notification,
  		GroupChannelTable,
  	},
  };
</script>