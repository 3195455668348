import * as plur from 'plur';

export function eq(actual, expected): boolean {
	return actual === expected;
}

export function and(first, ...extras): boolean {
	return first && extras.every(extra => extra === true);
}

export function active(actual, expected): string {
	return eq(actual, expected) ? 'active' : '';
}

export function disabled(actual, expected): string {
	return !eq(actual, expected) ? 'disabled' : '';
}

export function selected(actual, expected): string {
	return eq(actual, expected) ? 'selected' : '';
}

export function pluralize(str: string, count: number): string {
	return plur(str, count);
}
