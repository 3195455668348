const Route = {
	Login: '/login',
	Register: '/register' ,
	ForgotPassword: '/forgot' ,
	VerificationCode: '/verify' ,
	ResetPassword: '/reset' ,
	NewPasswordRequired: '/password' ,
	Changelog: '/changelog',
};

export function addRoutes(router, render) {
	router.on(Route.Login, () => import('./Login.html').then(render))
	router.on(Route.Changelog, () => import('./Changelog.html').then(render))
	router.on(Route.Register, () => import('./Register.html').then(render))
	router.on(Route.ForgotPassword, () => import('./ForgotPassword.html').then(render))
	router.on(Route.ResetPassword, () => import('./ResetPassword.html').then(render))
	router.on(Route.VerificationCode, () => import('./VerificationCode.html').then(render))
	router.on(Route.NewPasswordRequired, () => import('./NewPasswordRequired.html').then(render));
}

export default Route;
	