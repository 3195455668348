<div class="tracker">
    {#each steps as step, index}
        <Step on:change='fire("change", event)' 
            label={step.label} 
            stepNumber={index} 
            {currentStep} /> 
    {/each}
</div>


<script>
    import Step from './Step.html';

    export default {
    	data() {
    		return {
    			currentStep: 0,
    			steps: [],
    		};
    	},
    	components: {
    		Step,
    	},
    	methods: {
    		toggle(e) {
    			const { checked } = this.get();
    			this.set({
    				checked: !checked,
    			});
    		},
    	},
    };
</script>