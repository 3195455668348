<div class='{blockClass} radio-button-group vertical'>
    <form class='form'>
        {#if !isEditing}
        <h2 class='form-header'>Channel Data based Trigger</h2>
        {/if}
        <div class='form-body medium-form'>
            <div class='form-field-container'>
                <h4>Trigger Type</h4>
                <div class='image-radio-button-container'>
                    <div class='image-radio-button {selected(values.type, types.Threshold)}' 
                        on:click='changeType(types.Threshold)'
                        data-test='threshold-trigger'>
                        <i class='fas fa-tachometer-alt'></i>
                        <label>Threshold Trigger</label>
                    </div>
                    <div class='image-radio-button {selected(values.type, types.WindowComparator)}' 
                        on:click='changeType(types.WindowComparator)'
                        data-test='window-trigger'>
                        <i class='fas fa-exchange-alt'></i>
                        <label>Window Comparator Trigger</label>
                    </div>
                </div>
            </div>

            <div class='form-field-container'>
                <h4>Trigger Operation</h4>
                {#if values.type === types.Threshold}
                    {#if !fields.type.valid && fields.type.error}
                        <div data-test='threshold-error' class='form-error'>
                            {fields.type.error}
                        </div>
                    {/if}
                    <div class='flex-rows form-field'>
                        <div class='flex-row margin-b-3'>
                            <div class='inline'>Trigger notification if </div><span class='code margin-l-3'>CHANNEL DATA</span>
                            <Dropdown className='operator-dropdown margin-l-3' 
                                itemClass='operator' 
                                items={operators} 
                                test_tag="trigger-operator"
                                selected={values.triggerOperator}
                                on:select='setSelectedDropdown(event, "triggerOperator")' />
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                className='margin-l-3' 
                                placeholder='0.0' 
                                step='any'
                                test_tag="trigger-value"
                                statusClass={fields.type.valid && fields.triggerSetPoint.valid ? '' : 'error'}
                                bind:value='values.triggerSetPoint' />
                        </div>
                        <div class='flex-row margin-b-3'>
                            <div class='inline'>Reset trigger when </div><span class='code margin-l-3'>CHANNEL DATA</span>
                            <Dropdown className='operator-dropdown margin-l-3' 
                                itemClass='operator' 
                                items={operators} 
                                test_tag="reset-trigger-operator"
                                selected={values.resetTriggerOperator}
                                on:select='setSelectedDropdown(event, "resetTriggerOperator")' />
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                className='margin-l-3' 
                                placeholder='0.0' 
                                step='any'
                                test_tag="reset-trigger-value"
                                statusClass={fields.type.valid && fields.resetTriggerSetPoint.valid ? '' : 'error'}
                                bind:value='values.resetTriggerSetPoint' />
                        </div>
                    </div>
                {:else}
                    {#if !fields.type.valid && fields.type.error}
                        <div data-test='window-error' class='form-error'>
                            {fields.type.error}
                        </div>
                    {/if}
                    <div class='flex-rows form-field'>
                        <div class='flex-row margin-b-3'>
                            <div class='inline'>Trigger notification if </div><span class='code margin-l-3'>CHANNEL DATA</span>
                            <Dropdown className='operator-dropdown margin-l-3' 
                                itemClass='range' 
                                items={ranges} 
                                test_tag='trigger-operator'
                                selected={values.direction}
                                on:select='setSelectedDropdown(event, "direction")' />`
                            <div class='inline margin-l-3'>the range </div>
                        </div>
                        <div class='flex-row margin-b-3'>
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                placeholder='0.0' 
                                step='any'
                                bind:value='values.minValue'
                                test_tag='window-min'
                                statusClass={fields.type.valid && fields.minValue.valid ? '' : 'error'} />
                            <div class='inline margin-l-3'>to </div>
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                className='margin-l-3' 
                                placeholder='0.0' 
                                step='any'
                                bind:value='values.maxValue' 
                                test_tag='window-max'
                                statusClass={fields.type.valid && fields.maxValue.valid ? '' : 'error'} />
                        </div>
                        <div class='margin-t-5'>
                            {#if !fields.useHysteresis.valid}
                                <div class='flex-row margin-b-0'>
                                    <div data-test='hysteresis-error' class='form-error margin-b-0'>
                                        {fields.useHysteresis.error}
                                    </div>
                                </div>
                            {/if}

                            {#if values.useHysteresis && !fields.hysteresisValue.valid}
                                <div data-test='hysteresis-error' class='flex-row margin-b-0'>
                                    <div class='form-error margin-b-0'>
                                        {fields.hysteresisValue.error}
                                    </div>
                                </div>
                            {/if}

                            <div class='flex-row'>
                                <Checkbox checked={values.useHysteresis}
                                        test_tag='use-hysteresis'
                                        on:change='checked("useHysteresis", event.target.checked)'/>
                                <div class='inline'>Use Hysteresis of </div>
                                <TextInput isNumber={true} 
                                    test_tag='hysteresis-value'
                                    containerClassName='long-number-field' 
                                    className='margin-l-3' 
                                    placeholder='0.0' 
                                    bind:value='values.hysteresisValue' />
                            </div>
                        </div>

                        {#if values.useHysteresis}
                        <div class='flex-row margin-b-3 margin-t-5'>
                            <div class='inline'>Trigger will reset when </div>
                            <span class='code margin-l-3'>CHANNEL DATA</span>
                            <div class='inline margin-l-3'>is {windowReset} the range:</div>
                        </div>
                        <div class='flex-row margin-b-3'>
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                placeholder='0.0' 
                                step='any'
                                disabled={true}
                                test_tag='hysteresis-min'
                                value={hysterisisMin} />
                            <div class='inline margin-l-3'>to </div>
                            <TextInput isNumber={true} 
                                containerClassName='long-number-field' 
                                className='margin-l-3' 
                                placeholder='0.0' 
                                step='any'
                                disabled={true}
                                test_tag='hysteresis-max'
                                value={hysterisisMax} />
                        </div>
                        {/if}
                    </div>
                {/if}
            </div>

            <div class='form-field-container'>
                <h4>Channel Data Filter Options</h4>
                <div class='flex-rows'>
                {#if !fields.enableConsecutiveReadings.valid}
                    <div class='flex-row margin-b-0'>
                        <div data-test='consecutive-error' class='form-error margin-b-0'>
                            {fields.enableConsecutiveReadings.error}
                        </div>
                    </div>
                {/if}
                {#if values.enableConsecutiveReadings && !fields.consecutiveReadings.valid}
                    <div class='flex-row margin-b-0'>
                        <div data-test='consecutive-error' class='form-error margin-b-0'>
                            {fields.consecutiveReadings.error}
                        </div>
                    </div>
                {/if}
                <div class='flex-row form-field'>
                    <Checkbox checked={values.enableConsecutiveReadings} 
                              on:change='checked("enableConsecutiveReadings", event.target.checked)'
                              test_tag="enable-consecutive" />
                    <div class='inline'>Trigger after receipt of </div>
                    <TextInput isNumber={true}  
                        containerClassName='number-field margin-l-3' 
                        placeholder='0' 
                        test_tag="consecutive-readings" 
                        statusClass={hideConsecutiveError ? '' : 'error'}
                        bind:value='values.consecutiveReadings' />
                    <div class='inline margin-l-3'>consecutive samples meeting Trigger conditions</div>
                </div>
                {#if !fields.enableSlidingAvgSamples.valid}
                    <div class='flex-row margin-b-0'>
                        <div data-test='sliding-error' class='form-error margin-b-0'>
                            {fields.enableSlidingAvgSamples.error}
                        </div>
                    </div>
                {/if}
                {#if values.enableSlidingAvgSamples && !fields.slidingAvgSamples.valid}
                    <div class='flex-row margin-b-0'>
                        <div data-test='sliding-error' class='form-error margin-b-0'>
                            {fields.slidingAvgSamples.error}
                        </div>
                    </div>
                {/if}
                <div class='flex-row form-field'>
                    <Checkbox checked={values.enableSlidingAvgSamples} 
                            on:change='checked("enableSlidingAvgSamples", event.target.checked)'
                            test_tag="enable-sliding"/>
                    <div class='inline'>Perform Sliding Average of </div>
                    <TextInput isNumber={true}  
                        containerClassName='number-field margin-l-3' 
                        placeholder='0'
                        test_tag="sliding-average" 
                        statusClass={hideSlidingAvgError ? '' : 'error'}
                        bind:value='values.slidingAvgSamples' />
                    <div class='inline margin-l-3'>samples on Channel Data being tested</div>
                </div>
                </div>
            </div>
        </div>
    </form>
</div>



<script>
    import { TriggerType } from '@shanewwarren/aqlcloud-shared-types';
    import { TextInput, Dropdown, Checkbox } from 'Shared';
    import { OPERATORS, RANGES } from 'Services/operators';
    import { selected } from 'Services/helpers';
    import { validate } from 'Services/forms/validators';

    export default {
    	helpers: {
    		selected,
    	},
    	components: {
    		TextInput,
    		Dropdown,
    		Checkbox,
    	},
    	onupdate({ changed, current, previous }) {
    		validate(this, changed, current, previous, true, current.isEditing);
    	},
    	computed: {
    		form: ({ triggerForm }) => triggerForm,
    		values: ({ form }) => form.values,
            fields: ({ form }) => form.fields,
            windowReset: ({ values }) => {
                if (!values.direction) {
                    return '';
                }
                let direction = values.direction;
                if (direction === 'inside') {
                    direction = 'outside';
                } else {
                    direction = 'inside';
                }
                return direction;
            },
            hysterisisMin: ({ values }) => {
                if (values.direction === "inside") {
                    return values.minValue - values.hysteresisValue;
                } else {
                    return values.minValue + values.hysteresisValue;
                }
            },
            hysterisisMax: ({ values }) => {
                if (values.direction === "inside") {
                    return values.maxValue + values.hysteresisValue;
                } else {
                    return values.maxValue - values.hysteresisValue;
                }
            },
    		hideConsecutiveError: ({ fields, values }) => {
    			if (!values.enableConsecutiveReadings) {
    				return true;
    			}

    			return (
    				fields.enableConsecutiveReadings.valid &&
    				fields.consecutiveReadings.valid
    			);
    		},
    		hideSlidingAvgError: ({ fields, values }) => {
    			if (!values.enableSlidingAvgSamples) {
    				return true;
    			}

    			return (
    				fields.enableSlidingAvgSamples.valid && fields.slidingAvgSamples.valid
    			);
    		},
    	},
    	data() {
    		return {
    			blockClass: 'channel-triggers',
    			type: TriggerType.Threshold,
    			types: TriggerType,
    			operators: OPERATORS,
    			ranges: RANGES,
    			isEditing: false,
    		};
    	},
    	methods: {
    		changeType(type) {
    			const { form } = this.get();
    			form.values.type = type;
    			this.set({ form });
    		},
    		checked(key, value) {
    			const { form } = this.get();
    			form.values[key] = value;
    			this.set({ form });
            },
            setSelectedDropdown(value, key) {
                const { form } = this.get();
                form.values[key] = value;
                this.set({ form });
            }
    	},
    };

    /* https://lbi-test.manuscript.com/f/cases/388/Trigger-Channel-Threshold-Trigger-mods
    * Dave asked me to get rid of the hysterisis option.
    *
    {#if !fields.useHysteresis.valid}
        <div class='flex-row margin-b-0'>
            <div class='form-error margin-b-0'>
                {fields.useHysteresis.error}
            </div>
        </div>
    {/if}
    {#if values.useHysteresis && !fields.hysteresisValue.valid}
        <div class='flex-row margin-b-0'>
            <div class='form-error margin-b-0'>
                {fields.hysteresisValue.error}
            </div>
        </div>
    {/if}

    <div class='flex-row'>
        <Checkbox checked={values.useHysteresis}
                on:change='checked("useHysteresis", event.target.checked)'/>
        <div class='inline'>Use Hysteresis of </div>
        <TextInput isNumber={true} 
            containerClassName='long-number-field' 
            className='margin-l-3' 
            placeholder='0.0' 
            bind:value='values.hysteresisValue' />
    </div>
    */
</script>