<Route {router} {routes} />

<script>
    import { RouteComponents } from './routes'
    import { Route } from 'Shared';

    export default {
        components: {
            Route
        },
        data() {
            return {
                routes: RouteComponents
            }
        }
    }
</script>