<div class='{blockClass} {stepClass}'
     on:click='fire("change", { step: stepNumber })'>
    <div class={circleClass}>
        {#if currentStep > stepNumber}
        <i class='fas fa-check'/>
        {:else}
        {stepNumber + 1}
        {/if}
    </div>
    <div class={labelClass}>
        {label}
    </div>
</div>


<script>
    import * as BEM from 'Services/bem';
    import { StepStatus } from './status';

    const baseClass = 'step';
    const bem = {
    	blockClass: baseClass,
    	circleClass: BEM.element(baseClass, 'circle'),
    	labelClass: BEM.element(baseClass, 'label'),
    };

    const defaults = {
    	label: '',
    	stepNumber: 0,
    	currentStep: 0,
    	status: StepStatus.NotComplete,
    };

    export default {
    	computed: {
    		stepClass: ({ stepNumber, currentStep }) => {
    			if (stepNumber === currentStep)
    				return BEM.modifier(bem.blockClass, 'active');
    			if (currentStep > stepNumber) {
    				return BEM.modifier(bem.blockClass, 'complete');
    			}
    			return '';
    		},
    	},
    	data() {
    		return Object.assign({}, defaults, bem);
    	},
    };
</script>