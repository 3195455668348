<div class='{blockClass} radio-button-group vertical'>
    <form class='form'>
        {#if !isEditing}
        <h2 class='form-header'>System Health based Trigger</h2>
        {/if}
        <div class='form-body medium-form'>
            <div class='form-field-container'>
                <h4>Trigger Type</h4>
                <div class='image-radio-button-container'>
                    <div class='image-radio-button {selected(values.type, types.FailedToReport)}' 
                        data-test='failed-to-report-trigger'
                        on:click='changeType(types.FailedToReport)'>
                        <i class='fas fa-calendar-times'></i>
                        <label>Failed to Report</label>
                    </div>
                    <div class='image-radio-button {selected(values.type, types.LowVoltage)}' 
                        data-test='low-voltage-trigger'
                        on:click='changeType(types.LowVoltage)'>
                        <i class='fas fa-battery-bolt'></i>
                        <label>Low AQL Device Supply Voltage</label>
                    </div>
                    <div class='image-radio-button {selected(values.type, types.LowMemory)}' 
                        data-test='low-memory-trigger'
                        on:click='changeType(types.LowMemory)'>
                        <i class='fas fa-hdd'></i>
                        <label>Low AQL Memory</label>
                    </div>
                </div>
            </div>

            <div class='form-field-container'>
                <h4>Trigger Operation</h4>
                {#if values.type === types.FailedToReport}
                    <div class='flex-center form-field'>
                        <div class="inline">Trigger after</div>
                        <TextInput className='code-field' 
                                   isNumber={true}
                                   test_tag='comm-fail-value'
                                   statusClass={fields.commFailCount.valid ? '' : 'error'}
                                   statusMessage={fields.commFailCount.error ? fields.commFailCount.error : ''}
                                   placeholder='n' 
                                   bind:value='values.commFailCount' />
                        <div class="inline">consecutive communication failures</div>
                    </div>
                {:elseif values.type === types.LowMemory}
                    <form class='flex-center form-field'>
                        <div class="inline">Trigger if the <span class="code">Remaining Local Memory Usage</span> is <span class="code">&lt;</span></div>
                        <TextInput className='code-field' 
                                   isNumber={true}
                                   test_tag='memory-threshold-value'
                                   placeholder='20'
                                   statusClass={fields.memoryThreshold.valid ? '' : 'error'}
                                   statusMessage={fields.memoryThreshold.error ? fields.memoryThreshold.error : ''}
                                   bind:value='values.memoryThreshold' />
                        <div class="inline">%</div>
                    </form>   
                {:else}
                    <form class='flex-center form-field'>
                        <div class="inline">Trigger if the <span class="code">Device Supply Voltage</span> is <span class="code">&lt;</span></div>
                        <TextInput className='code-field' 
                                   isNumber={true}
                                   test_tag='voltage-threshold-value'
                                   placeholder='0.0'
                                   statusClass={fields.voltageThreshold.valid ? '' : 'error'}
                                   statusMessage={fields.voltageThreshold.error ? fields.voltageThreshold.error : ''}
                                   bind:value='values.voltageThreshold' />
                        <div class="inline">Vdc</div>
                    </form>
                {/if}
            </div>
        </div>
    </form>
</div>



<script>
    import { TriggerType } from '@shanewwarren/aqlcloud-shared-types';
    import { TextInput } from 'Shared';
    import { selected } from 'Services/helpers';
    import { fromModel } from 'Services/forms/trigger/deviceTrigger';
    import { validate } from 'Services/forms/validators';

    const form = fromModel();

    export default {
    	helpers: {
    		selected,
    	},
    	components: {
    		TextInput,
    	},
    	data() {
    		return {
    			blockClass: 'system-triggers',
    			types: TriggerType,
    			form: form,
    			isEditing: false,
    		};
    	},
    	onupdate({ changed, current, previous }) {
    		validate(this, changed, current, previous, true, current.isEditing);
    	},
    	computed: {
    		form: ({ triggerForm }) => triggerForm,
    		values: ({ form }) => form.values,
    		fields: ({ form }) => form.fields,
    	},
    	methods: {
    		changeType(type) {
    			const { values } = this.get();
    			values.type = type;
    			this.set({ values });
    		},
    	},
    };
</script>