import { IContact, IUpdateNotificationAck, INotificationAck } from '@shanewwarren/aqlcloud-shared-types';
import { NoContactsValidator, PositiveIntegerValidator } from '../validators';
import { FormField, Field, Form } from '../field';


export interface AckForm extends Form {
    values: {
        id: string;
        recipients: IContact[];
        enabled: boolean;
        minutes: number;
        appendAckFailureMessage: boolean;
    },
    fields: {
        id: Field<string>;
        recipients: Field<IContact[]>;
        enabled: Field<boolean>;
        minutes: Field<number>;
        appendAckFailureMessage: Field<boolean>;
    }
}

export const fromModel = (model?: INotificationAck): AckForm => {
    let recipients: IContact[] = [];
    let minutes: number = 0;
    let enabled: boolean = true;
    let appendAckFailureMessage: boolean = false;
    let id: string = '';
    if (model) {
        id = model.id
        recipients = model.recipients;
        minutes = model.minutes;
        enabled = model.enabled;
        appendAckFailureMessage = model.appendAckFailureMessage;
    }

    return {
        type: 'form',
        valid: false,
        values: {
            id,
            recipients,
            enabled,
            minutes,
            appendAckFailureMessage
        },
        fields: {
            id: FormField({
                key: 'id',
                validators: []
            }),
            recipients: FormField({
                key: 'recipients',
                validators: [NoContactsValidator()]
            }),
            minutes: FormField({
                key: 'minutes',
                validators: [PositiveIntegerValidator()]
            }),
            appendAckFailureMessage: FormField({
                key: 'appendAckFailureMessage',
                validators: []
            }),
            enabled: FormField({
                key: 'enabled',
                validators: []
            })
        }
    };
}

export function toModel(model: IUpdateNotificationAck, form: AckForm) {
    model.enabled = form.values.enabled;
    model.recipients = form.values.recipients.map(r => r.id);
    model.minutes = form.values.minutes;
    model.appendAckFailureMessage = form.values.appendAckFailureMessage;
    if (form.values.id) {
        model.id = form.values.id;
    }
    return model;
}
