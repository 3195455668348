<Notification 
color={color}
open={actualOpen} 
test_tag='success-notification' 
className='floating auto'>{message}</Notification>


<script>
    import { Notification } from 'Shared';
    export default {
        components: {
            Notification
        },
        onupdate({ changed, current, previous }) {
            // console.log(changed, current, previous);
        },
        data() {
            return {
                show: false,
                color: '',
                message: ''
            }
        },
        computed: {
            actualOpen: ({ show, message }) => show && !!message
        }
    }
</script>