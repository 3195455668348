import {
	reportTypeFormatter,
	reverse,
	scheduleTypeFormatter,
} from './formatters';

export function sort(key) {
	const { sortKey, sortAsc } = this.get();
	if (key === sortKey) {
		this.set({ sortAsc: !sortAsc });
	} else {
		this.set({ sortKey: key, sortAsc: true });
	}
}

export function stringCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const order = direction ? 1 : -1;
	return (
		(itemOne[key] || '').trim().localeCompare((itemTwo[key] || '').trim()) *
		order
	);
}

export function numberCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const order = direction ? 1 : -1;
	let numberOne: number = itemOne[key] as number;
	let numberTwo: number = itemTwo[key] as number;

	if (typeof numberOne === 'string') {
		numberOne = parseFloat(numberOne);
	}

	if (typeof numberTwo === 'string') {
		numberTwo = parseFloat(numberTwo);
	}

	if (numberOne === numberTwo) {
		return 0;
	}

	const comparator = numberOne > numberTwo ? 1 : -1;
	return comparator * order;
}

export function defaultCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const order = direction ? 1 : -1;
	return order;
}

export function scheduleTypeCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const strOne = scheduleTypeFormatter(itemOne['scheduleType']);
	const strTwo = scheduleTypeFormatter(itemTwo['scheduleType']);
	const order = direction ? 1 : -1;
	return (strOne || '').localeCompare(strTwo || '') * order;
}

export function reportTypeCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const strOne = reportTypeFormatter(itemOne['type']);
	const strTwo = reportTypeFormatter(itemTwo['type']);
	const order = direction ? 1 : -1;
	return (strOne || '').localeCompare(strTwo || '') * order;
}

export function versionCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const versionOne = reverse(itemOne[key]);
	const versionTwo = reverse(itemTwo[key]);
	const order = direction ? 1 : -1;
	return _versionCompare(versionOne, versionTwo, {}) * order;
}

const _versionCompare = (v1, v2, options) => {
	var lexicographical = options && options.lexicographical,
		zeroExtend = options && options.zeroExtend,
		v1parts = v1.split('.'),
		v2parts = v2.split('.');

	function isValidPart(x) {
		return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
	}

	if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
		return NaN;
	}

	if (zeroExtend) {
		while (v1parts.length < v2parts.length) v1parts.push('0');
		while (v2parts.length < v1parts.length) v2parts.push('0');
	}

	if (!lexicographical) {
		v1parts = v1parts.map(Number);
		v2parts = v2parts.map(Number);
	}

	for (var i = 0; i < v1parts.length; ++i) {
		if (v2parts.length == i) {
			return 1;
		}

		if (v1parts[i] == v2parts[i]) {
			continue;
		} else if (v1parts[i] > v2parts[i]) {
			return 1;
		} else {
			return -1;
		}
	}

	if (v1parts.length != v2parts.length) {
		return -1;
	}

	return 0;
};

const date_sort_asc = function(date1, date2) {
	if (date1 > date2) return 1;
	if (date1 < date2) return -1;
	return 0;
};

const date_sort_desc = function(date1, date2) {
	if (date1 > date2) return -1;
	if (date1 < date2) return 1;
	return 0;
};

export function dateCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const order = direction ? 1 : -1;
	const minDate = new Date(-8640000000000000);
	const dateOne: Date = new Date(itemOne[key] || minDate);
	const dateTwo: Date = new Date(itemTwo[key] || minDate);

	if (order === 1) {
		return date_sort_asc(dateOne, dateTwo);
	}

	return date_sort_desc(dateOne, dateTwo);
}

export function booleanCompare(
	itemOne: any,
	itemTwo: any,
	key: string,
	direction = true
) {
	const order = direction ? 1 : -1;
	let first = itemOne[key] ? 1 : -1;
	let second = itemTwo[key] ? 1 : -1;

	let compared = 0;
	if (first === second) {
		compared = 0;
	} else if (first > second) {
		compared = 1;
	} else {
		compared = -1;
	}

	return compared * order;
}

export function filterItems(
	items: any[],
	keys: string | string[],
	filter,
	sortKey: string,
	sortAsc: boolean,
	sortColumns
) {
	const regex = new RegExp(filter, 'i');
	const filtered = Array.isArray(keys)
		? items.filter(item =>
				keys.some(key => item[key] && item[key].match(regex) !== null)
		  )
		: items.filter(item => item[keys] && item[keys].match(regex) !== null);

	if (sortKey && sortAsc !== undefined && sortColumns) {
		const sortColumn = Object.keys(sortColumns).find(
			column => sortColumns[column].key === sortKey
		);
		if (sortColumn) {
			const sortType = sortColumns[sortColumn].type;
			switch (sortType) {
				case ColumnType.String:
					return filtered.sort((itemOne, itemTwo) =>
						stringCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.Date:
					return filtered.sort((itemOne, itemTwo) =>
						dateCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.Number:
					return filtered.sort((itemOne, itemTwo) =>
						numberCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.Boolean:
					return filtered.sort((itemOne, itemTwo) =>
						booleanCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.ReportType:
					return filtered.sort((itemOne, itemTwo) =>
						reportTypeCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.ScheduleType:
					return filtered.sort((itemOne, itemTwo) =>
						scheduleTypeCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
				case ColumnType.Version:
					return filtered.sort((itemOne, itemTwo) =>
						versionCompare(itemOne, itemTwo, sortKey, sortAsc)
					);
			}
		}
	}
	return filtered;
}

export function getSelectedCount(selected) {
	return Object.keys(selected).filter(key => selected[key]).length;
}

export const getAllSelected = (items, selected) => {
	const count = items.filter(item => selected[item.id]).length;
	return count === items.length && count > 0;
};

export const filtered = (items, filter, sortKey, sortAsc, sortColumns) => {
	return filterItems(
		items,
		'loggerName',
		filter,
		sortKey,
		sortAsc,
		sortColumns
	);
};

export function selectItem(event, id) {
	const { selected } = this.get();
	selected[id] = event.target.checked;
	this.set({ selected });
}

export function selectAll(checked) {
	const { selected, filtered } = this.get();

	const newlySelected = {};
	filtered.forEach(item => (newlySelected[item.id] = checked));

	this.set({ selected: Object.assign({}, selected, newlySelected) });
}

export enum ColumnType {
	String = 'string',
	Date = 'date',
	Number = 'number',
	Boolean = 'boolean',
	ReportType = 'reportType',
	ScheduleType = 'scheduleType',
	Version = 'versionType',
}
