import { IDevice } from '@shanewwarren/aqlcloud-shared-types';
import { EventEmitter } from 'eventemitter3';
import { IUpdatePoint } from './store';

export class DataEmitter extends EventEmitter {
    private onWidgetData = (widgetId: string) => `data:${widgetId}`;
    private onWidgetLive = (widgetId: string) => `live:${widgetId}`;
    private onWidgetDevice = (widgetId: string) => `device:${widgetId}`;
    private onWidgetAlert = (widgetId: string) => `alert:${widgetId}`;
    constructor() {
        super();
    }

    public addDataListener(widgetId: string, listener: (data: any[]) => void) {
        this.on(this.onWidgetData(widgetId), listener);
    }

    public addLiveListener(widgetId: string, listener: (data: any[]) => void) {
        this.on(this.onWidgetLive(widgetId), listener);
    }

    public addDeviceStateListener(widgetId: string, listener: (data: IDevice) => void) {
        this.on(this.onWidgetDevice(widgetId), listener);
    }

    public addAlertListener(widgetId: string, listener: (data: any) => void) {
        this.on(this.onWidgetAlert(widgetId), listener);
    }

    public removeDataListener(widgetId: string, listener: any) {
        this.removeListener(this.onWidgetData(widgetId));
    }

    public removeLiveListener(widgetId: string, listener: any) {
        this.removeListener(this.onWidgetLive(widgetId));
    }
    public removeDeviceListener(widgetId: string, listener: any) {
        this.removeListener(this.onWidgetDevice(widgetId));
    }
    public removeAlertListener(widgetId: string, listener: any) {
        this.removeListener(this.onWidgetAlert(widgetId));
    }
    public removeListeners(widgetId: string): void {
        this.removeListener(this.onWidgetData(widgetId));
        this.removeListener(this.onWidgetDevice(widgetId));
        this.removeListener(this.onWidgetAlert(widgetId));
    }

    public emitAlert(widgetId, data: any) {
        if (data) {
            this.emit(this.onWidgetAlert(widgetId), data);
        }
    }

    public emitDevice(widgetId, data: IDevice) {
        if (data) {
            this.emit(this.onWidgetDevice(widgetId), data);
        }
    }

    public emitLive(widgetId, data: IUpdatePoint[]) {
        if (data && data.length > 0) {
            this.emit(this.onWidgetLive(widgetId), data)
        }
    }
}