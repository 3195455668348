<a title={title} data-test={test_tag} class="{class} {active ? activeClass : ''}" href='{url}' on:click>
    <slot></slot>
</a>

<script>

    export default {
        data() {
            return {
                router: null,
                routeName: null,
                class: '',
                routeParams: {},
                routeOptions: {},
                activeClass: 'active',
                activeStrict: false,
                baseMatch: false,
                test_tag: '',
                url: '',
                title: ''
            };
        },
    }

</script>