<Card className='modal-card'>
    <div class='delete-device'>
        <form>
            <fieldset>
                <div class='legend'>
                    <legend>
                        {message}
                        <div class='secondary'>{secondaryMessage}</div>
                    </legend>
                </div>
                <div class='controls'>
                    <div class='form-field-container'>
                        <p>Deleting this device will remove it's membership from:</p>
                        <ul>
                            <li>All Groups</li>
                            <li>All Dashboard Objects</li>
                            <li>All Reports</li>
                            <li>All Alerts</li>
                        </ul>
                    </div>
                    <div class='form-field-container'>
                        <Checkbox bind:checked='deleteAllData'>Additionally, REMOVE ALL archived data provided by this device.</Checkbox>
                    </div>
                </div>
            </fieldset>
            <div class='button-panel'>
                <Button on:click='submit(event)'  color='primary' className="{buttonConfirmClass} ok">{buttonConfirmText}</Button>
                <Button on:click='cancel(event)' className="cancel" color="normal">{buttonCancelText}</Button>
            </div>
        </form>

    </div>
</Card>

<script>
import { Checkbox, Card, Button } from 'Shared';

export default {
    components: { Checkbox, Card, Button },
    data() {
        return {
            message: '',
            secondaryMessage: '',
            buttonConfirmText: 'Ok',
            buttonConfirmClass: '',
            buttonCancelText: 'Cancel',
            deleteAllData: false
        };
    },
    methods: {
        submit(e) {
            e.preventDefault();
            const { deleteAllData } = this.get();
            this.fire('dismiss', deleteAllData);
        },
        cancel(e) {
            e.preventDefault();
            this.fire('cancel');
        },
    }
}
</script>