// Import the css
require('./assets/css/vendor/font-awesome/fontawesome-pro-solid.css');
require('./assets/css/vendor/font-awesome/fontawesome-all.css');
require("flatpickr/dist/themes/dark.css");
require('./assets/css/index.styl');

import { Main } from './components';
import { ApplicationStore } from './store/store';

export default async function start(target, appConfig) {
	const store = new ApplicationStore(appConfig, {});
	await store.initialize();
	window.store = store;

	let app = null;
	app = new Main({
		data: { appConfig },
		target: document.querySelector(target),
		store,
	});
}
