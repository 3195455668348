import { IDevice, StateFlags } from '@shanewwarren/aqlcloud-shared-types';

export function isRunning(device: IDevice): boolean {
    return hasFlag(getFlags(device), StateFlags.StateFlags.SSF_LOGGING);
}

export function isDead(device: IDevice): boolean {
    return device.heartbeatFailureCount > 3;
}

export function getFlags(device: IDevice): StateFlags.StateFlags[] {
    const state = getLoggerState(device);
    return StateFlags.toStateFlags(state);
}

export function getLoggerState(device: IDevice): number {
    return (typeof device.loggerState === 'string') ?
         parseInt(device.loggerState): 
         device.loggerState;
}

export function hasFlag (flags: StateFlags.StateFlags[], flag: number) : boolean {
    return flags.findIndex(f => f === flag) >= 0 ? true : false;
}