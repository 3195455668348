import { Credentials, config } from 'aws-sdk/global';
import {
	IAccount,
	IAlert,
	IAlertInstance,
	IBillingSerie,
	IBillingSummary,
	IContact,
	IDebugFilter,
	IDebugLog,
	IDevice,
	IEmailOptions,
	IExceptionItem,
	IExportLogQuery,
	IFinishUpload,
	IFtpOptions,
	IGroup,
	ILog,
	ILogQuery,
	INewUser,
	IRemoteControlVariable,
	IReport,
	IReportInstance,
	ISMSOptions,
	ISessionEnable,
	IStartUpload,
	IStateClear,
	ITestResult,
	ITheme,
	IUpdateAlert,
	IUpdateCompanyInfo,
	IUpdateContact,
	IUpdateDebugFilter,
	IUpdateRemoteControlVariable,
	IUpdateReport,
	IUpdateTheme,
	IUpdateUser,
	IUpdateWidget,
	IUpload,
	IUser,
	IView,
	IWidget,
	IWidgetData,
	IWidgetDataRequest,
	LogFile,
	WidgetType,
} from '@shanewwarren/aqlcloud-shared-types';
import axios, {
	AxiosInstance,
	AxiosTransformer,
	CancelTokenSource,
} from 'axios';
import {
	formatDate,
	formatTime,
	handleNull,
	logCategory,
} from '../services/formatters';

import { Buffer } from 'buffer';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Personita } from 'personita';

export class Api {
	public get request(): AxiosInstance {
		return this.api;
	}

	private api: AxiosInstance;
	private personita: Personita;
	private refreshPromise: Promise<CognitoUserSession | null> | null = null;
	private session: CognitoUserSession | null = null;

	constructor(endpoint: string, personita: Personita) {
		// Initialize api.
		this.api = axios.create({
			baseURL: `${endpoint}/api`,
		});

		this.api.defaults.headers.common['Content-Type'] = 'application/json';

		// default to no authorization key.
		this.updateAuthorization(null);
		this.personita = personita;
	}

	public async getToken() {
		if (
			(this.session && !this.session.isValid()) ||
			(config.credentials && (config.credentials as Credentials).needsRefresh())
		) {
			if (!this.refreshPromise) {
				// console.log("UPDATING");
				this.refreshPromise = this.personita.refreshUserSession();
			}

			const session: CognitoUserSession | null = await this.refreshPromise;
			this.updateAuthorization(session);
			this.refreshPromise = null;
			// console.log("UPDATED");
		}
	}

	public updateAuthorization(session: CognitoUserSession | null) {
		if (session && session.isValid()) {
			const idToken = session.getIdToken();
			this.api.defaults.headers['authorization'] = idToken.getJwtToken();
			this.session = session;
		} else {
			this.api.defaults.headers['authorization'] = '';
		}
	}

	public async getAccount(): Promise<IAccount> {
		await this.getToken();

		const result = await this.api
			.get('/accounts/current')
			.catch(this._handleException);
		return this._handleResult<IAccount>(result);
	}

	public async getStorageDetails(): Promise<IBillingSerie[]> {
		await this.getToken();

		const result = await this.api
			.get('/accounts/current/storage')
			.catch(this._handleException);
		return this._handleResult<IBillingSerie[]>(result);
	}

	public async deleteSeries(deviceId: string, seriesId: number): Promise<void> {
		await this.getToken();

		const result = await this.api
			.delete(
				`/accounts/current/storage?deviceId=${deviceId}&seriesId=${seriesId}`
			)
			.catch(this._handleException);
		return this._handleResult<void>(result);
	}

	public async updateCompanyInfo(companyInfo: IUpdateCompanyInfo) {
		await this.getToken();

		const result = await this.api
			.post('/accounts/current/company', companyInfo)
			.catch(this._handleException);
		return this._handleResult<IAccount>(result);
	}

	public async updateTheme(theme: IUpdateTheme) {
		await this.getToken();

		const result = await this.api
			.post('/accounts/current/customize', theme)
			.catch(this._handleException);
		return this._handleResult<ITheme>(result);
	}

	public async activateUser(identityId: string) {
		await this.getToken();

		return this.api
			.post('/users/activate', {
				identityId,
			})
			.catch(this._handleException);
	}

	public async createWidget(
		viewId: string,
		name: string,
		widgetType: string
	): Promise<IWidget> {
		await this.getToken();

		return this.api
			.post(`/views/${viewId}/widgets`, {
				widgetType,
				name,
			})
			.then(result => {
				const widget = result.data;
				widget.devices = widget.devices || [];
				widget.channels = widget.channels || [];
				return widget;
			})
			.catch(this._handleException);
	}

	public async updateWidget(
		viewId: string,
		widgetId: string,
		widgetType: string,
		assignedIds
	) {
		await this.getToken();

		if (widgetType === WidgetType.Table) {
			return this.api
				.post(`/views/${viewId}/widgets/${widgetId}/devices`, {
					deviceIds: assignedIds,
				})
				.then(result => result.data)
				.catch(this._handleException);
		} else {
			return this.api
				.post(`/views/${viewId}/widgets/${widgetId}/channels`, {
					channelIds: assignedIds,
				})
				.then(result => result.data)
				.catch(this._handleException);
		}
	}

	public async updateWidgetDetails(
		viewId: string,
		widgetId: string,
		widget: IUpdateWidget
	) {
		await this.getToken();
		return this.api
			.put(`/views/${viewId}/widgets/${widgetId}`, widget)
			.then(result => result.data)
			.catch(this._handleException);
	}

	public async deleteWidget(viewId, widgetId) {
		await this.getToken();
		return this.api
			.delete(`/views/${viewId}/widgets/${widgetId}`)
			.catch(this._handleException);
	}

	public async getExceptions() {
		await this.getToken();
		const result = await this.api
			.get(`/exception`)
			.catch(this._handleException);
		return this._handleResult<IExceptionItem[]>(result);
	}

	public async acknowledgeByPortal(alertInstanceId: string) {
		await this.getToken();
		const result = await this.api
			.get(`/alertInstances/${alertInstanceId}/ack`)
			.catch(this._handleException);
		return this._handleResult<IAlertInstance>(result);
	}

	public async getNonAcknowledgedAlertInstanceCount() {
		await this.getToken();
		const result = await this.api
			.get(`/alertInstances`)
			.catch(this._handleException);
		return this._handleResult<{ count: number; }>(result);
	}

	public async acknowledgeException(deviceId: string, exceptionId: string) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/exception/${exceptionId}/ack`)
			.catch(this._handleException);
		return this._handleResult<IExceptionItem>(result);
	}

	public async blockException(deviceId: string, exceptionId: string) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/exception/${exceptionId}/block`)
			.catch(this._handleException);
		return this._handleResult<IExceptionItem>(result);
	}

	public async getDeviceAlertStatus(deviceIds: string[]) {
		await this.getToken();
		const result = await this.api
			.post(`/device/alertstatus`, { deviceIds })
			.catch(this._handleException);
		return this._handleResult<any>(result);
	}

	public async updateSession(deviceId: string, sessionEnable: ISessionEnable) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/session`, sessionEnable)
			.catch(this._handleException);
		return this._handleResult<IDevice>(result);
	}

	public async getDebugLogs(deviceId: string) {
		await this.getToken();
		const result = await this.api
			.get(`/device/${deviceId}/debuglogs`)
			.catch(this._handleException);
		return this._handleResult<IDebugLog[]>(result);
	}

	public async getDebugLog(url: string): Promise<any> {
		// @ts-ignore: I think this should work.
		const removeAuth: AxiosTransformer = (data, headers) => {
			delete headers.authorization;
			return data;
		};

		const response = await axios.get(url, {
			transformRequest: [removeAuth],
			headers: {
				Accept: 'application/octet-stream',
				'Content-Type': 'application/octet-stream',
			},
			responseType: 'arraybuffer',
		});

		const buffer = Buffer.from(response.data);
		return { file: LogFile.fromBuffer(buffer), raw: response.data };
	}

	public async triggerNewDebugLog(deviceId: string, clear: boolean) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/debuglogs`, { clear: clear })
			.catch(this._handleException);
		return this._handleResult<IDebugLog>(result);
	}

	public async triggerClearDebugLog(deviceId: string) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/debuglogs/clear`)
			.catch(this._handleException);
		return this._handleResult<any>(result);
	}

	public async getDebugFilter(deviceId: string) {
		await this.getToken();
		const result = await this.api
			.get(`/device/${deviceId}/debug`)
			.catch(this._handleException);
		return this._handleResult<IDebugFilter>(result);
	}

	public async updateDebugFilter(
		deviceId: string,
		debugFilter: IUpdateDebugFilter
	) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/debug`, debugFilter)
			.catch(this._handleException);
		return this._handleResult<IDebugFilter>(result);
	}

	public async clearStateFlag(deviceId: string, stateClear: IStateClear) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/state/clear`, stateClear)
			.catch(this._handleException);

		return this._handleResult<any>(result);
	}

	public async getBillingSummary(): Promise<IBillingSummary> {
		await this.getToken();
		const result = await this.api
			.get(`/accounts/current/billing`)
			.catch(this._handleException);
		return this._handleResult<IBillingSummary>(result);
	}

	public async getRemoteVariables(deviceId) {
		await this.getToken();
		const result = await this.api
			.get(`/device/${deviceId}/rc`)
			.catch(this._handleException);
		return this._handleResult<IRemoteControlVariable[]>(result);
	}

	public async updateRemoteVariables(
		deviceId,
		variables: IUpdateRemoteControlVariable[]
	) {
		await this.getToken();
		const result = await this.api
			.post(`/device/${deviceId}/rc`, variables)
			.catch(this._handleException);
		return this._handleResult<IRemoteControlVariable[]>(result);
	}

	public async deleteView(viewId) {
		await this.getToken();
		return this.api
			.delete(`/views/${viewId}`)
			.catch(this._handleException)
			.catch(this._handleException);
	}

	public async createView(viewName: string): Promise<IView> {
		await this.getToken();
		return this.api
			.post('/views', {
				name: viewName,
				default: false,
			})
			.then(result => result.data)
			.catch(this._handleException) as Promise<any>;
	}

	public async setViewDefault(viewId: string) {
		await this.getToken();
		return this.api.get(`/view/default/${viewId}`).catch(this._handleException);
	}

	public async exportLogs(logQuery: IExportLogQuery, progressCallback) {
		await this.getToken();
		const response = await this.api.post('/logs/export', logQuery, {
			responseType: 'blob',
			onDownloadProgress: progressEvent => {
				if (progressCallback) {
					progressCallback(progressEvent.loaded);
				}
			},
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		return url;
	}

	public async getLogs(logQuery: ILogQuery) {
		await this.getToken();
		const result = await this.api.post('/logs', logQuery);
		const logs = this._handleResult<ILog[]>(result);

		// filter groups to only include queried groups.
		const transformed = logs.map(log => {
			const tlog: any = {};
			tlog.timestamp = `${formatDate(log.timestamp)} ${formatTime(
				log.timestamp
			)}`;
			tlog.eventType = logCategory(log.eventType);
			tlog.groupName = log.groupName
				.split(',')
				.filter(name => logQuery.group && logQuery.group.includes(name))
				.join('\n');
			tlog.unitName = handleNull(log.unitName);
			tlog.commandSource = handleNull(log.commandSource);
			tlog.payload = log.payload;
			return tlog;
		});

		return transformed;
	}

	public async getAllUsers() {
		await this.getToken();
		const result = await this.api.get('/users').catch(this._handleException);

		return this._handleResult<IUser[]>(result);
	}

	public async createUser(user: INewUser) {
		await this.getToken();
		const result = await this.api
			.post(`/user`, user)
			.catch(this._handleException);
		return this._handleResult<IUser>(result);
	}

	public async updateUser(userId: string, user: IUpdateUser) {
		await this.getToken();
		const result = await this.api
			.put(`/user/${userId}`, user)
			.catch(this._handleException);
		return this._handleResult<IUser>(result);
	}

	public async deleteUser(userId) {
		await this.getToken();
		return this.api.delete(`/user/${userId}`).catch(this._handleException);
	}

	public async updateView(
		viewId: string,
		name: string,
		defaultView: boolean,
		rangeType: string,
		startDate: Date | undefined,
		endDate: Date | undefined
	) {
		await this.getToken();
		const payload: any = {
			name,
			default: defaultView,
			rangeType,
		};

		if (startDate && endDate) {
			payload.startDate = startDate;
			payload.endDate = endDate;
		}

		const result = await this.api
			.put(`/views/${viewId}`, payload)
			.catch(this._handleException);

		return this._handleResult<IView>(result);
	}

	public async setViewGroups(
		viewId: string,
		groupIds: string[]
	): Promise<IView> {
		await this.getToken();
		const result = await this.api
			.post(`/views/${viewId}/groups`, {
				groupIds,
			})
			.catch(this._handleException);

		return this._handleResult<IView>(result);
	}

	public async getView(viewId: string): Promise<IView> {
		await this.getToken();
		const result = await this.api
			.get(`/views/${viewId}`)
			.catch(this._handleException);

		const view = this._handleResult<IView>(result);
		view.startDate = view.startDate ? new Date(view.startDate) : undefined;
		view.endDate = view.endDate ? new Date(view.endDate) : undefined;
		return view;
	}

	public async getDefaultView(): Promise<IView> {
		await this.getToken();
		const result = await this.api
			.get('/view/default')
			.catch(this._handleException);
		const view = this._handleResult<IView>(result);
		view.startDate = view.startDate ? new Date(view.startDate) : undefined;
		view.endDate = view.endDate ? new Date(view.endDate) : undefined;
		return view;
	}

	public async getViews(): Promise<IView[]> {
		await this.getToken();
		const result = await this.api.get('/views').catch(this._handleException);
		return this._handleResult<IView[]>(result);
	}

	/**
	 * TODO: this should now return a list of requests to make, so this one in
	 * @param viewId
	 * @param widgetId
	 * @param query
	 */
	public async getWidgetData(
		cancelToken: CancelTokenSource,
		viewId: string,
		widgetId: string,
		query?: IWidgetDataRequest
	): Promise<IWidgetData> {
		await this.getToken();
		return this.api
			.post(`/views/${viewId}/widgets/${widgetId}/data`, query || {}, {
				cancelToken: cancelToken.token,
			})
			.then(result => result.data)
			.catch(this._handleException);
	}

	public async createGroup(
		groupName: string,
		deviceIds: string[]
	): Promise<IGroup> {
		await this.getToken();
		const result = await this.api
			.post('/groups', {
				name: groupName,
				deviceIds,
			})
			.catch(this._handleException);

		return this._handleResult<IGroup>(result);
	}

	public async updateGroup(
		groupId: string,
		groupName: string,
		deviceIds: string[]
	): Promise<IGroup> {
		await this.getToken();
		const result = await this.api
			.put(`/groups/${groupId}`, {
				name: groupName,
				deviceIds,
			})
			.catch(this._handleException);

		return this._handleResult<IGroup>(result);
	}

	public async deleteGroup(groupId: string): Promise<void> {
		await this.getToken();
		await this.api.delete(`/groups/${groupId}`).catch(this._handleException);
	}

	public async getGroup(groupId: string): Promise<IGroup> {
		await this.getToken();
		const result = await this.api
			.get(`/groups/${groupId}`)
			.catch(this._handleException);
		return this._handleResult<IGroup>(result);
	}

	public async getGroups(groupId: string): Promise<IGroup[]> {
		await this.getToken();
		const result = await this.api.get(`/groups`).catch(this._handleException);
		return this._handleResult<IGroup[]>(result);
	}

	public async getDevices(): Promise<IDevice[]> {
		await this.getToken();
		const result = await this.api.get('/devices').catch(this._handleException);
		return this._handleResult<IDevice[]>(result);
	}

	public async associateDevice(
		serialNumber: number,
		provision: string
	): Promise<IDevice> {
		await this.getToken();
		const result = await this.api
			.post('/devices/associate', {
				id: serialNumber,
				provision,
			})
			.catch(this._handleException);
		return this._handleResult<IDevice>(result);
	}

	public async disassociateDevice(
		serialNumber: number,
		clearData: boolean
	): Promise<IDevice> {
		await this.getToken();
		const result = await this.api
			.post('/devices/disassociate', {
				serialNumber,
				clearData,
			})
			.catch(this._handleException);
		return this._handleResult<IDevice>(result);
	}

	public async getAlerts(): Promise<IAlert[]> {
		await this.getToken();
		const result = await this.api.get('/alerts').catch(this._handleException);
		return this._handleResult<IAlert[]>(result);
	}

	public async getAlertInstances(deviceId: string): Promise<IAlertInstance[]> {
		await this.getToken();
		const result = await this.api
			.get(`/device/${deviceId}/alertinstances`)
			.catch(this._handleException);
		return this._handleResult<IAlertInstance[]>(result);
	}

	public async createAlert(alert: IUpdateAlert): Promise<IAlert> {
		await this.getToken();
		const result = await this.api
			.post('/alerts', alert)
			.catch(this._handleException);
		return this._handleResult<IAlert>(result);
	}

	public async getAlert(alertId: string): Promise<IAlert> {
		await this.getToken();
		const result = await this.api
			.get(`/alerts/${alertId}`)
			.catch(this._handleException);
		return this._handleResult<IAlert>(result);
	}

	public async toggleAlert(alertId: string, enable: boolean): Promise<IAlert> {
		await this.getToken();
		const result = await this.api
			.put(`/alerts/${alertId}/enable`, {
				enabled: enable,
			})
			.catch(this._handleException);
		return this._handleResult<IAlert>(result);
	}

	public async updateAlert(
		alertId: string,
		alert: IUpdateAlert
	): Promise<IAlert> {
		await this.getToken();
		const result = await this.api
			.put(`/alerts/${alertId}`, alert)
			.catch(this._handleException);
		return this._handleResult<IAlert>(result);
	}

	public async deleteAlert(alertId: string): Promise<void> {
		await this.getToken();
		await this.api.delete(`/alerts/${alertId}`).catch(this._handleException);
	}

	public async getContacts(): Promise<IContact[]> {
		await this.getToken();
		const result = await this.api.get('/contacts').catch(this._handleException);
		return this._handleResult<IContact[]>(result);
	}

	public async createContact(contact: IUpdateContact): Promise<IContact> {
		await this.getToken();
		const result = await this.api
			.post('/contacts', contact)
			.catch(this._handleException);
		return this._handleResult<IContact>(result);
	}

	public async getContact(contactId: string): Promise<IContact> {
		await this.getToken();
		const result = await this.api
			.get(`/contacts/${contactId}`)
			.catch(this._handleException);
		return this._handleResult<IContact>(result);
	}

	public async updateContact(
		contactId: string,
		contact: IUpdateContact
	): Promise<IContact> {
		await this.getToken();
		const result = await this.api
			.put(`/contacts/${contactId}`, contact)
			.catch(this._handleException);
		return this._handleResult<IContact>(result);
	}

	public async deleteContact(contactId: string): Promise<void> {
		await this.getToken();
		await this.api
			.delete(`/contacts/${contactId}`)
			.catch(this._handleException);
	}

	public async getReports(): Promise<IReport[]> {
		await this.getToken();
		const result = await this.api.get('/reports').catch(this._handleException);
		return this._handleResult<IReport[]>(result);
	}

	public async createReport(report: IUpdateReport): Promise<IReport> {
		await this.getToken();
		const result = await this.api
			.post('/reports', report)
			.catch(this._handleException);
		return this._handleResult<IReport>(result);
	}

	public async getReport(reportId: string): Promise<IReport> {
		await this.getToken();
		const result = await this.api
			.get(`/reports/${reportId}`)
			.catch(this._handleException);
		return this._handleResult<IReport>(result);
	}

	public async getReportDownload(reportId: string): Promise<IReportInstance> {
		await this.getToken();
		const result = await this.api
			.get(`/reports/${reportId}/download`)
			.catch(this._handleException);
		return this._handleResult<IReportInstance>(result);
	}

	public async reportCopy(reportId: string): Promise<IReport> {
		await this.getToken();
		const result = await this.api
			.post(`/reports/${reportId}/copy`)
			.catch(this._handleException);
		return this._handleResult<IReport>(result);
	}

	public async getReportStatus(
		reportId: string,
		reportInstanceId: string
	): Promise<IReportInstance> {
		await this.getToken();
		const result = await this.api
			.get(`/reports/${reportId}/status/${reportInstanceId}`)
			.catch(this._handleException);
		return this._handleResult<IReportInstance>(result);
	}

	public async updateReport(
		reportId: string,
		report: IUpdateReport
	): Promise<IReport> {
		await this.getToken();
		const result = await this.api
			.put(`/reports/${reportId}`, report)
			.catch(this._handleException);
		return this._handleResult<IReport>(result);
	}

	public async deleteReport(reportId: string): Promise<void> {
		await this.getToken();
		await this.api.delete(`/reports/${reportId}`).catch(this._handleException);
	}

	public async startUpload(upload: IStartUpload): Promise<IUpload> {
		await this.getToken();
		const result = await this.api
			.post(`/files`, upload)
			.catch(this._handleException);
		return this._handleResult<IUpload>(result);
	}

	public async doUpload(url: string, file: any) {
		try {
			// @ts-ignore: I think this should work.
			const removeAuth: AxiosTransformer = (data, headers) => {
				delete headers.authorization;
				return data;
			};

			await axios.put(url, file, {
				transformRequest: [removeAuth],
				withCredentials: false,
				headers: {
					'Content-Type': file.type,
				},
			});
		} catch (err) {
			// console.log(err);
			if (err && err.response && err.response.data) {
				// console.log(err.response.data);
			}

			throw err;
		}
	}

	public async finishUpload(upload: IFinishUpload): Promise<IDevice[]> {
		await this.getToken();
		const result = await this.api
			.post(`/files/publish`, upload)
			.catch(this._handleException);

		return this._handleResult<IDevice[]>(result);
	}

	public async testEmail(options: IEmailOptions): Promise<ITestResult> {
		await this.getToken();
		const result = await this.api
			.post(`/email/test`, options)
			.catch(this._handleException);

		return this._handleResult<ITestResult>(result);
	}

	public async testSms(options: ISMSOptions): Promise<ITestResult> {
		await this.getToken();
		const result = await this.api
			.post(`/sms/test`, options)
			.catch(this._handleException);

		return this._handleResult<ITestResult>(result);
	}

	public async testFtp(options: IFtpOptions): Promise<ITestResult> {
		await this.getToken();
		const result = await this.api
			.post(`/ftp/test`, options)
			.catch(this._handleException);

		return this._handleResult<ITestResult>(result);
	}

	private _handleResult<T>(result: any) {
		return result.data as T;
	}

	private _handleException = (rejection: any) => {
		if (axios.isCancel(rejection)) {
			return Promise.resolve();
		}

		if (this._isUnauthorized(rejection.response)) {
			return Promise.resolve();
		}

		if (!axios.isCancel(rejection)) {
			let message = 'Sorry, there was an error processing your request';
			if (rejection.response) {
				if (typeof rejection.response.data === 'string') {
					message = rejection.response.data;
				} else if (typeof rejection.response.data.errorMessage === 'string') {
					message = rejection.response.data.errorMessage;
				}
			}
			return Promise.reject(new Error(message));
		}
	};

	private _isUnauthorized(response) {
		if (!response.request) {
			return false;
		}

		return response.status === 401;
	}
}
