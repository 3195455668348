<div class='application-container'>
    <Toolbar {router} {store} {active}>
    </Toolbar>
    <div class='master'>
        <svelte:component this={_component} {params} {router} {uri} />
    </div>
</div>

<script>
    import Navaid from 'navaid';
    import Toolbar from './Toolbar';
    import AppRoutes from './routes';
    import { applyBodyClass } from 'Services/classes';
    import { loadComponent, trackRoute } from 'Services/router';

    export default {
        components: {
            Toolbar
        },
        data() {
            return {
                router: null,
                _component: undefined,
                _uri: location.pathname
            }
        },
        computed: {
            active: ({ _uri }) => _uri || 'home'
        },
        oncreate() {
            applyBodyClass('account');

            const onDraw = this.draw.bind(this);
            const onTrack = this.track.bind(this);
            const router = Navaid('/app')
            .on(AppRoutes.Alerts, () => import('./Alerts/Index.html').then(onDraw))
            .on(AppRoutes.CreateSystemAlert, () => import('./Alerts/Alert.html').then(onDraw))
            .on(AppRoutes.CreateChannelAlert, () => import('./Alerts/Alert.html').then(onDraw))
            .on(AppRoutes.Groups, () => import('./Groups/Index.html').then(onDraw))
            .on(AppRoutes.CreateGroup, () => import('./Groups/Create.html').then(onDraw))
            .on(AppRoutes.Devices, () => import('./Devices/Index.html').then(onDraw))
            .on(AppRoutes.Reports, () => import('./Reports/Index.html').then(onDraw))
            .on(AppRoutes.CreateReport, () => import('./Reports/Report.html').then(onDraw))
            .on(AppRoutes.Log, () => import('./CommLog/Index.html').then(onDraw))
            .on("/accounts/*", () => import('./Accounts/Index.html').then(onDraw))
            .on("/accounts*", () => import('./Accounts/Index.html').then(onDraw))
            .on(AppRoutes.Dashboard, () => import('./Dashboard/Index.html').then(onDraw))
            .on(AppRoutes.EditAlert, obj => import('./Alerts/Alert.html').then(m => onDraw(m, obj)))
            .on(AppRoutes.EditGroup, obj => import('./Groups/Edit.html').then(m => onDraw(m, obj)))
            .on(AppRoutes.EditReport, obj => import('./Reports/Report.html').then(m => onDraw(m, obj)))
                .listen();
                
            addEventListener('replacestate', onTrack);
            addEventListener('pushstate', onTrack);
            addEventListener('popstate', onTrack);

            this.set({ router });
        }, 
        ondestroy() {
            const { router } = this.get();
            if (router) {
                router.unlisten;
            }
        },
        methods: {
            draw(m, p) {
                const { component, params } = loadComponent(m, p);
                this.set({ _component: component, params });
            },
            track(obj) {
                this.set({ _uri: trackRoute(obj) });
	        }
        }
    }
</script>