import { TriggerType, ITriggerSystemConfig, IUpdateAlert } from '@shanewwarren/aqlcloud-shared-types';
import { IsValid, NotValid, Validator, ValidatorType, IValidatorResult, ValidationErrors } from '../validators';
import { FormField, Field } from '../field';

export interface DeviceTrigger {
    type: TriggerType;
    commFailCount: number | null;
    voltageThreshold: number | null;
    memoryThreshold: number | null;
}

export interface DeviceTriggerForm {
    values: {
        type: TriggerType;
        commFailCount: number | null;
        memoryThreshold: number | null;
        voltageThreshold: number | null;
    },
    fields: {
        type: Field<TriggerType | null>;
        commFailCount: Field<number | null>;
        voltageThreshold: Field<number | null>;
        memoryThreshold: Field<number | null>;
    }
}


class _SystemTriggerTypeValidator extends Validator<TriggerType> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values['type'] === TriggerType.LowVoltage) {
            if (!values['voltageThreshold']) {
                return NotValid('Must specify a voltage threshold.');
            }

            if (values['voltageThreshold'] <= 0) {
                return NotValid(ValidationErrors.PositiveInteger);
            }
        }

        if (values['type'] === TriggerType.FailedToReport) {
            if (!values['commFailCount']) {
                return NotValid('Must specify a communication failure count.');
            }
            if (values['commFailCount'] <= 0) {
                return NotValid(ValidationErrors.PositiveInteger);
            }
        }

        if (values['type'] === TriggerType.LowMemory) {
            if (!values['memoryThreshold']) {
                return NotValid('Must specify a memory theshold.');
            }

            if (values['memoryThreshold'] < 0 || values['memoryThreshold'] > 100) {
                return NotValid('Must specify a percentage from 0-100');
            }
        }

        return IsValid;
    }
}

class _CommFailCountPositiveInteger extends Validator<any> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === TriggerType.FailedToReport) {
            if (!values.commFailCount && values.commFailCount !== 0) {
                return NotValid(ValidationErrors.NoValue);
            }
            if (values.commFailCount <= 0) {
                return NotValid(ValidationErrors.PositiveInteger);
            }
        }
        return IsValid;
    }
}
const CommFailCountPositiveInteger = () => new _CommFailCountPositiveInteger();

class _VoltageThresholdPositiveInteger extends Validator<any> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === TriggerType.LowVoltage) {
            if (!values.voltageThreshold && values.commFailCount !== 0) {
                return NotValid(ValidationErrors.NoValue);
            }

            if (values.voltageThreshold <= 0) {
                return NotValid(ValidationErrors.PositiveInteger);
            }
        }
        return IsValid;
    }
}
const VoltageThresholdPositiveInteger = () => new _VoltageThresholdPositiveInteger();

const SystemTriggerTypeValidator = () => new _SystemTriggerTypeValidator();

// Need to do a notSet flag.

export const fromModel = (model?: ITriggerSystemConfig): DeviceTriggerForm => {
    let type = TriggerType.FailedToReport;
    let commFailCount: number | null = null;
    let voltageThreshold: number | null = null;
    let memoryThreshold: number | null = null;

    if (model) {
        if (model.commFailCount) {
            type = TriggerType.FailedToReport;
            commFailCount = model.commFailCount;
        }
        if (model.voltageThreshold) {
            type = TriggerType.LowVoltage;
            voltageThreshold = model.voltageThreshold;
        }
        if (model.memoryThreshold) {
            type = TriggerType.LowMemory;
            memoryThreshold = model.memoryThreshold * 100;
        }
    }

    return {
        values: {
            type,
            commFailCount,
            voltageThreshold,
            memoryThreshold
        },
        fields: {
            type: FormField({
                key: 'type',
                validators: [SystemTriggerTypeValidator()]
            }),
            commFailCount: FormField({
                key: 'commFailCount',
                validators: [CommFailCountPositiveInteger()]
            }),
            voltageThreshold: FormField({
                key: 'voltageThreshold',
                validators: [VoltageThresholdPositiveInteger()]
            }),
            memoryThreshold: FormField({
                key: 'memoryThreshold',
                validators: []
            })
        }
    };
}

export function toModel(model: IUpdateAlert, form: DeviceTriggerForm) {
    const { type, commFailCount, voltageThreshold, memoryThreshold } = form.values;

    let trigger: any = {};
    if (model.trigger) {
        trigger = model.trigger;
    }

    trigger.type = type;
    trigger.thresholdConfig = undefined;
    trigger.windowConfig = undefined;
    trigger.systemConfig = {
        commFailCount: type === TriggerType.FailedToReport ? commFailCount : null,
        voltageThreshold: type === TriggerType.LowVoltage ? voltageThreshold : null,
        memoryThreshold: type === TriggerType.LowMemory && memoryThreshold ? (memoryThreshold / 100) : null
    };

    model.trigger = trigger;

    return model;
}
