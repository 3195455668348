
<svelte:component this={_component} {params} {router}  />

<script>
    import Navaid from 'navaid';
    import MarketingRoutes from './Marketing/routes';
    import AccountRoutes from './Account/routes';
    import { loadComponent } from 'Services/router';
    export default {
        data() {
            return {
                router: null,
                _component: undefined,
            }
        },
        oncreate() {
            const onDraw = this.draw.bind(this);
            const onAuthenticate = this.authenticated.bind(this);
            const router = Navaid('/')
                .on(MarketingRoutes.Home, () => import('./Marketing/Home.html').then(onDraw))
                .on(AccountRoutes.Login, () => import('./Account/Container.html').then(onDraw))
                .on(AccountRoutes.Changelog, () => import('./Account/Container').then(onDraw))
                .on(AccountRoutes.Register, () => import('./Account/Container').then(onDraw))
                .on(AccountRoutes.ForgotPassword, () => import('./Account/Container').then(onDraw))
                .on(AccountRoutes.ResetPassword, () => import('./Account/Container').then(onDraw))
                .on(AccountRoutes.VerificationCode, () => import('./Account/Container').then(onDraw))
                .on(AccountRoutes.NewPasswordRequired, () => import('./Account/Container').then(onDraw))
                .on('/app/*',  onAuthenticate);

            this.set({ router });
            router.listen();
        }, 
        ondestroy() {
            const { router } = this.get();
            if (router) {
                router.unlisten;
            }
        },
        methods: {
            authenticated() {
                const { user } = this.store.get();
                const { router } = this.get();
                if (!user) {
                    router.route(AccountRoutes.Login, true);
                } else {
                    import('./App/Container').then(this.draw.bind(this));
                }
            },
            draw(m, obj) {
                const { component, params } = loadComponent(m, obj);
                this.set({ _component: component, params });
            }
        }
    }
</script>