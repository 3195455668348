import { IAlert, IUpdateAlert } from '@shanewwarren/aqlcloud-shared-types';
import { EmptyStringValidator } from '../validators';
import { Form, FormField, Field } from '../field';

export interface AlertDetailsForm extends Form {
    values: {
        name: string;
    },
    fields: {
        name: Field<string>;
    }
}

export const fromModel = (model?: IAlert): AlertDetailsForm => {
    let name = '';
    if (model) {
        name = model.name;
    }

    return {
        valid: false,
        type: 'form',
        values: {
            name
        },
        fields: {
            name: FormField({
                key: 'name',
                validators: [EmptyStringValidator()]
            })
        }
    };
}

export function toModel(model: IUpdateAlert, form: AlertDetailsForm) {
    model.name = form.values.name;
    return model;
}