<div class='sortable'>
    {#if eq(key, sortKey)}
        <i class='fas {sortAsc ? "fa-sort-up" : "fa-sort-down"}'></i>
    {:else}
        <i class='fas fa-sort'></i>
    {/if}
</div>

<script>
    import { eq } from 'Services/helpers';

    export default {
        helpers: {
            eq
        },
        data() {
            return {
                key: undefined,
                sortKey: undefined,
                sortAsc: true
            }
        }
    }
</script>

<style>
    .sortable {
        float: right;
    }
</style>