<div class="step-panel">
    <Button test_tag='cancel' 
		on:click='cancel(event)' 
		color='normal'>Cancel</Button> 
    {#if !firstStep}
        <Button test_tag='back' 
			on:click='previousStep(event)'>Back</Button> 
    {/if} 

    {#if lastStep}
        <Button test_tag='save' 
			loading={saving} 
			{disabled} 
			on:click="fire('save', event)">Save</Button> 
    {:else}
        <Button test_tag='next' 
			{disabled} 
			on:click='nextStep(event)'>Next</Button> 
    {/if}
</div>

<script>
	import { Button } from 'Shared';

	export default {
		components: { Button },
		data() {
			return {
				saving: false,
				currentStep: 0,
				steps: [],
			};
		},
		computed: {
			disabled: ({ steps, currentStep }) => {
				return !steps[currentStep].valid;
			},
			lastStep: ({ steps, currentStep }) => {
				return currentStep === steps.length - 1;
			},
			firstStep: ({ currentStep }) => {
				return currentStep === 0;
			},
		},
		methods: {
			cancel(event) {
				event.preventDefault();
				this.fire('cancel', event);
			},
			previousStep(event) {
				event.preventDefault();
				const { currentStep } = this.get();
				if (currentStep > 0) {
					this.set({
						currentStep: currentStep - 1,
					});
				}
			},
			nextStep(event) {
				event.preventDefault();
				const { currentStep, steps } = this.get();
				if (currentStep < steps.length - 1) {
					this.set({
						currentStep: currentStep + 1,
					});
				}
			},
		},
	};
</script>