import {
	TriggerType,
	ITrigger,
	DataFilterType,
	TriggerWindowRangeType,
	TriggerOperators,
	IUpdateAlert,
} from '@shanewwarren/aqlcloud-shared-types';
import {
	Validator,
	ValidatorType,
	IValidatorResult,
	IsValid,
	NotValid,
	ValidationErrors,
} from '../validators';
import { FormField, Field } from '../field';

export interface ChannelTriggerForm {
	values: {
		type: TriggerType;
		direction: TriggerWindowRangeType;
		useHysteresis: boolean;
		hysteresisValue: number | null;
		triggerOperator: TriggerOperators;
		triggerSetPoint: number | null;
		resetTriggerOperator: TriggerOperators;
		resetTriggerSetPoint: number | null;
		minValue: number | null;
		maxValue: number | null;
		enableConsecutiveReadings: boolean;
		consecutiveReadings: number | null;
		enableSlidingAvgSamples: boolean;
		slidingAvgSamples: number | null;
		filterType: DataFilterType;
	};
	fields: {
		type: Field<TriggerType | null>;
		direction: Field<TriggerWindowRangeType>;
		useHysteresis: Field<boolean>;
		hysteresisValue: Field<number | null>;
		triggerOperator: Field<TriggerOperators>;
		triggerSetPoint: Field<number | null>;
		resetTriggerOperator: Field<TriggerOperators>;
		resetTriggerSetPoint: Field<number | null>;
		minValue: Field<number | null>;
		maxValue: Field<number | null>;
		enableConsecutiveReadings: Field<boolean>;
		enableSlidingAvgSamples: Field<boolean>;
		consecutiveReadings: Field<number | null>;
		slidingAvgSamples: Field<number | null>;
		filterType: Field<DataFilterType>;
	};
}

const isNull = (value: any) => {
	return value === null;
};

class _ChannelTriggerTypeValidator extends Validator<TriggerType> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values['type'] === TriggerType.Threshold) {
			if (
				isNull(values['triggerSetPoint']) &&
				isNull(values['resetTriggerSetPoint'])
			) {
				return NotValid('Must set a trigger and reset trigger value.');
			}

			if (isNull(values['triggerSetPoint'])) {
				return NotValid('Must set a trigger value.');
			}

			if (isNull(values['resetTriggerSetPoint'])) {
				return NotValid('Must set a reset trigger value.');
			}

			if (
				(values['triggerOperator'] === TriggerOperators.GreaterThan ||
					values['triggerOperator'] === TriggerOperators.GreaterThanEqualTo) &&
				values['resetTriggerSetPoint'] > values['triggerSetPoint']
			) {
				return NotValid('Reset value cannot be larger than trigger value.');
			}

			if (
				(values['triggerOperator'] === TriggerOperators.LessThan ||
					values['triggerOperator'] === TriggerOperators.LessThanEqualTo) &&
				values['resetTriggerSetPoint'] < values['triggerSetPoint']
			) {
				return NotValid('Trigger value cannot be larger than reset value.');
			}

			if (
				(values['triggerOperator'] === TriggerOperators.LessThan ||
					values['triggerOperator'] === TriggerOperators.LessThanEqualTo) &&
				(values['resetTriggerOperator'] === TriggerOperators.LessThan ||
					values['resetTriggerOperator'] ===
						TriggerOperators.LessThanEqualTo) &&
				values['resetTriggerSetPoint'] < values['triggerSetPoint']
			) {
				return NotValid('Reset value cannot be smaller than trigger value.');
			}

			if (
				(values['triggerOperator'] === TriggerOperators.GreaterThan ||
					values['triggerOperator'] === TriggerOperators.GreaterThanEqualTo) &&
				(values['resetTriggerOperator'] === TriggerOperators.GreaterThan ||
					values['resetTriggerOperator'] ===
						TriggerOperators.GreaterThanEqualTo) &&
				values['resetTriggerSetPoint'] > values['triggerSetPoint']
			) {
				return NotValid('Reset value cannot be larger than trigger value.');
			}
		}

		if (values['type'] === TriggerType.WindowComparator) {
			if (isNull(values['minValue']) || isNull(values['maxValue'])) {
				return NotValid('Must set a minimum and maximum value.');
			}

			if (isNull(values['minValue'])) {
				return NotValid('Must set a minimum value.');
			}

			if (isNull(values['maxValue'])) {
				return NotValid('Must set a maximum value.');
			}

			if (values['minValue'] > values['maxValue']) {
				return NotValid(
					'Minimum value cannot be larger than the maximum value.'
				);
			}
		}

		return IsValid;
	}
}

const ChannelTriggerTypeValidator = () => new _ChannelTriggerTypeValidator();

class _TriggetSetPointNotNull extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (
			values.type === TriggerType.Threshold &&
			isNull(values.triggerSetPoint)
		) {
			return NotValid(ValidationErrors.CannotBeNull);
		}
		return IsValid;
	}
}

const TriggetSetPointNotNull = () => new _TriggetSetPointNotNull();

class _ResetTriggerNotNull extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (
			values.type === TriggerType.Threshold &&
			isNull(values.resetTriggerSetPoint)
		) {
			return NotValid(ValidationErrors.CannotBeNull);
		}
		return IsValid;
	}
}

const ResetTriggerNotNull = () => new _ResetTriggerNotNull();

class _MinValueNotNull extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (
			values.type === TriggerType.WindowComparator &&
			isNull(values.minValue)
		) {
			return NotValid(ValidationErrors.CannotBeNull);
		}
		return IsValid;
	}
}

const MinValueNotNull = () => new _MinValueNotNull();

class _MaxValueNotNull extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (
			values.type === TriggerType.WindowComparator &&
			isNull(values.maxValue)
		) {
			return NotValid(ValidationErrors.CannotBeNull);
		}
		return IsValid;
	}
}

const MaxValueNotNull = () => new _MaxValueNotNull();

class _MustSetConsecutive extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.enableConsecutiveReadings) {
			if (isNull(values.consecutiveReadings)) {
				return NotValid(ValidationErrors.CannotBeNull);
			}

			if (values.consecutiveReadings <= 0) {
				return NotValid(ValidationErrors.PositiveInteger);
			}

			if (!Number.isInteger(values.consecutiveReadings)) {
				return NotValid(ValidationErrors.PositiveInteger);
			}
		}
		return IsValid;
	}
}

const MustSetConsecutive = () => new _MustSetConsecutive();

class _MustSetSlidingAvg extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.enableSlidingAvgSamples) {
			if (isNull(values.slidingAvgSamples)) {
				return NotValid(ValidationErrors.CannotBeNull);
			}

			if (values.slidingAvgSamples <= 0) {
				return NotValid(ValidationErrors.PositiveInteger);
			}

			if (!Number.isInteger(values.slidingAvgSamples)) {
				return NotValid(ValidationErrors.PositiveInteger);
			}
		}
		return IsValid;
	}
}
const MustSetSlidingAvg = () => new _MustSetSlidingAvg();

class _MustSetHysteresis extends Validator<any> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.useHysteresis && values.type === TriggerType.WindowComparator) {
			if (values.direction === "outside" && values.minValue + values.hysteresisValue >= values.maxValue - values.hysteresisValue) {
				return NotValid("Invalid hysterisis value.");
			}
		
			if (isNull(values.hysteresisValue) || values.hysteresisValue === undefined) {
				return NotValid(ValidationErrors.CannotBeNull);
			}

			if (values.hysteresisValue <= 0) {
				return NotValid(ValidationErrors.PositiveInteger);
			}
		}
		return IsValid;
	}
}
const MustSetHysteresis = () => new _MustSetHysteresis();

export const fromModel = (model?: ITrigger): ChannelTriggerForm => {
	let type = TriggerType.Threshold;

	let direction: TriggerWindowRangeType = TriggerWindowRangeType.Inside;
	let useHysteresis: boolean = false;
	let hysteresisValue: number | null = null;
	let triggerOperator: TriggerOperators = TriggerOperators.GreaterThan;
	let triggerSetPoint: number | null = null;
	let resetTriggerOperator: TriggerOperators = TriggerOperators.LessThan;
	let resetTriggerSetPoint: number | null = null;
	let minValue: number | null = null;
	let maxValue: number | null = null;

	let enableConsecutiveReadings = false;
	let enableSlidingAvgSamples = false;
	let consecutiveReadings: number | null = null;
	let slidingAvgSamples: number | null = null;
	let filterType: DataFilterType = DataFilterType.MultipleReadings;

	if (model) {
		type = model.type;
	}

	if (model && model.windowConfig) {
		direction = model.windowConfig.direction;
		hysteresisValue = model.windowConfig.hysteresisValue;
		maxValue = model.windowConfig.maxValue;
		minValue = model.windowConfig.minValue;
		useHysteresis = model.windowConfig.useHysteresis;

		if (typeof hysteresisValue === 'string') {
			hysteresisValue = parseInt(hysteresisValue);
		}

		if (typeof maxValue === 'string') {
			maxValue = parseInt(maxValue);
		}

		if (typeof minValue === 'string') {
			minValue = parseInt(minValue);
		}
	}

	if (model && model.thresholdConfig) {
		resetTriggerOperator = model.thresholdConfig.resetTriggerOperator;
		resetTriggerSetPoint = model.thresholdConfig.resetTriggerSetPoint;
		triggerOperator = model.thresholdConfig.triggerOperator;
		triggerSetPoint = model.thresholdConfig.triggerSetPoint;
	}

	if (model && model.dataFilter) {
		consecutiveReadings = model.dataFilter.consecutiveReadings;
		enableConsecutiveReadings = consecutiveReadings !== null;

		slidingAvgSamples = model.dataFilter.slidingAvgSamples;
		enableSlidingAvgSamples = slidingAvgSamples !== null;

		filterType = model.dataFilter.type;
	}

	return {
		values: {
			type,
			direction,
			useHysteresis,
			hysteresisValue,
			triggerOperator,
			triggerSetPoint,
			resetTriggerOperator,
			resetTriggerSetPoint,
			minValue,
			maxValue,
			filterType,
			consecutiveReadings,
			slidingAvgSamples,
			enableSlidingAvgSamples,
			enableConsecutiveReadings,
		},
		fields: {
			type: FormField({
				key: 'type',
				validators: [ChannelTriggerTypeValidator()],
				skipInitialization: true,
			}),
			direction: FormField({
				key: 'direction',
				validators: [],
			}),
			useHysteresis: FormField({
				key: 'useHysteresis',
				validators: [MustSetHysteresis()],
			}),
			hysteresisValue: FormField({
				key: 'hysteresisValue',
				validators: [],
			}),
			triggerOperator: FormField({
				key: 'triggerOperator',
				validators: [],
			}),
			triggerSetPoint: FormField({
				key: 'triggerSetPoint',
				validators: [TriggetSetPointNotNull()],
			}),
			resetTriggerOperator: FormField({
				key: 'resetTriggerOperator',
				validators: [],
			}),
			resetTriggerSetPoint: FormField({
				key: 'resetTriggerSetPoint',
				validators: [ResetTriggerNotNull()],
			}),
			minValue: FormField({
				key: 'minValue',
				validators: [MinValueNotNull()],
			}),
			maxValue: FormField({
				key: 'maxValue',
				validators: [MaxValueNotNull()],
			}),
			consecutiveReadings: FormField({
				key: 'consecutiveReadings',
				validators: [],
			}),
			slidingAvgSamples: FormField({
				key: 'slidingAvgSamples',
				validators: [],
			}),
			filterType: FormField({
				key: 'filterType',
				validators: [],
			}),
			enableConsecutiveReadings: FormField({
				key: 'enableConsecutiveReadings',
				validators: [MustSetConsecutive()],
			}),
			enableSlidingAvgSamples: FormField({
				key: 'enableSlidingAvgSamples',
				validators: [MustSetSlidingAvg()],
			}),
		},
	};
};

export function toModel(model: IUpdateAlert, form: ChannelTriggerForm) {
	const { type } = form.values;

	let trigger: any = {};
	if (model.trigger) {
		trigger = model.trigger;
	}

	trigger.thresholdConfig = undefined;
	trigger.windowConfig = undefined;
	trigger.systemConfig = undefined;
	trigger.type = type;

	if (type === TriggerType.Threshold) {
		trigger.thresholdConfig = {};
		trigger.thresholdConfig.resetTriggerOperator =
			form.values.resetTriggerOperator;
		trigger.thresholdConfig.resetTriggerSetPoint =
			form.values.resetTriggerSetPoint;
		trigger.thresholdConfig.triggerOperator = form.values.triggerOperator;
		trigger.thresholdConfig.triggerSetPoint = form.values.triggerSetPoint;
		trigger.thresholdConfig.useHysteresis = false;
	} else if (type === TriggerType.WindowComparator) {
		trigger.windowConfig = {};
		trigger.windowConfig.direction = form.values.direction;
		trigger.windowConfig.hysteresisValue = form.values.hysteresisValue;
		trigger.windowConfig.maxValue = form.values.maxValue;
		trigger.windowConfig.minValue = form.values.minValue;
		trigger.windowConfig.useHysteresis = form.values.useHysteresis;
	}

	if (form.values.enableConsecutiveReadings) {
		if (!trigger.dataFilter) {
			trigger.dataFilter = {};
		}
		trigger.dataFilter.consecutiveReadings = form.values.consecutiveReadings;
		trigger.dataFilter.type = DataFilterType.MultipleReadings;
	} else {
		if (!trigger.dataFilter) {
			trigger.dataFilter = {};
		}
		trigger.dataFilter.consecutiveReadings = null;
		trigger.dataFilter.enableConsecutiveReadings = false;
	}

	if (form.values.enableSlidingAvgSamples) {
		if (!trigger.dataFilter) {
			trigger.dataFilter = {};
		}
		trigger.dataFilter.slidingAvgSamples = form.values.slidingAvgSamples;
		trigger.dataFilter.type = DataFilterType.SlidingAverage;
	} else {
		if (!trigger.dataFilter) {
			trigger.dataFilter = {};
		}
		trigger.dataFilter.slidingAvgSamples = null;
		trigger.dataFilter.enableSlidingAvgSamples = false;
	}

	if (!form.values.enableSlidingAvgSamples && !form.values.enableConsecutiveReadings) {
		trigger.dataFilter = null;
	}

	model.trigger = trigger;

	return model;
}
