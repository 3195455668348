<svelte:window on:click='close(event)' /> 
<div data-test='profile' ref:profile class={classes(blockClass, openClass)} on:click='toggle(e)'>
    <div class={element(blockClass, 'avatar')}>
        <img class='gravatar' src={gravatar} />
    </div>

    <div class={element(blockClass, 'name')}>
        {#if $user}
            {$user.name}
        {/if}
    </div>
    
    <i class="fas fa-chevron-down {open ? 'open' : ''}"></i>

    {#if open}
        <div transition:fly class={element(blockClass, 'dropdown-menu')}>
            <a on:click='logout()' 
            data-test='log-out'
            class="dropdown-item" 
            href="#"><i class="fas fa-sign-out"></i>Log Out</a>
        </div>
    {/if}
</div>

<script>
    import { url } from 'gravatar';
    import { classes } from 'Services/classes';
    import { fly } from 'svelte-transitions';
    import { element, modifier } from 'Services/bem';

    const blockClass = 'profile'; 
    export default {
        transitions: {
            fly
        },
        computed: {
            gravatar: ({ $user }) => {
                return $user ? url($user.email, {s: '100', r: 'pg', d: 'retro'}) : '';
            },
            openClass: ({ open }) => {
                return open ? modifier(blockClass, 'open'): '';
            }
        },
        data() {
            return {
                open: false
            }
        },
        helpers: {
            classes,
            element, 
            blockClass
        },
        methods: {
            close(event) {
                if (this.refs.profile.contains(event.target)) {
                    event.preventDefault();
                    return;
                }

                this.set({
                    open: false
                });
            },
            toggle() {
                const { open } = this.get();
                this.set({ open: !open });
            },
            logout() {
                this.store.logout();
            }
        }
    }
</script>