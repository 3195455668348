import * as store2 from 'store2';

import { CognitoIdentityCredentials, config } from 'aws-sdk/global';
import {
	CommandTag,
	ConfigBlock,
	DataBlock,
	IAlert,
	IAlertInstance,
	IBillingSerie,
	IChannel,
	ICommand,
	IContact,
	IDebugFilter,
	IDebugLog,
	IDevice,
	IEmailOptions,
	IExceptionItem,
	IExportLogQuery,
	IFinishUpload,
	IFtpOptions,
	IGroup,
	ILogQuery,
	INewUser,
	IRemoteControlVariable,
	IReport,
	IReportInstance,
	ISMSOptions,
	ISessionEnable,
	IStateClear,
	ITestResult,
	IUpdateAlert,
	IUpdateContact,
	IUpdateDebugFilter,
	IUpdateRemoteControlVariable,
	IUpdateReport,
	IUpdateTheme,
	IUpdateWidget,
	IUpload,
	IUser,
	IView,
	IWidget,
	IWidgetDataRequest,
	LogFile,
	LoggerStatus,
	Permission,
	Topics,
	ViewRangeType,
	WidgetType,
} from '@shanewwarren/aqlcloud-shared-types';
import { DevicesEventType, TimeType } from '../services/constants';
import { Personita, User } from 'personita';
import {
	fromModel as companyInfoFromModel,
	toModel as companyInfoToModel,
} from '../services/forms/account/companyInfo';
import {
	fromModel as customizeFromModel,
	toModel as customizeToModel,
} from '../services/forms/account/customize';
import {
	fromModel as reportChannelSelectFromModel,
	toModel as reportChannelSelectToModel,
} from '../services/forms/report/channelSelect';
import {
	fromModel as reportDetailsFromModel,
	toModel as reportDetailsToModel,
} from '../services/forms/report/reportDetails';
import {
	fromModel as reportNameFromModel,
	toModel as reportNameToModel,
} from '../services/forms/report/reportName';
import {
	fromModel as reportTypeFromModel,
	toModel as reportTypeToModel,
} from '../services/forms/report/reportType';
import { subDays, subHours, subMonths } from 'date-fns';

import { AlertTag } from '@shanewwarren/aqlcloud-shared-types/dist/types';
import { Api } from './api';
import { AqlBroker } from './aql-broker';
import { CancelTokenSource } from 'axios';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { DataEmitter } from './data-emitter';
import { Store } from 'svelte/store.js';
import { dateCompare } from '../services/table';
import { getDeviceChannelResults } from '../services/model-helpers';
import { hasValue } from '../services/number';
import { isChannelAlert } from '../services/alert';

interface IStoreConfig {
	clientId: string;
	userPoolId: string;
	identityPoolId: string;
	apiEndpoint: string;
	region: string;
	iotEndpoint: string;
}

const CUSTOMIZE_KEY = 'customize';
const COMM_LOG_KEY = 'commlog';

const STORE_DEFAULTS = {
	loggedIn: false,
	user: null,
	fullAccess: false,
	channelAlerts: [],
	systemAlerts: [],
	contacts: [],
	reports: [],
	devices: [],
	groups: [],
	views: [],
	users: [],
	exceptions: [],
	alertInstances: [],
	deviceAlertStatus: {},
	nonAcknowledgedAlertInstanceCount: 0,
	reportsDownloading: {},
	selectedCompanyInfo: null,
	selectedCustomize: null,
	selectedAlert: null,
	selectedReport: null,
	selectedGroup: null,
	selectedDevice: null,
	selectedView: null,
	listeners: {},
	notificationShow: false,
	notificationMessage: '',
	notificationColor: 'success',
	progressShow: false,
	errorShow: false,
	errorMessage: '',
	expandWidget: null,
	widgetStates: {},
	widgetDates: {},
	toolbarOpen: false,
	version: '',
};

export class ApplicationStore extends Store {
	private api: Api;
	private aqlBroker: AqlBroker | null;
	private config: IStoreConfig;
	private personita: Personita;
	private deviceLookup: {
		[serialNumber: string]: { [iconNumber: string]: IChannel };
	} = {};

	private passwordChallengeAttributes: { [key: string]: string };
	public dataEmitter: DataEmitter = new DataEmitter();

	constructor(appConfig: IStoreConfig, state: any) {
		// Defaults
		super(STORE_DEFAULTS);

		this.config = appConfig;

		// Initialize personita.
		this.personita = new Personita(
			{
				ClientId: this.config.clientId,
				UserPoolId: this.config.userPoolId,
			},
			this.config.region,
			this.config.identityPoolId
		);

		this.api = new Api(appConfig.apiEndpoint, this.personita);
	}

	public async initialize(): Promise<void> {
		// Gets the session from local storage.
		try {
			const session: CognitoUserSession | null = await this.personita.initialize();
			this.personita.refreshCognitoCreds();
			this.api.updateAuthorization(session);
			await this.updateUserSession(session);
			await this.activateUser(session);

			this.initalizeAqlBroker();
			if (this.aqlBroker) {
				this.aqlBroker.connect(session);
			}

			const sentry = (window as any).Sentry;
			const { user } = super.get();
			if (sentry && user) {
				sentry.configureScope(scope => {
					scope.setUser({
						email: user.email,
						id: user.userId,
						username: user.name,
					});
				});
			}

			await this.updateCustomizations();
			await this.initializeDevices();
		} catch (err) {
			// console.log(err);
		}
	}

	private timeoutHandle: NodeJS.Timer | undefined;
	public showNotification(message: string, color: string = 'success') {
		super.set({
			progressShow: false,
			notificationShow: true,
			notificationMessage: message,
			notificationColor: color,
		});

		if (this.timeoutHandle) {
			clearTimeout(this.timeoutHandle);
			this.timeoutHandle = undefined;
		}

		this.timeoutHandle = setTimeout(() => {
			super.set({
				notificationShow: false,
			});
		}, 3500);
	}

	public showError(message: string) {
		const regex = /something went wrong/i;
		message = message.trim();
		if (message.match(regex)) {
			message = 'Something went wrong.';
		}

		if (!message.endsWith('.')) {
			message = message + '.';
		}

		super.set({
			progressShow: false,
			errorShow: true,
			errorMessage: `${message} \nIf this error continues, please contact the system administrator.`,
		});

		setTimeout(() => {
			super.set({
				errorShow: false,
			});
		}, 3500);
	}

	public toggleToolbar(open: boolean) {
		super.set({ toolbarOpen: open });
	}

	public showProgress() {
		super.set({ progressShow: true });
	}

	public hideProgress() {
		super.set({ progressShow: false });
	}

	public get isLoggedIn(): any {
		const { loggedIn } = super.get();
		return loggedIn;
	}

	public async updateCustomizations() {
		const customization = await store2.get(CUSTOMIZE_KEY);
		if (customization) {
			super.set({
				selectedCustomize: customization,
			});
		} else {
			try {
				await this.getAccount();
			} catch (err) {
				// not logged in.
			}
		}
	}

	public async completeNewPasswordChallenge(
		username: string,
		password: string
	) {
		localStorage.clear();
		return this.personita.completeNewPasswordChallenge(
			username,
			password,
			this.passwordChallengeAttributes
		);
	}

	public async login(username: string, password: string) {
		localStorage.clear();
		const result: User.IAuthenticationResult = await this.personita.authenticate(
			username,
			password
		);

		if (result.status === User.AuthenticationStatus.Success) {
			const sessionPayload: User.ISessionSuccess = result.payload as User.ISessionSuccess;
			const session: CognitoUserSession = sessionPayload.session;

			this.api.updateAuthorization(session);
			await this.updateUserSession(session);
			await this.activateUser(session);

			this.initalizeAqlBroker();
			if (this.aqlBroker) {
				this.aqlBroker.connect(session);
			}
			await this.initializeDevices();
		} else if (
			result.status === User.AuthenticationStatus.NewPasswordRequired
		) {
			const challenge: User.INewPasswordRequiredResult = result.payload as User.INewPasswordRequiredResult;
			delete challenge.userAttributes.email_verified;
			delete challenge.userAttributes.email;
			this.passwordChallengeAttributes = challenge.userAttributes;
		}

		await this.updateCustomizations();

		return result;
	}

	public async logout() {
		this.personita.logout();
		localStorage.clear();

		this.api.updateAuthorization(null);
		this.updateUserSession(null);
		if (this.aqlBroker) {
			this.aqlBroker.disconnect();
			this.aqlBroker = null;
		}
		await store2.set(CUSTOMIZE_KEY, null);
		this.deviceLookup = {};
		super.set(STORE_DEFAULTS);
	}

	public async getBillingSummary() {
		return this.api.getBillingSummary();
	}

	public async activateUser(session: CognitoUserSession | null) {
		if (!session || !session.isValid()) {
			return;
		}

		const credentials: CognitoIdentityCredentials = config.credentials as CognitoIdentityCredentials;
		const identityId = credentials.identityId;

		const { user } = super.get();
		if (user && !user.active) {
			await this.api.activateUser(identityId);
		}
	}

	public async register(name: string, email: string, password: string) {
		localStorage.clear();
		await this.personita.register(name, email, password, { name: name });
	}

	public async confirmRegistration(username: string, confirmationCode: string) {
		localStorage.clear();
		await this.personita.confirmRegistration(username, confirmationCode);
	}

	public async resendCode(username: string) {
		localStorage.clear();
		await this.personita.resendCode(username);
	}

	public async resetPassword(oldPassword: string, newPassword: string) {
		localStorage.clear();
		await this.personita.changePassword(oldPassword, newPassword);
	}

	public async forgotPassword(username: string) {
		localStorage.clear();
		await this.personita.forgotPassword(username);
	}

	public async confirmNewPassword(
		email: string,
		verificationCode: string,
		password: string
	) {
		localStorage.clear();
		await this.personita.confirmNewPassword(email, verificationCode, password);
	}

	public async getAccount() {
		const account = await this.api.getAccount();
		const companyInfo = companyInfoFromModel(account);
		const customize = customizeFromModel(account.theme);

		await store2.set(CUSTOMIZE_KEY, customize);

		super.set({
			selectedCompanyInfo: companyInfo,
			selectedCustomize: customize,
		});
	}

	public async updateCompanyInfo() {
		let { selectedCompanyInfo } = super.get();

		const companyInfoModel = companyInfoToModel(selectedCompanyInfo);
		const account = await this.api.updateCompanyInfo(companyInfoModel);
		selectedCompanyInfo = companyInfoFromModel(account);

		super.set({ selectedCompanyInfo });
	}

	public async updateTheme(updatedTheme: IUpdateTheme) {
		let { selectedCustomize } = super.get();

		const customizeModel = customizeToModel(selectedCustomize);
		const theme = await this.api.updateTheme(customizeModel);
		selectedCustomize = customizeFromModel(theme);

		store2.set(CUSTOMIZE_KEY, selectedCustomize);

		super.set({ selectedCustomize });
	}

	public clearLocalStorageValues() {
		store2.remove(CUSTOMIZE_KEY);
	}

	public async createUser(newUser: INewUser) {
		const { users } = super.get();
		const user = await this.api.createUser(newUser);
		users.push(user);
		super.set({ users });
	}

	public async deleteUser(userId) {
		const { users } = super.get();
		await this.api.deleteUser(userId);

		const index = users.findIndex(user => user.userId === userId);
		users.splice(index, 1);
		super.set({ users });
	}

	public async updateUser(userId, updatedUser: INewUser) {
		const { users } = super.get();
		const user = await this.api.updateUser(userId, updatedUser);

		const index = users.findIndex(u => u.userId === user.id);
		users[index] = user;
		super.set({ users });
	}

	private initalizeAqlBroker() {
		this.aqlBroker = new AqlBroker(
			{
				region: this.config.region,
				host: this.config.iotEndpoint,
				debug: false,
			},
			this.onDataReceived.bind(this),
			this.onStatusReceived.bind(this),
			this.onSessionReceived.bind(this),
			this.onTriggerReceived.bind(this),
			this.onCommandReceived.bind(this)
		);
	}

	public async updateUserSession(session: CognitoUserSession | null) {
		if (!session) {
			super.set({
				loggedIn: false,
				user: null,
			});
			return;
		}

		const attrs: object | null = await this.personita.getUserAttributes();

		if (!attrs) {
			super.set({
				loggedIn: false,
				user: null,
			});
			return;
		}

		const user: IUser = {
			accountId: attrs['custom:accountId'],
			userId: attrs['custom:userId'],
			active: attrs['custom:active'] === 'true' ? true : false,
			permission: attrs['custom:permission'],
			email: attrs['email'],
			name: attrs['name'],
		};

		super.set({
			loggedIn: true,
			user: user,
			fullAccess: user.permission !== Permission.LimitedAccess,
		});
	}

	public getUser(userId: string) {
		const { users } = super.get();
		return users.find(u => u.userId === userId);
	}

	public async getUsers() {
		const users: IUser[] = await this.api.getAllUsers();
		// console.log(users);
		super.set({ users });

		return users;
	}

	public async expandWidget(widgetId: string | null) {
		super.set({ expandWidget: widgetId });
	}

	public async createWidget(name: string, widgetType: string) {
		const { selectedView, widgetStates } = super.get();

		const widget: IWidget = await this.api.createWidget(
			selectedView.id,
			name,
			widgetType
		);

		const widgetState = this.initializeWidgets([widget], widgetStates);
		widgetStates[widget.id] = widgetState[widget.id];
		selectedView.widgets.push(widget);

		super.set({ selectedView, widgetStates });
	}

	public async updateWidgetDetails(widgetId, payload: IUpdateWidget) {
		const { selectedView } = super.get();

		const widget: IWidget = await this.api.updateWidgetDetails(
			selectedView.id,
			widgetId,
			payload
		);

		// update the widget in the selected view.
		const index = selectedView.widgets.findIndex(w => w.id === widget.id);
		selectedView.widgets[index].name = widget.name;
		super.set({ selectedView });
	}

	public async updateWidget(payload: any) {
		const { selectedView } = super.get();

		const widget: IWidget = await this.api.updateWidget(
			selectedView.id,
			payload.id,
			payload.type,
			payload.type === WidgetType.Table ? payload.deviceIds : payload.channelIds
		);

		// update the widget in the selected view.
		const index = selectedView.widgets.findIndex(w => w.id === widget.id);
		selectedView.widgets[index] = widget;

		super.set({ selectedView });
	}

	public async deleteWidget(widgetId: string) {
		const { selectedView } = super.get();
		return this.api.deleteWidget(selectedView.id, widgetId).then(() => {
			const index = selectedView.widgets.findIndex(
				widget => widget.id === widgetId
			);
			if (index >= 0) {
				selectedView.widgets.splice(index, 1);
				super.set({ selectedView });
			}
		});
	}

	public async deleteView() {
		const { selectedView, views } = super.get();
		await this.api.deleteView(selectedView.id);

		const index = views.findIndex(view => view.id === selectedView.id);
		views.splice(index, 1);

		if (views.length > 0) {
			const defaultView = views.find((view: IView) => view.default);
			if (defaultView) {
				await this.selectView(defaultView.id);
			} else {
				await this.selectView(views[0].id);
			}
		} else {
			super.set({
				selectedView: null,
			});
		}

		super.set({
			views,
		});
	}

	public async setLogQuery(logQuery) {
		return store2.set(COMM_LOG_KEY, logQuery);
	}

	public async getLogQuery() {
		const query = await store2.get(COMM_LOG_KEY);
		if (!query || query === 'undefined') {
			return undefined;
		}
		const { groups } = super.get();
		const selectedGroups = groups.filter(g =>
			query && query.group ? query.group.includes(g.name) : false
		);
		query.group = selectedGroups;

		if (query.group.length === 0) {
			await this.setLogQuery(undefined);
			return undefined;
		}
		return query;
	}

	public async exportLogs(logQuery: ILogQuery, callback) {
		const exportLogQuery: IExportLogQuery = {
			...logQuery,
			timezoneOffset: new Date().getTimezoneOffset(),
		};
		return this.api.exportLogs(exportLogQuery, callback);
	}

	public async getLogs(logQuery: ILogQuery) {
		return this.api.getLogs(logQuery);
	}

	public async getInitialLogs(logQuery: ILogQuery) {
		const { groups } = super.get();
		if (!groups || groups.length === 0) {
			await this.getGroups();
		}
		return this.getLogs(logQuery);
	}

	public async createView(viewName: string) {
		const createdView: IView = await this.api.createView(viewName);
		const view: IView = await this.api.getView(createdView.id);
		const { views } = super.get();

		views.push(view);
		super.set({ views });
		return this.selectView(view.id);
	}

	public async setViewDefault() {
		const { selectedView, views } = super.get();
		await this.api.setViewDefault(selectedView.id);

		selectedView.default = true;

		super.set({
			selectedView,
			views: views.map(view => {
				view.default = view.id === selectedView.id;
				return view;
			}),
		});
	}

	public async updateViewRange(rangeType: string) {
		const { selectedView, views } = super.get();
		selectedView.rangeType = rangeType;

		const updatedView = await this.api.updateView(
			selectedView.id,
			selectedView.name,
			selectedView.default,
			selectedView.rangeType,
			undefined,
			undefined
		);

		const index = views.findIndex(v => v.id === selectedView.id);
		if (index >= 0) {
			views[index].rangeType = updatedView.rangeType;
			views[index].startDate = updatedView.startDate;
			views[index].endDate = updatedView.endDate;
			super.set({ views });
		}

		await this.selectView(selectedView.id);
	}

	public async updateViewCustomRange(startDate: Date, endDate: Date) {
		const { selectedView, views } = super.get();
		selectedView.rangeType = ViewRangeType.Custom;
		selectedView.startDate = startDate;
		selectedView.endDate = endDate;

		await this.api.updateView(
			selectedView.id,
			selectedView.name,
			selectedView.default,
			selectedView.rangeType,
			selectedView.startDate,
			selectedView.endDate
		);

		const index = views.findIndex(v => v.id === selectedView.id);
		if (index >= 0) {
			views[index].rangeType = selectedView.rangeType;
			views[index].startDate = selectedView.startDate;
			views[index].endDate = selectedView.endDate;
			super.set({ views });
		}

		await this.selectView(selectedView.id);
	}

	public async setViewGroups(groupIds: string[]) {
		const { selectedView } = super.get();
		const view: IView = await this.api.setViewGroups(selectedView.id, groupIds);
		super.set({
			selectedView: view,
		});
	}

	public async getViewData() {
		return Promise.all([this.getViews(), this.getGroups(), this.getDevices()]);
	}

	public async getDebugFilter(deviceId): Promise<IDebugFilter> {
		return this.api.getDebugFilter(deviceId);
	}

	public async updateDebugFilter(
		deviceId,
		debugFilter: IUpdateDebugFilter
	): Promise<IDebugFilter> {
		return this.api.updateDebugFilter(deviceId, debugFilter);
	}

	public async getDebugLogs(deviceId: string): Promise<IDebugLog[]> {
		return this.api.getDebugLogs(deviceId);
	}

	public async getDebugLog(url: string): Promise<LogFile> {
		return this.api.getDebugLog(url);
	}

	public async triggerNewDebugLog(
		deviceId: string,
		clear: boolean
	): Promise<IDebugLog> {
		return this.api.triggerNewDebugLog(deviceId, clear);
	}

	public async triggerClearDebugLog(deviceId: string) {
		return this.api.triggerClearDebugLog(deviceId);
	}

	public async clearStateFlag(deviceId: string, stateClear: IStateClear) {
		return this.api.clearStateFlag(deviceId, stateClear);
	}

	public async getRemoteVariables(deviceId): Promise<IRemoteControlVariable[]> {
		return this.api.getRemoteVariables(deviceId);
	}

	public async getExceptions(): Promise<IExceptionItem[]> {
		const exceptions = await this.api.getExceptions();
		super.set({ exceptions });
		return exceptions;
	}

	public async acknowledgeByPortal(
		alertInstanceId: string
	): Promise<IAlertInstance> {
		const result = await this.api.acknowledgeByPortal(alertInstanceId);
		return result;
	}

	public async getNonAcknowledgedAlertInstanceCount(): Promise<{
		count: number;
	}> {
		const result = await this.api.getNonAcknowledgedAlertInstanceCount();
		super.set({ nonAcknowledgedAlertInstanceCount: result.count });
		return result;
	}

	public async acknowledgeException(
		deviceId: string,
		exceptionId: string
	): Promise<IExceptionItem> {
		const exception = await this.api.acknowledgeException(
			deviceId,
			exceptionId
		);

		const { exceptions } = super.get();
		super.set({
			exceptions: exceptions.filter(e => e.id !== exceptionId),
		});

		return exception;
	}

	public async blockException(
		deviceId: string,
		exceptionId: string
	): Promise<IExceptionItem> {
		const exception = await this.api.blockException(deviceId, exceptionId);

		const { exceptions } = super.get();
		super.set({
			exceptions: exceptions.filter(e => e.id !== exceptionId),
		});

		return exception;
	}

	public async getStorageDetails(): Promise<IBillingSerie[]> {
		return this.api.getStorageDetails();
	}

	public async deleteSeries(deviceId: string, seriesId: number): Promise<void> {
		return this.api.deleteSeries(deviceId, seriesId);
	}

	public async updateSession(
		deviceId: string,
		sessionEnable: ISessionEnable
	): Promise<IDevice> {
		return this.api.updateSession(deviceId, sessionEnable);
	}

	public async updateRemoteVariables(
		deviceId,
		variables: IUpdateRemoteControlVariable[]
	): Promise<IRemoteControlVariable[]> {
		return this.api.updateRemoteVariables(deviceId, variables);
	}

	public async selectView(id: string) {
		const view: IView = await this.api.getView(id);

		const { selectedView, expandWidget, widgetStates } = super.get();
		if (expandWidget) {
			this.set({ expandWidget: null });
		}
		if (!selectedView) {
			await this.getViewData();
		}

		const states: any = this.initializeWidgets(view.widgets, widgetStates);

		super.set({
			selectedView: view,
			widgetStates: states,
		});

		return view;
	}

	public async getDefaultView() {
		return await this.api.getDefaultView();
	}

	public async updateDefaultViewWidgets(view: IView) {
		const { widgetStates } = super.get();
		let states: any = this.initializeWidgets(view.widgets, widgetStates);

		super.set({ widgetStates: states, selectedView: view });
	}

	private getStartDate(view: IView): Date {
		const now = new Date();
		switch (view.rangeType) {
			case ViewRangeType.Hour:
				return subHours(now, 1);
			case ViewRangeType.Day:
				return subDays(now, 1);
			case ViewRangeType.Week:
				return subDays(now, 7);
			case ViewRangeType.Month:
				return subMonths(now, 1);
			case ViewRangeType.Custom:
				return view.startDate || now;
		}
	}

	private getEndDate(view: IView): Date {
		const now = new Date();
		switch (view.rangeType) {
			case ViewRangeType.Hour:
				return now;
			case ViewRangeType.Day:
				return now;
			case ViewRangeType.Week:
				return now;
			case ViewRangeType.Month:
				return now;
			case ViewRangeType.Custom:
				return view.endDate || now;
		}
	}

	public async getWidgetData(
		widget: IWidget,
		limit: number,
		offset: number,
		startDate: Date,
		endDate: Date,
		pcTime: boolean,
		cancelToken: CancelTokenSource
	) {
		const query: IWidgetDataRequest = {
			channelIds: widget.channels.map(item => item.id),
			startDate,
			endDate,
			rangeType: ViewRangeType.Custom,
			offset,
			limit,
			pcTime,
		};

		const result = await this.api.getWidgetData(
			cancelToken,
			widget.viewId,
			widget.id,
			query
		);

		return result ? result.measurements : undefined;
	}

	public getWidgetDataDateRange = (widget: IWidget) => {
		const { views } = super.get();
		const selectedView = views.find(v => v.id === widget.viewId);
		const startDate = this.getStartDate(selectedView);
		const endDate = this.getEndDate(selectedView);

		return { startDate, endDate };
	};

	public async getWidgetTable(
		widget: IWidget,
		pcTime: boolean,
		cancelToken: CancelTokenSource
	) {
		const { views } = super.get();
		const selectedView = views.find(v => v.id === widget.viewId);
		const startDate = this.getStartDate(selectedView);
		const endDate = this.getEndDate(selectedView);

		const query: IWidgetDataRequest = {
			deviceIds: widget.devices.map(item => item.id),
			startDate,
			endDate,
			rangeType: ViewRangeType.Custom,
			pcTime,
		};

		const result = await this.api.getWidgetData(
			cancelToken,
			selectedView.id,
			widget.id,
			query
		);

		return result ? result.tableMeasurements : undefined;
	}

	private initializeWidgets(
		widgets: IWidget[],
		widgetStates: { [id: string]: any }
	) {
		const states = {};
		for (let index = 0; index < widgets.length; index++) {
			const widget = widgets[index];
			if (widgetStates[widget.id]) {
				states[widget.id] = widgetStates[widget.id];
				continue;
			}

			if (widget.widgetType === WidgetType.Graph) {
				states[widget.id] = {
					pcTime: TimeType.PcTime,
					widgetType: widget.widgetType,
				};
			} else if (widget.widgetType === WidgetType.List) {
				states[widget.id] = {
					pcTime: TimeType.PcTime,
					widgetType: widget.widgetType,
				};
			} else if (widget.widgetType === WidgetType.Table) {
				states[widget.id] = {
					pcTime: TimeType.PcTime,
					widgetType: widget.widgetType,
				};
			}
		}
		return states;
	}

	public toggleWidgetDate(widgetId: string, timeType: string) {
		const { widgetStates } = super.get();
		if (widgetStates[widgetId].pcTime === undefined) {
			widgetStates[widgetId].pcTime = TimeType.PcTime;
		}

		if (widgetStates[widgetId].pcTime === timeType) {
			return;
		}
		widgetStates[widgetId].pcTime = timeType;
		super.set({ widgetStates });
	}

	public async getViews() {
		const views: IView[] = await this.api.getViews();

		super.set({
			views,
		});

		return views;
	}

	public async getAlert(alertId) {
		const alert = await this.api.getAlert(alertId);
		super.set({ selectedAlert: alert });
		return alert;
	}

	public async getAlertInstances(deviceId: string): Promise<IAlertInstance[]> {
		const instances = await this.api.getAlertInstances(deviceId);
		super.set({ alertInstances: instances });
		return instances;
	}

	public async createGroup(groupName: string, deviceIds: string[]) {
		const result = await this.api.createGroup(groupName, deviceIds);

		const { groups } = super.get();
		const group: IGroup = result;
		groups.push(group);

		super.set({ groups });
	}

	public async updateGroup(groupName: string, deviceIds: string[]) {
		const { selectedGroup } = super.get();

		const group: IGroup = await this.api.updateGroup(
			selectedGroup.id,
			groupName,
			deviceIds
		);
		const { groups } = super.get();

		const index = groups.findIndex(g => g.id === group.id);
		groups[index] = group;

		super.set({
			groups,
			selectedGroup: group,
		});
	}

	public async deleteGroup(groupId: string): Promise<void> {
		const { groups } = super.get();
		const index = groups.findIndex(g => g.id === groupId);

		await this.api.deleteGroup(groupId);

		groups.splice(index, 1);

		super.set({ groups });
	}

	public async getGroup(id: string) {
		const group: IGroup = await this.api.getGroup(id);

		super.set({
			selectedGroup: group,
		});

		return group;
	}

	public async getGroups() {
		const groups: IGroup[] = await this.api.getGroups('/groups');
		super.set({
			groups,
		});

		// this.listenToGroups(groups);

		return groups;
	}

	public async getDevice(id) {
		const { devices } = super.get();

		const device = devices.findIndex(d => d.id === id);

		return device;
	}

	public async getDevices() {
		const devices: IDevice[] = await this.api.getDevices();

		super.set({
			devices,
		});

		return devices;
	}

	public async getDeviceAlertStatus(deviceIds) {
		const { deviceAlertStatus } = super.get();
		const alertStatuses = await this.api.getDeviceAlertStatus(deviceIds);

		// merge the results
		Object.keys(alertStatuses).forEach(key => {
			deviceAlertStatus[key] = {
				...deviceAlertStatus[key],
				...alertStatuses[key],
			};
		});

		super.set({ deviceAlertStatus });
		return deviceAlertStatus;
	}

	public async initializeDevices() {
		const devices = await this.getDevices();
		this.addDeviceListeners(devices);
	}

	public async associateDevice(serialNumber: number, provision: string) {
		return this.api.associateDevice(serialNumber, provision).then(device => {
			const { devices } = super.get();
			devices.push(device);
			super.set({ devices });

			// Make sure to listen to the new device
			this.addDeviceListeners([device]);
		});
	}

	public async getAlerts() {
		const alerts: IAlert[] = await this.api.getAlerts();
		// console.log(alerts);
		super.set({
			channelAlerts: alerts.filter(alert => isChannelAlert(alert.trigger)),
			systemAlerts: alerts.filter(alert => !isChannelAlert(alert.trigger)),
		});

		return alerts;
	}

	public async toggleAlert(
		alertType: string,
		alertId: string,
		enabled: boolean
	) {
		const alert = await this.api.toggleAlert(alertId, enabled);
		if (alertType === 'system') {
			const { systemAlerts } = super.get();
			const index = systemAlerts.findIndex(a => a.id === alertId);
			systemAlerts[index] = alert;
			super.set({ systemAlerts });
		} else {
			const { channelAlerts } = super.get();
			const index = channelAlerts.findIndex(a => a.id === alertId);
			channelAlerts[index] = alert;
			super.set({ channelAlerts });
		}
		return alert;
	}

	public async createAlert(updateAlert: IUpdateAlert): Promise<IAlert> {
		const alert: IAlert = await this.api.createAlert(updateAlert);

		if (isChannelAlert(alert.trigger)) {
			const { channelAlerts } = super.get();
			channelAlerts.push(alert);
			super.set({ channelAlerts });
		} else {
			const { systemAlerts } = super.get();
			systemAlerts.push(alert);
			super.set({ systemAlerts });
		}

		return alert;
	}

	public async updateAlert(
		alertId: string,
		updateAlert: IUpdateAlert
	): Promise<IAlert> {
		const alert: IAlert = await this.api.updateAlert(alertId, updateAlert);

		if (isChannelAlert(alert.trigger)) {
			const { channelAlerts } = super.get();
			const index = channelAlerts.findIndex(g => g.id === alert.id);
			channelAlerts[index] = alert;
			super.set({ channelAlerts });
		} else {
			const { systemAlerts } = super.get();
			const index = systemAlerts.findIndex(g => g.id === alert.id);
			systemAlerts[index] = alert;
			super.set({ systemAlerts });
		}

		super.set({ selectedAlert: alert });

		return alert;
	}

	public async disassociateDevice(
		deviceId: string,
		clearData: boolean
	): Promise<void> {
		const { devices } = super.get();
		const device = devices.find(device => device.id === deviceId);

		// remove the device listener
		super.set({
			devices: devices.filter(d => d.serialNumber !== device.serialNumber),
		});
		this.removeDeviceListener(device.serialNumber);
		await this.api.disassociateDevice(device.serialNumber, clearData);
	}

	public async deleteAlerts(alertIds: string[]): Promise<void> {
		const promises = alertIds.map(alertId => this.api.deleteAlert(alertId));
		await promises;

		const { systemAlerts, channelAlerts } = super.get();

		for (const alertId of alertIds) {
			let index = systemAlerts.findIndex(alert => alert.id === alertId);
			if (index >= 0) {
				systemAlerts.splice(index, 1);
			}

			index = channelAlerts.findIndex(alert => alert.id === alertId);
			if (index >= 0) {
				channelAlerts.splice(index, 1);
			}
		}

		super.set({
			channelAlerts,
			systemAlerts,
		});
	}

	public async getContacts(): Promise<IContact[]> {
		let { users } = super.get();
		if (!users || users.length === 0) {
			users = await this.api.getAllUsers();
		}

		const contacts: IContact[] = await this.api.getContacts();
		contacts.forEach(contact => this.isUserContact(users, contact));

		super.set({
			contacts,
			users,
		});
		return contacts;
	}

	public getContactsFromIds(ids: string[]): IContact[] {
		const { contacts } = super.get();
		return contacts.filter(contact => ids.includes(contact.id));
	}

	public isUserContact(users, contact) {
		const foundIndex = users.findIndex(user => user.contactId === contact.id);
		contact.isUser = foundIndex >= 0;
	}

	public async createContact(updateContact: IUpdateContact): Promise<IContact> {
		const contact: IContact = await this.api.createContact(updateContact);

		const { contacts } = super.get();
		contacts.push(contact);

		super.set({
			contacts,
		});

		return contact;
	}

	public getContact(contactId: string): IContact {
		const { contacts } = super.get();
		const contact = contacts.find(c => c.id === contactId);
		return contact;
	}

	public async updateContact(
		contactId: string,
		updateContact: IUpdateContact
	): Promise<IContact> {
		const contact: IContact = await this.api.updateContact(
			contactId,
			updateContact
		);

		const { contacts } = super.get();
		const index = contacts.findIndex(c => c.id === contact.id);
		contacts[index] = contact;

		super.set({
			contacts,
		});

		return contact;
	}

	public async deleteContact(contactId: string): Promise<void> {
		await this.api.deleteContact(contactId);

		const { contacts } = super.get();
		const index = contacts.findIndex(contact => contact.id === contactId);
		contacts.splice(index, 1);

		super.set({
			contacts,
		});
	}

	public async getReports(): Promise<IReport[]> {
		const reports: IReport[] = await this.api.getReports();
		super.set({
			reports,
		});
		return reports;
	}

	public async saveReport(reportId?: string): Promise<IReport> {
		const { selectedReport } = super.get();
		const report: any = {};

		reportNameToModel(report, selectedReport.name);
		reportChannelSelectToModel(report, selectedReport.select);
		reportDetailsToModel(report, selectedReport.details);
		reportTypeToModel(report, selectedReport.type);

		if (reportId) {
			return this.updateReport(reportId, report);
		} else {
			return this.createReport(report);
		}
	}

	public async createReport(updateReport: IUpdateReport): Promise<IReport> {
		const report: IReport = await this.api.createReport(updateReport);
		const { reports } = super.get();
		reports.push(report);
		super.set({
			reports,
		});
		return report;
	}

	public async newReport(): Promise<IReport> {
		const report: any = {};

		report.name = reportNameFromModel();
		report.select = reportChannelSelectFromModel();
		report.details = reportDetailsFromModel();
		report.type = reportTypeFromModel();

		super.set({ selectedReport: report });
		return report;
	}

	public async getReport(reportId: string): Promise<IReport> {
		const report: IReport = await this.api.getReport(reportId);
		super.set({
			selectedReport: report,
		});
		return report;
	}

	public async getReportStatus(
		reportId: string,
		reportInstanceId: string
	): Promise<IReportInstance> {
		const report: IReportInstance = await this.api.getReportStatus(
			reportId,
			reportInstanceId
		);

		const { reportsDownloading } = super.get();
		reportsDownloading[report.id] = report;
		super.set({ reportsDownloading });

		return report;
	}

	public async getReportDownload(reportId: string): Promise<IReportInstance> {
		const report: IReportInstance = await this.api.getReportDownload(reportId);

		const { reportsDownloading } = super.get();
		reportsDownloading[report.id] = report;
		super.set({ reportsDownloading });

		return report;
	}

	public async reportCopy(reportId: string): Promise<IReport> {
		const report: IReport = await this.api.reportCopy(reportId);
		const { reports } = super.get();
		reports.push(report);
		super.set({ reports });
		return report;
	}

	public async clearReportDownload(reportInstanceId: string) {
		const { reportsDownloading } = super.get();
		delete reportsDownloading[reportInstanceId];
		super.set({ reportsDownloading });
	}

	public async updateReport(
		reportId: string,
		updateReport: IUpdateReport
	): Promise<IReport> {
		const report: IReport = await this.api.updateReport(reportId, updateReport);

		const { reports } = super.get();
		const index = reports.findIndex(r => r.id === report.id);
		reports[index] = report;

		super.set({
			reports,
		});

		return report;
	}

	public async deleteReports(reportIds: string[]): Promise<void> {
		const promises = reportIds.map(id => this.api.deleteReport(id));
		await promises;

		const { reports } = super.get();

		for (const reportId of reportIds) {
			const index = reports.findIndex(report => report.id === reportId);
			if (index >= 0) {
				reports.splice(index, 1);
			}
		}

		super.set({
			reports,
		});
	}

	public async handleUpload(type: string, file: any, deviceIds: string[]) {
		const start: IUpload = await this.api.startUpload({ mimeType: file.type });

		await this.api.doUpload(start.fileUrl, file);

		const topic: Topics.MQTTFileTopic =
			type === DevicesEventType.Firmware
				? Topics.MQTTFileTopic.Firmware
				: Topics.MQTTFileTopic.ProgramNet;

		const finished: IFinishUpload = {
			deviceIds,
			fileUrl: start.fileUrl,
			topic,
		};

		const updatedDevices = await this.api.finishUpload(finished);
		const updatedDevicesMap = {};
		updatedDevices.forEach(d => (updatedDevicesMap[d.id] = d));

		const { devices } = super.get();
		const newDevices = devices.map(d =>
			updatedDevicesMap[d.id] ? updatedDevicesMap[d.id] : d
		);

		super.set({
			devices: newDevices,
		});
	}
	public testSms(options: ISMSOptions): Promise<ITestResult> {
		return this.api.testSms(options);
	}

	public testEmail(options: IEmailOptions): Promise<ITestResult> {
		return this.api.testEmail(options);
	}
	public testFtp(options: IFtpOptions): Promise<ITestResult> {
		return this.api.testFtp(options);
	}

	public addDeviceListeners(devices) {
		getDeviceChannelResults(devices).forEach(({ device, channel }) => {
			if (device.serialNumber && !this.deviceLookup[device.serialNumber]) {
				this.deviceLookup[device.serialNumber] = {};
				if (this.aqlBroker) {
					this.aqlBroker.addDevice(device.serialNumber);
				}
			}

			if (device.serialNumber) {
				this.deviceLookup[device.serialNumber][channel.iconNumber] = channel;
			}
		});
	}

	public removeDeviceListener(device) {
		if (this.deviceLookup && this.deviceLookup[device.serialNumber]) {
			delete this.deviceLookup[device.serialNumber];
		}
		if (this.aqlBroker) {
			this.aqlBroker.removeDevice(device.serialNumber);
		}
	}

	private onDataReceived(serialNumber: string, data: DataBlock) {
		setTimeout(() => {
			this._onDataReceived(serialNumber, data);
		}, 25);
	}

	private _onDataReceived(serialNumber: string, data: DataBlock) {
		const pointsUF: IUpdatePoint[][] = data.items.map(item => {
			return item.data.map(dataTag => {
				const channel = this.deviceLookup[serialNumber][dataTag.iconNumber];
				let value: number | string = '';
				if (hasValue(dataTag.value) && channel && channel.digits >= 0) {
					value = parseFloat(dataTag.value.toFixed(channel.digits));
				} else if (hasValue(dataTag.value)) {
					value = dataTag.value;
				}

				return {
					channelId: channel ? channel.id : null,
					iconNumber: dataTag.iconNumber,
					timestamp: item.time.date,
					value: value,
				};
			});
		});
		// @ts-ignore
		const points = ([] as IUpdatePoint[]).concat(...pointsUF);
		points.sort((p1, p2) => dateCompare(p1, p2, 'timestamp'));

		const { widgetStates, selectedView } = super.get();
		for (const key of Object.keys(widgetStates)) {
			const state = widgetStates[key];
			if (!state) {
				continue;
			}

			if (
				state.widgetType === WidgetType.Graph &&
				selectedView.rangeType !== ViewRangeType.Custom
			) {
				this.dataEmitter.emitLive(key, points);
			} else if (
				state.widgetType === WidgetType.List &&
				selectedView.rangeType !== ViewRangeType.Custom
			) {
				this.dataEmitter.emitLive(key, points);
			} else if (state.widgetType === WidgetType.Table) {
				this.dataEmitter.emitLive(key, points);
			}
		}

		super.set({ widgetStates });
	}

	private onStatusReceived(serialNumber: string, data: LoggerStatus) {
		const { widgetStates, devices } = super.get();
		const { device, index } = this.findDeviceBySerialNumber(serialNumber);
		if (!device) {
			return;
		}

		device.updatedAt = new Date(); //data.AqlTime;
		(device.loggerId = data.loggerId),
			(device.loggerName = data.loggerName),
			(device.loggerVersion = data.loggerVersion),
			(device.model = data.model.toString()),
			(device.programName = data.programName),
			(device.logMemoryUsedKb = data.logMemoryUsedKb),
			(device.logMemorySizeKb = data.logMemorySizeKb),
			(device.programVersion = data.programVersion),
			(device.sessionStartTime = new Date(data._sessionStartTime * 1000)),
			(device.tickTiming = data._tickTiming),
			(device.timePresentation = data.timeZoneRule),
			(device.timezoneOffset = data.timeZoneOffset),
			(device.loggerState = data.stateFlag),
			(device.aqlTime = data.AqlTime),
			(device.externalVoltage = data.externalVoltage),
			(device.internalVoltage = data.internalVoltage),
			(device.backUpVoltage = data.backUpVoltage),
			(device.pingTimeMinutes = data.pingTimeMinutes),
			(device.sessionKeepAlive = data.sessionKeepAlive === 1 ? true : false),
			(device.connectionKeepAlive =
				data.connectionKeepAlive === 1 ? true : false),
			(device.memoryMode = data.sessionType),
			(device.temperature = data.temperature),
			(device.location = data.location);

		devices[index] = device;
		super.set({ devices });

		for (const key of Object.keys(widgetStates)) {
			const state = widgetStates[key];
			if (!state) {
				continue;
			}

			if (state.widgetType === WidgetType.Table) {
				this.dataEmitter.emitDevice(key, device);
			}
		}
	}

	private onSessionReceived(serialNumber: string, data: ConfigBlock) {
		const { devices } = super.get();
		const { device, index } = this.findDeviceBySerialNumber(serialNumber);
		if (!device) {
			return;
		}

		device.loggerId = data.logger.loggerId;
		device.loggerName = data.logger.loggerName;
		device.loggerVersion = data.logger.loggerVersion;
		device.model = data.logger.model.toString();
		device.programName = data.logger.programName;
		device.programVersion = data.logger.programVersion;
		device.sessionStartTime = new Date(data.logger._sessionStartTime * 1000);
		device.tickTiming = data.logger._tickTiming;
		device.timePresentation = data.logger.timeZoneRule;
		device.timezoneOffset = data.logger.timeZoneOffset;
		(device.sessionKeepAlive =
			data.logger.sessionKeepAlive === 1 ? true : false),
			(device.connectionKeepAlive =
				data.logger.connectionKeepAlive === 1 ? true : false),
			(device.memoryMode = data.logger.sessionType),
			(device.temperature = data.logger.temperature),
			(device.location = data.logger.location);
		devices[index] = device;
		super.set({ devices });
	}

	private async onTriggerReceived(serialNumber: string, data: AlertTag) {
		const { widgetStates, deviceAlertStatus } = super.get();

		const { device } = this.findDeviceBySerialNumber(serialNumber);
		if (!device) {
			return;
		}

		for (const key of Object.keys(widgetStates)) {
			const state = widgetStates[key];
			if (!state) {
				continue;
			}

			if (state.widgetType === WidgetType.Table) {
				this.dataEmitter.emitAlert(key, {
					id: device.id,
					lastAlertAwaitingAck: data.lastAlertAwaitingAck,
					lastAlert: data.lastAlert,
					lastAlertDate: data.updatedAt,
				});

				deviceAlertStatus[device.serialNumber] = {
					...deviceAlertStatus[device.serialNumber],
					lastAlertAwaitingAck: data.lastAlertAwaitingAck,
					lastAlert: data.lastAlert,
					lastAlertDate: data.updatedAt,
				};
			}
		}

		// Now get the latest alert info
		await this.getNonAcknowledgedAlertInstanceCount();
		await this.getDeviceAlertStatus([device.id]);
	}

	private onCommandReceived(serialNumber: string, data: CommandTag) {
		const { devices } = super.get();
		const { device, index } = this.findDeviceBySerialNumber(serialNumber);
		if (!device) {
			return;
		}

		device.command = {
			active: data.command.active,
			commandType: data.command.commandType,
			eventType: data.command.eventType,
			id: data.command.id,
			deviceId: data.command.deviceId,
			updatedAt: data.command.updatedAt,
			status: data.command.status,
		} as ICommand;

		devices[index] = device;
		super.set({ devices });
	}

	private findDeviceBySerialNumber(
		serialNumber: string
	): { device: IDevice | undefined; index: number } {
		const { devices } = super.get();
		const index = devices.findIndex(
			device => device.serialNumber === parseInt(serialNumber)
		);

		return { device: devices[index], index };
	}
}

export interface IUpdatePoint {
	channelId: number | null;
	iconNumber: number;
	timestamp: Date;
	value: any;
}
