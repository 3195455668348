<div class='{blockClass} radio-button-group vertical'>
    <form class='form'>
        {#if !isEditing}
        <h2 class='form-header'>Notification</h2>
        {/if}
        <div class='form-body medium-form'>
            <div class='form-field-container'>
                <h4>Notification Type</h4>
                <div class='image-radio-button-container'>
                    <div class='image-radio-button {selected(values.type, types.SMS)}' 
                        on:click='changeType(types.SMS)'
                        data-test='text-message'>
                        <i class='fas fa-comments'></i>
                        <label>Text Message</label>
                    </div>
                    <div class='image-radio-button {selected(values.type, types.Email)}' 
                        on:click='changeType(types.Email)'
                        data-test='email-message'>
                        <i class='fas fa-envelope'></i>
                        <label>Email</label>
                    </div>
                </div>
            </div>
            {#if values.type === types.SMS }
            <div class='form-field-container'>
                <h4>Text Message Options</h4>
                <ContactGroup className='form-field' 
                    label='To:' 
                    bind:contacts='values.recipients'
                    filterPhoneNumber={true}
                    test_tag='recipients'
                    statusClass={fields.recipients.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.recipients.error ? fields.recipients.error : ''} />
                <TextInput isTextArea={true} 
                    className='form-field' 
                    placeholder='The message body goes here...' 
                    label='Message' 
                    test_tag='message-body'
                    statusClass={fields.message.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.message.error ? fields.message.error : ''}
                    bind:value='values.message' />

                <div class='test-sending'>
                    <Button loading={isTesting}
                        disabled={canTestSms || !$fullAccess}
                        on:click='testSms(event)' 
                        test_tag='email-test'
                        color='warning'>Test SMS</Button>

                    <Notification test_tag='email-test-notification' 
                        open={showTestNotification}
                        className='test-notification' 
                        color={testColor}>{testMessage}</Notification>
                </div>

                <div class='form-field'>
                    <label class='text-input-label'>Include in message</label>
                    <div class='checkbox-group'>
                        <div class='row'>
                            <Checkbox checked={values.includeUnitName} 
                                test_tag='include-unit-name'
                                on:change='onCheck("includeUnitName", event.target.checked)'
                                >Unit Name</Checkbox>
                            <Checkbox checked={values.includeChannelName} 
                                test_tag='include-channel-name'
                                on:change='onCheck("includeChannelName", event.target.checked)'
                                >Channel Name</Checkbox>
                            <Checkbox checked={values.includeUnitDateTime} 
                                test_tag='include-unit-datetime'
                                on:change='onCheck("includeUnitDateTime", event.target.checked)'
                                >Unit Date and Time</Checkbox>
                        </div>
                        <div class='row'>
                            <Checkbox checked={values.includeSerialNumber} 
                                test_tag='include-serial-number'
                                on:change='onCheck("includeSerialNumber", event.target.checked)'
                                >Serial Number</Checkbox>
                            <Checkbox checked={values.includeLastDataValue} 
                                test_tag='include-last-data-value'
                                on:change='onCheck("includeLastDataValue", event.target.checked)'
                                >Last Data Value</Checkbox>
                            <Checkbox checked={values.includeUnitLocation} 
                                test_tag='include-unit-location'
                                on:change='onCheck("includeUnitLocation", event.target.checked)'
                                >Unit Location</Checkbox>
                        </div>
                    </div>
                </div>
            </div>
            {:else}
            <div class='form-field-container'>
                <h4>Email Options</h4>
                <ContactGroup className='form-field' 
                    label='To:' 
                    test_tag='recipients'
                    bind:contacts='values.recipients'
                    statusClass={fields.recipients.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.recipients.error ? fields.recipients.error : ''} />
                <ContactGroup className='form-field' 
                    label='Cc:' 
                    test_tag='cc'
                    bind:contacts='values.cc'
                    statusClass={fields.cc.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.cc.error ? fields.cc.error : ''} />
                <TextInput className='form-field' 
                    placeholder='e.g. Device Alert Triggered' 
                    label='Subject' 
                    test_tag='subject'
                    statusClass={fields.subject.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.subject.error ? fields.subject.error : ''}
                    bind:value='values.subject' />
                <TextInput isTextArea={true} 
                    className='form-field' 
                    placeholder='The message body goes here...' 
                    label='Message' 
                    test_tag='message-body'
                    statusClass={fields.message.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.message.error ? fields.message.error : ''}
                    bind:value='values.message' />

                <div class='test-sending'>
                    <Button loading={isTesting}
                        disabled={cantTestEmail || !$fullAccess}
                        on:click='testEmail(event)' 
                        test_tag='email-test'
                        color='warning'>Test Email</Button>

                    <Notification test_tag='email-test-notification' 
                        open={showTestNotification}
                        className='test-notification' 
                        color={testColor}>{testMessage}</Notification>
                </div>

                <div class='form-field'>
                    <label class='text-input-label'>Include in message</label>
                    <div class='checkbox-group'>
                        <div class='row'>
                            <Checkbox bind:checked='values.includeUnitName' 
                                test_tag='include-unit-name'
                                on:change='onCheck("includeUnitName", event.target.checked)'>Unit Name</Checkbox>
                            <Checkbox bind:checked='values.includeChannelName' 
                                test_tag='include-channel-name'
                                on:change='onCheck("includeChannelName", event.target.checked)'>Channel Name</Checkbox>
                            <Checkbox bind:checked='values.includeUnitDateTime' 
                                test_tag='include-unit-datetime'
                                on:change='onCheck("includeUnitDateTime", event.target.checked)'>Unit Date and Time</Checkbox>
                        </div>
                        <div class='row'>
                            <Checkbox bind:checked='values.includeSerialNumber' 
                                test_tag='include-serial-number'
                                on:change='onCheck("includeSerialNumber", event.target.checked)'>Serial Number</Checkbox>
                            <Checkbox bind:checked='values.includeLastDataValue' 
                                test_tag='include-last-data-value'
                                on:change='onCheck("includeLastDataValue", event.target.checked)'>Last Data Value</Checkbox>
                            <Checkbox bind:checked='values.includeUnitLocation' 
                                test_tag='include-unit-location'
                                on:change='onCheck("includeUnitLocation", event.target.checked)'>Unit Location</Checkbox>
                        </div>
                    </div>
                </div>
            </div>
            {/if}
            <div class='form-field-container'>
                <h4>Acknowledgement Options</h4>
                <div class='acks'>
                    {#each values.acks as ack, index}
                        <Ack order={index + 1} 
                             bind:form="ack"
                             :isEditing
                             on:remove='removeAck(event)'
                             type={values.type} />
                    {/each}
                </div>
                <Button  
                    test_tag='add-acknowledgement'
                    on:click='addAck(event)'
                    className='margin-t-5' 
                    iconClass='fas fa-plus' 
                    color='normal'>Add Acknowledgement</Button>
            </div>
        </div>
    </form>
</div>

<script>
    import { NotificationType } from '@shanewwarren/aqlcloud-shared-types';
    import ContactGroup from '../../Contacts/ContactGroup';
    import Ack from './Ack.html';
    import { Checkbox, Button, TextInput, Notification } from 'Shared';
    import { fromModel as fromAckModel } from 'Services/forms/trigger/ack';
    import { validate } from 'Services/forms/validators';
    import { selected } from 'Services/helpers';

    export default {
    	helpers: {
    		selected,
    	},
    	components: {
    		Ack,
    		Checkbox,
    		Button,
    		TextInput,
            ContactGroup,
            Notification
    	},
    	data() {
    		return {
    			blockClass: 'notifications',
    			types: NotificationType,
                isEditing: false,
                isTesting: false,
                testColor: 'success',
                testMessage: ''
    		};
    	},
    	onupdate({ changed, current, previous }) {
    		validate(this, changed, current, previous, true, current.isEditing);
    	},
    	computed: {
    		form: ({ notificationForm }) => notificationForm,
    		values: ({ form }) => form.values,
            fields: ({ form }) => form.fields,
            cantTestEmail: ({ values }) => {
                return !(values.recipients && 
                    values.recipients.length > 0 &&
                    values.subject &&
                    values.subject !== '');
            },
            canTestSms: ({ values }) => {
                return !(values.recipients && 
                    values.recipients.length > 0 &&
                    values.message &&
                    values.message !== '');
            },
    	},
    	methods: {
    		onCheck(key, checked) {
    			const { values } = this.get();
    			values[key] = checked;
    			this.set({ values });
    		},
    		changeType(type) {
    			const { values } = this.get();
    			values.type = type;
    			this.set({ values });
    		},
    		addAck(evt) {
    			evt.preventDefault();
    			const { form, isEditing } = this.get();
    			const ack = fromAckModel();
    			form.values.acks.push(ack);
    			this.set({ form });
    		},
    		removeAck(index) {
    			const { form } = this.get();
    			form.values.acks.splice(index, 1);
    			this.set({ form });
            },
            testSms(e) {
                e.preventDefault();
                const { values } = this.get();
                const smsOptions = {
                    recipients: values.recipients,
                    cc: values.cc ? values.cc : [],
                    subject: values.subject,
                    message: values.message
                };

                this.set({ isTesting: true });
                this.store.testSms(smsOptions)
                    .then((result) => {
                        if (result.success) {
                            this.set({ 
                                isTesting: false,
                                showTestNotification: true,
                                testColor: 'success',
                                testMessage: 'Sms successfully sent' 
                            });
                        } else {
                            this.set({ 
                                isTesting: false,
                                showTestNotification: true,
                                testColor: 'floating-error',
                                testMessage: result.errorMessage
                            });
                        }

                        setTimeout(() => {
                            this.set({
                                showTestNotification: false
                            })
                        }, 5000);
                    })
            },
            testEmail(e) {
                e.preventDefault();
                
                const { values } = this.get();
                const emailOptions = {
                    to: values.recipients,
                    subject: values.subject,
                    message: values.message,
                    reports: false,
                    cc: values.cc ? values.cc : []
                };

                this.set({ isTesting: true });
                this.store.testEmail(emailOptions)
                    .then((result) => {
                        if (result.success) {
                            this.set({ 
                                isTesting: false,
                                showTestNotification: true,
                                testColor: 'success',
                                testMessage: 'Email successfully sent' 
                            });
                        } else {
                            this.set({ 
                                isTesting: false,
                                showTestNotification: true,
                                testColor: 'floating-error',
                                testMessage: result.errorMessage
                            });
                        }

                        setTimeout(() => {
                            this.set({
                                showTestNotification: false
                            })
                        }, 5000);
                    })
                    .catch((err) => {
                        this.set({ isTesting: false });
                    });
            }
    	},
    };
</script>