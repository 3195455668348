import {
	ReportDataFormat,
	ReportScheduleType,
	ReportType,
	IReport,
	IUpdateReport,
	ReportDateFormat,
} from '@shanewwarren/aqlcloud-shared-types';
import {
	Validator,
	ValidatorType,
	IValidatorResult,
	IsValid,
	NotValid,
	_DateValidator,
} from '../validators';
import { FormField, Field } from '../field';

export interface ReportDetailsForm {
	values: {
		scheduleType: ReportScheduleType | undefined;
		max: number | undefined;
		timespan: number | undefined;
		start: Date | undefined;
		formatType: ReportDataFormat | undefined;
		reportType: ReportType | undefined;
		dateFormat: ReportDateFormat | undefined;
	};
	fields: {
		scheduleType: Field<ReportScheduleType | undefined>;
		max: Field<number | undefined>;
		timespan: Field<number | undefined>;
		start: Field<Date | undefined>;
		formatType: Field<ReportDataFormat | undefined>;
		reportType: Field<ReportType | undefined>;
		dateFormat: Field<ReportDateFormat> | undefined;
	};
}

class _ReportScheduleTypeValidator extends Validator<ReportScheduleType> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.scheduleType === ReportScheduleType.Download) {
			return IsValid;
		}

		if (values.scheduleType === ReportScheduleType.Finite) {
			if (!values.max || values.max.length === 0) {
				return NotValid('Must be greater than zero.');
			}
		}

		if (!values.timespan) {
			return NotValid('Must specify a valid timespan.');
		}

		if (!values.start) {
			return NotValid('Must specify a report start date.');
		}

		if (values.dateFormat === undefined) {
			return NotValid('Must specify a date format');
		}

		return IsValid;
	}
}

const ReportScheduleTypeValidator = () => new _ReportScheduleTypeValidator();

class _DiscreteMaxValidator extends Validator<number> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.scheduleType === ReportScheduleType.Download) {
			return IsValid;
		}
		if (values.scheduleType === ReportScheduleType.Finite) {
			if (!values.max) {
				return NotValid('Must specify a maximum report count.');
			}

			if (values.max <= 0) {
				return NotValid('Maximum report count must be greater than zero.');
			}
		}
		return IsValid;
	}
}

const DiscreteMaxValidator = () => new _DiscreteMaxValidator();

class ReportDetailsDateValidator extends _DateValidator {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		if (values.scheduleType === ReportScheduleType.Download) {
			return IsValid;
		}
		return this.validate(values.start, prevValues.start);
	}
}

export const fromModel = (model?: IReport): ReportDetailsForm => {
	let scheduleType: ReportScheduleType | undefined = ReportScheduleType.Finite;
	let max: number | undefined = 0;
	let timespan: number | undefined = undefined;
	let start: Date | undefined = undefined;
	let formatType: ReportDataFormat | undefined = ReportDataFormat.CSV;
	let reportType: ReportType | undefined = undefined;
	let dateFormat: ReportDateFormat | undefined = ReportDateFormat.PC;

	if (model) {
		scheduleType = model.scheduleType;
		if (scheduleType === ReportScheduleType.Download) {
			max = 1;
			timespan = 0;
			start = new Date();
		} else {
			max = model.max;
			timespan = model.timespan;
			start = model.start;
		}
		formatType = model.formatType;
		dateFormat = model.dateFormat;
	}

	return {
		values: {
			scheduleType,
			max,
			timespan,
			start,
			formatType,
			reportType,
			dateFormat,
		},
		fields: {
			scheduleType: FormField({
				key: 'scheduleType',
				validators: [ReportScheduleTypeValidator()],
			}),
			max: FormField({
				key: 'max',
				validators: [DiscreteMaxValidator()],
			}),
			timespan: FormField({
				key: 'timespan',
				validators: [],
			}),
			start: FormField({
				key: 'start',
				validators: [new ReportDetailsDateValidator()],
			}),
			formatType: FormField({
				key: 'formatType',
				validators: [],
			}),
			reportType: FormField({
				key: 'reportType',
				validators: [],
			}),
			dateFormat: FormField({
				key: 'reportDate',
				validators: [],
			}),
		},
	};
};

export function toModel(model: IUpdateReport, form: ReportDetailsForm) {
	model.scheduleType = form.values.scheduleType;
	model.max =
		form.values.scheduleType !== ReportScheduleType.Download &&
		form.values.scheduleType === ReportScheduleType.Finite
			? form.values.max
			: undefined;
	model.timespan =
		form.values.scheduleType !== ReportScheduleType.Download
			? form.values.timespan
			: undefined;
	model.start =
		form.values.scheduleType !== ReportScheduleType.Download
			? form.values.start
			: new Date();

	model.formatType = form.values.formatType;
	model.dateFormat = form.values.dateFormat;
	return model;
}
