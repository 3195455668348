<div class="portal" ref:portal>
	<slot></slot>
</div>
<script>
    export default {
        oncreate() {
            document.body.appendChild(this.refs.portal)
        }
    }
</script>
