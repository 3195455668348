import { TriggerOperators, TriggerWindowRangeType, Constants } from '@shanewwarren/aqlcloud-shared-types';

export const OPERATORS = [{
    key: ">",
    value: TriggerOperators.GreaterThan
}, {
    key: "<",
    value: TriggerOperators.LessThan
}, {
    key: ">=",
    value: TriggerOperators.GreaterThanEqualTo
}, {
    key: "<=",
    value: TriggerOperators.LessThanEqualTo
}, {
    key: "=",
    value: TriggerOperators.EqualTo
}, {
    key: "!=",
    value: TriggerOperators.NotEqualTo
}];

export const RANGES = [{
    key: "inside",
    value: TriggerWindowRangeType.Inside
},
{
    key: "outside",
    value: TriggerWindowRangeType.Outside
}];

export const FTP_MODES = [{
    key: 'Active',
    value: 'active'
}, {
    key: 'Passive',
    value: 'passive'
}];

export const SERIES_TYPE = [{
    key: 'All data in series',
    value: Constants.SeriesSnapshotType.All
}, {
    key: 'Last',
    value: Constants.SeriesSnapshotType.LastTimespan
}, {
    key: 'Custom',
    value: Constants.SeriesSnapshotType.Bounded
}]