<div class="ack" transition:fade='{duration: 200}' >
    <div class='ack-header'>
        <Label color='dark'>ACK #{order}</Label>
        <div on:click='fire("remove", order - 1)' 
					data-test={`ack-remove-${order}`}
					class='remove-link margin-l-3'>Remove</div>
    </div>
    <div class='ack-body'>
        <div class='minutes-row flex-center margin-b-3 {fields.minutes.valid ? "" : "error"}'>
        <div class='inline'>If not acknowleded within</div>
        <TextInput isNumber={true} 
					test_tag={`ack-minutes-${order}`}
					statusClass={fields.minutes.valid ? '' : 'error'}
					statusMessage={fields.minutes.error ? fields.minutes.error : ''}
					className='code-field' 
					containerClassName='minutes-field'
					placeholder='n' 
					bind:value='values.minutes' />
        <div class='inline'>minutes, send {ackType} to:</div>
        </div>
        <ContactGroup bind:contacts="values.recipients"
					test_tag={`ack-recipients-${order}`}
					statusClass={fields.recipients.valid ? '' : 'error error-inline-vertical'}
					statusMessage={fields.recipients.error ? fields.recipients.error : ''} />
        <div class='append-failure'>
            <Checkbox checked={values.appendAckFailureMessage} 
				test_tag={`ack-append-failure-${order}`}
				on:change='appendFailureChange(event)'>Append Acknowledgement failure(s) notice to message. </Checkbox>
        </div>
    </div>
</div>

<script>
  import { NotificationType } from '@shanewwarren/aqlcloud-shared-types';
  import { Checkbox, Label, TextInput } from 'Shared';
  import ContactGroup from '../../Contacts/ContactGroup';
  import { fromModel } from 'Services/forms/trigger/ack';
  import { validate } from 'Services/forms/validators';
	import { fade } from 'svelte-transitions';

  const form = fromModel();
  export default {
  	components: {
  		Checkbox,
  		ContactGroup,
  		Label,
  		TextInput,
		},
		transitions: { fade },
  	computed: {
  		ackType: ({ type }) => {
  			return type === NotificationType.SMS ? 'text message' : 'email';
  		},
  		values: ({ form }) => form.values,
  		fields: ({ form }) => form.fields,
  	},
  	data() {
  		return {
  			minutes: 0,
  			contacts: [],
  			appendFailure: false,
  			order: 1,
  			type: NotificationType.SMS,
			form: form,
			isEditing: false
  		};
  	},
  	onupdate({ changed, current, previous }) {
  		validate(this, changed, current, previous, true, current.isEditing);
  	},
  	methods: {
  		appendFailureChange(evt) {
  			const { form } = this.get();
  			form.values.appendAckFailureMessage = evt.target.checked;
  			this.set({ form });
  		},
  	},
  };
</script>