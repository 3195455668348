import {
	Constants,
	IReport,
	IUpdateReport,
	INewReportSeriesSnapshot,
} from '@shanewwarren/aqlcloud-shared-types';
import {
	Validator,
	ValidatorType,
	IValidatorResult,
	IsValid,
	NotValid,
} from '../validators';
import { Form, FormField, Field } from '../field';

export interface ChannelSelectForm extends Form {
	values: {
		snapshots: INewReportSeriesSnapshot[];
		groupIds: string[];
	};
	fields: {
		snapshots: Field<INewReportSeriesSnapshot[]>;
		groupIds: Field<string[]>;
	};
}

class _ReportSnapshotValidator extends Validator<INewReportSeriesSnapshot[]> {
	public get type(): ValidatorType {
		return ValidatorType.Fields;
	}

	public validateFields(values: any, prevValues: any): IValidatorResult {
		const snapshots = values.snapshots;
		if (!snapshots || snapshots.length === 0) {
			return NotValid('Must choose one or more devices');
		}

		for (const value of snapshots) {
			if (!value.seriesId) {
				return NotValid('Must choose a series');
			}

			if (!value.channelIds || value.channelIds.length === 0) {
				return NotValid('Must select one or more channels');
			}

			if (value.type === Constants.SeriesSnapshotType.Bounded) {
				if (!value.start) {
					return NotValid('Must choose a valid start time.');
				}

				if (!value.end) {
					return NotValid('Must choose a valid end time.');
				}

				if (value.start > value.end) {
					return NotValid('Start time must come before start time');
				}
			} else if (value.type === Constants.SeriesSnapshotType.LastTimespan) {
				if (!value.timespan || value.timespan <= 0) {
					return NotValid('Must specify a valid timespan');
				}
			}
		}
		return IsValid;
	}
}
const ReportSnapshotValidator = () => new _ReportSnapshotValidator();

export class _ItemsValidator extends Validator<string[]> {
	public validate(value: string[], prevValue: string[]): IValidatorResult {
		if (value && value.length > 0) {
			return IsValid;
		}
		return NotValid('Must specify one or more groups.');
	}
}

export const ItemsValidator = () => new _ItemsValidator();

export const fromModel = (model?: IReport): ChannelSelectForm => {
	let snapshots: INewReportSeriesSnapshot[] = [];
	let groupIds: string[] = [];

	if (model) {
		model.reportSeriesSnapshots.forEach(snapshot => {
			const newSnapshot: INewReportSeriesSnapshot = {
				seriesId: snapshot.seriesId,
				deviceId: snapshot.series ? snapshot.series.deviceId : '',
				type: snapshot.type,
				channelIds: snapshot.channels ? snapshot.channels.map(c => c.id) : [],
			};

			if (snapshot.type === Constants.SeriesSnapshotType.Bounded) {
				newSnapshot.start = snapshot.start;
				newSnapshot.end = snapshot.end;
			} else if (snapshot.type === Constants.SeriesSnapshotType.LastTimespan) {
				newSnapshot.timespan = snapshot.timespan;
			}

			snapshots.push(newSnapshot);
		});

		groupIds = model.groups.map(g => g.id);
	}

	return {
		valid: false,
		type: 'form',
		values: {
			snapshots,
			groupIds,
		},
		fields: {
			snapshots: FormField({
				key: 'snapshots',
				validators: [ReportSnapshotValidator()],
			}),
			groupIds: FormField({
				key: 'groupIds',
				validators: [ItemsValidator()],
			}),
		},
	};
};

export function toModel(model: IUpdateReport, form: ChannelSelectForm) {
	model.reportSeriesSnapshots = form.values.snapshots;
	model.groupIds = form.values.groupIds;
	return model;
}
