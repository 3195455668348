import { FTPMode, IContact, IReport, IUpdateReport, ReportType as ReportTypeConstant } from '@shanewwarren/aqlcloud-shared-types';
import { Field, FormField } from '../field';
import { IValidatorResult, IsValid, NotValid, ValidationErrors, Validator, ValidatorType } from '../validators';

export interface DeviceTriggerForm {
    values: {
        type: ReportTypeConstant;
        recipients: IContact[] | undefined;
        cc: IContact[] | undefined;
        subject: string | undefined;
        message: string | undefined;
        ftpServer: string | undefined;
        ftpPort: number | undefined;
        ftpActiveMode: FTPMode | undefined;
        ftpUserId: string | undefined;
        ftpPassword: string | undefined;
        ftpDirectory: string | undefined;
        ftpFilename: string | undefined;
        ftpFilenameAppendDate: boolean | undefined;
        includeUnitDateTime: boolean | undefined;
        includeGroupName: boolean | undefined;
        includeSerialNumber: boolean | undefined;
        includeUnitName: boolean | undefined;
        includeChannelName: boolean | undefined;
        includeUnitsName: boolean | undefined;
    },
    fields: {
        type: Field<ReportTypeConstant>;
        recipients: Field<IContact[] | undefined>;
        cc: Field<IContact[] | undefined>;
        subject: Field<string | undefined>;
        message: Field<string | undefined>;
        ftpServer: Field<string | undefined>;
        ftpPort: Field<number | undefined>;
        ftpActiveMode: Field<FTPMode | undefined>;
        ftpUserId: Field<string | undefined>;
        ftpPassword: Field<string | undefined>;
        ftpDirectory: Field<string | undefined>;
        ftpFilename: Field<string | undefined>;
        ftpFilenameAppendDate: Field<boolean | undefined>;
        includeUnitDateTime: Field<boolean | undefined>;
        includeGroupName: Field<boolean | undefined>;
        includeSerialNumber: Field<boolean | undefined>;
        includeUnitName: Field<boolean | undefined>;
        includeChannelName: Field<boolean | undefined>;
        includeUnitsName: Field<boolean | undefined>;
    }
}

class _EmailSubjectValidator extends Validator<string> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.Email && (!values.subject || values.subject.length === 0)) {
            return NotValid('Must specify an email subject.');

        }
        return IsValid;
    }
}
const EmailSubjectValidator = () => new _EmailSubjectValidator();

class _FTPServerValidator extends Validator<string> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.FTP && (!values.ftpServer || values.ftpServer.length === 0)) {
            return NotValid('Must specify FTP server.');
        }
        return IsValid;
    }
}
const FTPServerValidator = () => new _FTPServerValidator();

class _FTPPortValidator extends Validator<number> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.FTP) {
            if (!values.ftpPort) {
                return NotValid("Must specify FTP port.");
            }

            if (values.ftpPort <= 0) {
                return NotValid(ValidationErrors.PositiveInteger)
            }
        }
        return IsValid;
    }
}
const FTPPortValidator = () => new _FTPPortValidator();

class _FTPUserIdValidator extends Validator<string> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.FTP && (!values.ftpUserId || values.ftpUserId.length === 0)) {
            return NotValid("Must specify FTP user id.");
        }
        return IsValid;
    }
}
const FTPUserIdValidator = () => new _FTPUserIdValidator();

// class _FTPUserPasswordValidator extends Validator<string> {
//     public get type(): ValidatorType {
//         return ValidatorType.Fields;
//     }

//     public validateFields(values: any, prevValues: any): IValidatorResult {
//         if (values.type === ReportTypeConstant.FTP && (!values.ftpPassword || values.ftpPassword.length === 0)) {
//             return NotValid("Must specify FTP user password.");
//         }
//         return IsValid;
//     }
// }
// const FTPUserPasswordValidator = () => new _FTPUserPasswordValidator();


// class _FTPFilenameValidator extends Validator<string> {
//     public get type(): ValidatorType {
//         return ValidatorType.Fields;
//     }

//     public validateFields(values: any, prevValues: any): IValidatorResult {
//         if (values.type === ReportTypeConstant.FTP && (!values.ftpFilename || values.ftpFilename.length === 0)) {
//             return NotValid('Must specify FTP filename');
//         }
//         return IsValid;
//     }
// }
// const FTPFilenameValidator = () => new _FTPFilenameValidator();

// class _FTPDirectoryValidator extends Validator<string> {
//     public get type(): ValidatorType {
//         return ValidatorType.Fields;
//     }

//     public validateFields(values: any, prevValues: any): IValidatorResult {
//         if (values.type === ReportTypeConstant.FTP && (!values.ftpDirectory || values.ftpDirectory.length === 0)) {
//             return NotValid('Must specify FTP filename');
//         }
//         return IsValid;
//     }
// }
// const FTPDirectoryValidator = () => new _FTPDirectoryValidator();

export class _NoContactsValidator extends Validator<any[]> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.Download || values.type === ReportTypeConstant.FTP) {
            return IsValid;
        }

        if (values.recipients.length === 0) {
            return NotValid(ValidationErrors.NoContactsSelected);
        }

        return IsValid;
    }
}

export const NoContactsValidator = () => new _NoContactsValidator();


class _ReportTypeValidator extends Validator<ReportTypeConstant> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values.type === ReportTypeConstant.Email) {
            if (!values.recipients || values.recipients.length === 0) {
                return NotValid('Must specify at least one recipient.');
            }

            if (!values.subject || values.subject.length === 0) {
                return NotValid('Must specify an email subject.');
            }
        }

        if (values.type === ReportTypeConstant.FTP) {
            if (!values.ftpServer || values.ftpServer.length === 0) {
                return NotValid('Must specify FTP server.');
            }

            if (!values.ftpPort) {
                return NotValid("Must specify FTP port.");
            }

            if (!values.ftpUserId) {
                return NotValid("Must specify FTP user id.");
            }

            // if (!values.ftpPassword) {
            //     return NotValid("Must specify FTP user password.");
            // }

            // if (!values.ftpFilename) {
            //     return NotValid('Must specify FTP filename');
            // }

            // if (!values.ftpDirectory) {
            //     return NotValid('Must specify FTP directory');
            // }
        }


        return IsValid;
    }
}

const ReportTypeValidator = () => new _ReportTypeValidator();

// Need to do a notSet flag.

export const fromModel = (model?: IReport): DeviceTriggerForm => {

    let type = ReportTypeConstant.Email;
    let recipients: IContact[] | undefined = [];
    let cc: IContact[] | undefined = [];
    let subject: string | undefined = '';
    let message: string | undefined = '';
    let ftpServer: string | undefined = '';
    let ftpPort: number | undefined = 20;
    let ftpActiveMode: FTPMode | undefined = FTPMode.Active;
    let ftpUserId: string | undefined = '';
    let ftpPassword: string | undefined = '';
    let ftpDirectory: string | undefined = '';
    let ftpFilename: string | undefined = '';
    let ftpFilenameAppendDate: boolean | undefined = false;
    let includeUnitDateTime: boolean | undefined = true;
    let includeGroupName: boolean | undefined = true;
    let includeSerialNumber: boolean | undefined = true;
    let includeUnitName: boolean | undefined = true;
    let includeChannelName: boolean | undefined = true;
    let includeUnitsName: boolean | undefined = true;

    if (model) {
        recipients = model.recipients;
        cc = model.cc;
        subject = model.subject;
        message = model.message;
        ftpServer = model.ftpServer;
        ftpPort = model.ftpPort;
        ftpActiveMode = model.ftpActiveMode ? FTPMode.Active : FTPMode.Passive;
        ftpUserId = model.ftpUserId;
        ftpPassword = model.ftpPassword;
        ftpDirectory = model.ftpDirectory;
        ftpFilename = model.ftpFilename;
        ftpFilenameAppendDate = model.ftpFilenameAppendDate;
        includeUnitDateTime = model.includeUnitDateTime;
        includeGroupName = model.includeGroupName;
        includeSerialNumber = model.includeSerialNumber;
        includeUnitName = model.includeUnitName;
        includeChannelName = model.includeChannelName;
        includeUnitsName = model.includeUnitsName;
        type = model.type;
    }

    return {
        values: {
            type,
            recipients,
            cc,
            subject,
            message,
            ftpServer,
            ftpPort,
            ftpActiveMode,
            ftpUserId,
            ftpPassword,
            ftpDirectory,
            ftpFilename,
            ftpFilenameAppendDate,
            includeUnitDateTime,
            includeGroupName,
            includeSerialNumber,
            includeUnitName,
            includeChannelName,
            includeUnitsName,
        },
        fields: {
            type: FormField({
                key: 'type',
                validators: [ReportTypeValidator()]
            }),
            recipients: FormField({
                key: 'recipients',
                validators: [NoContactsValidator()]
            }),
            cc: FormField({
                key: 'cc',
                validators: []
            }),
            subject: FormField({
                key: 'subject',
                validators: [EmailSubjectValidator()]
            }),
            message: FormField({
                key: 'message',
                validators: []
            }),
            ftpServer: FormField({
                key: 'ftpServer',
                validators: [FTPServerValidator()]
            }),
            ftpPort: FormField({
                key: 'ftpPort',
                validators: [FTPPortValidator()]
            }),
            ftpActiveMode: FormField({
                key: 'ftpActiveMode',
                validators: []
            }),
            ftpUserId: FormField({
                key: 'ftpUserId',
                validators: [FTPUserIdValidator()]
            }),
            ftpPassword: FormField({
                key: 'ftpPassword',
                validators: []
            }),
            ftpDirectory: FormField({
                key: 'ftpDirectory',
                validators: []
            }),
            ftpFilename: FormField({
                key: 'ftpFilename',
                validators: []
            }),
            ftpFilenameAppendDate: FormField({
                key: 'ftpFilenameAppendDate',
                validators: []
            }),
            includeUnitDateTime: FormField({
                key: 'includeUnitDateTime',
                validators: []
            }),
            includeGroupName: FormField({
                key: 'includeGroupName',
                validators: []
            }),
            includeSerialNumber: FormField({
                key: 'includeSerialNumber',
                validators: []
            }),
            includeUnitName: FormField({
                key: 'includeUnitName',
                validators: []
            }),
            includeChannelName: FormField({
                key: 'includeChannelName',
                validators: []
            }),
            includeUnitsName: FormField({
                key: 'includeUnitsName',
                validators: []
            }),
        }
    };
}

export function toModel(model: IUpdateReport, form: DeviceTriggerForm) {
    model.type = form.values.type;
    model.recipients = form.values.type === ReportTypeConstant.Email && form.values.recipients ? form.values.recipients.map(contact => contact.id) : [];
    model.cc = form.values.type === ReportTypeConstant.Email && form.values.cc ? form.values.cc.map(contact => contact.id) : [];
    model.subject = form.values.type === ReportTypeConstant.Email ? form.values.subject : undefined;
    model.message = form.values.type === ReportTypeConstant.Email ? form.values.message : undefined;
    model.ftpServer = form.values.type === ReportTypeConstant.FTP ? form.values.ftpServer : undefined;
    model.ftpPort = form.values.type === ReportTypeConstant.FTP ? form.values.ftpPort : undefined;
    model.ftpActiveMode = form.values.type === ReportTypeConstant.FTP ? form.values.ftpActiveMode === FTPMode.Active : undefined;
    model.ftpUserId = form.values.type === ReportTypeConstant.FTP ? form.values.ftpUserId : undefined;
    model.ftpPassword = form.values.type === ReportTypeConstant.FTP ? form.values.ftpPassword : undefined;
    model.ftpDirectory = form.values.type === ReportTypeConstant.FTP ? form.values.ftpDirectory : undefined;
    model.ftpFilename = form.values.type === ReportTypeConstant.FTP ? form.values.ftpFilename : undefined;
    model.ftpFilenameAppendDate = form.values.type === ReportTypeConstant.FTP ? form.values.ftpFilenameAppendDate : undefined;
    model.includeUnitDateTime = form.values.includeUnitDateTime;
    model.includeGroupName = form.values.includeGroupName;
    model.includeSerialNumber = form.values.includeSerialNumber;
    model.includeUnitName = form.values.includeUnitName;
    model.includeChannelName = form.values.includeChannelName;
    model.includeUnitsName = form.values.includeUnitsName;
    return model;
}
