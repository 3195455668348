export enum Colors {
  success = "success",
  primary = "primary",
  error = "error",
  dark = "dark",
  warning = "warning",
  disabled = "disabled",
  grey = "grey",
  normal = "normal",
  red = "red"
}

export function getColorClass(color: string): string {
  return color.toLowerCase();
}

export function getClasses(...classes: string[]): string {
  return classes.join(" ").trim();
}

export function getColorAndClasses(
  color: Colors,
  ...classes: string[]
): string {
  const className = [getColorClass(color), ...classes].join(" ").trim();
  return className;
}
