<div class='alert-details radio-button-group vertical'>
    <form class='form'>
        {#if !isEditing}
        <h2 class='form-header'>Alert Name</h2>
        {/if}
        <div class='form-body medium-form'>
            <div class='form-field-container'>
                <h4>Give a name to your alert:</h4>
                <TextInput 
                    className="form-field" 
                    placeholder='e.g. Low Voltage Alert'
                    bind:value='values.name'
                    test_tag='alert-name'
                    statusClass={fields.name.valid ? '' : 'error error-inline-vertical'}
                    statusMessage={fields.name.error ? fields.name.error : ''} />
            </div>      
        </div>
    </form>
</div>

<script>
    import { TextInput } from 'Shared';
    import { validate } from 'Services/forms/validators';

    export default {
    	components: {
    		TextInput,
    	},
    	data() {
    		return {
    			isEditing: false,
    		};
    	},
    	onupdate({ changed, current, previous }) {
    		validate(this, changed, current, previous, true, current.isEditing);
    	},
    	computed: {
    		form: ({ detailsForm }) => detailsForm,
    		values: ({ form }) => form.values,
    		fields: ({ form }) => form.fields,
    	},
    };
</script>