<tr class='no-results'>
    {#if !hideGutter}
    <td class='gutter'></td>
    {/if}

    <td colspan="{columnSpan}">
        <div>{message}</div>
    </td>
    
    {#if !hideGutter}
    <td class='gutter'></td>
    {/if}
</tr>

<script>
    export default {
    	data() {
    		return {
    			message: 'No results were found.',
    			columnSpan: 99,
    			hideGutter: false,
    		};
    	},
    };
</script>