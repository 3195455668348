<div class={classes(blockClass, 'table')}>
    <div class={element(blockClass, 'table-legend')}>
        <div class={element(blockClass, 'filter')}>
            <TextInput bind:value="filter" 
                       containerClassName={element(blockClass, 'search-filter')} 
                       iconClass='fas fa-search' 
                       placeholder='Search' />
        </div>
        {#if selectedCount > 0}
            <div class={element(blockClass, 'selected-panel')}>
                {#if showDeleteButton && $fullAccess}
                    <Button disabled={deleteButtonDisabled} 
                        iconClass="fas fa-trash"  
                        color='normal' 
                        test_tag='delete-device'
                        on:click="showDeleteConfirmation(event)">Delete Device</Button>    
                {/if}
                    <Button color='normal' 
                    test_tag='select-all-button'
                    on:click="selectAll(false)">Clear All</Button>    
            </div>
        {:elseif showCreateButton && $fullAccess}
            <Button iconClass="fas fa-plus" 
                    className={element(blockClass, 'add-btn')} 
                    color='primary' 
                    test_tag='add-device'
                    on:click="fire('add', event)">Add Device</Button>
        {/if}
    </div>
    <div class='table-wrapper'>
    <table>
        <thead>
            <tr>
                <th class='enable'>
                    <Checkbox on:change='selectAll(event.target.checked)' 
                    hideLabels={true} 
                    test_tag='select-all'
                    checked={allSelected} />
                </th>
                
                {#if showExtraDetails}
                <th class={element(blockClass, 'device-name', 'header')}>
                    
                </th>   
                {/if}

                <th on:click='sort(sortColumns.LoggerName.key)' class={element(blockClass, 'device-name', 'header')}>
                    <div class='flex-table-header'>
                        Device name
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.LoggerName.key} />
                        </div>
                    </div>
                </th>            
 
                {#if showExtraDetails}
                <th class={element(blockClass, 'device-name', 'header')}>
                    <div class='flex-table-header'>
                        Alert status
                    </div>
                </th>   
                {/if}

                {#if showExtraDetails}
                <th on:click='sort(sortColumns.ProgramNet.key)' class={element(blockClass, 'program-net', 'header')}>
                    <div class='flex-table-header'>
                        Program net
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.ProgramNet.key} />
                        </div>
                    </div>
                </th>
                {/if}  

                {#if showExtraDetails}
                <th on:click='sort(sortColumns.CommandSent.key)' class={element(blockClass, 'command-sent', 'header')}>
                    <div class='flex-table-header'>
                        Command Sent
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.CommandSent.key} />
                        </div>
                    </div>
                </th>
                {/if}

                {#if showExtraDetails}
                <th on:click='sort(sortColumns.CommandStatus.key)' class={element(blockClass, 'command-status', 'header')}>
                    <div class='flex-table-header'>
                        Command Status
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.CommandStatus.key} />
                        </div>
                    </div>
                </th>
                {/if}

                {#if showExtraDetails}
                <th on:click='sort(sortColumns.DeviceLocation.key)' class={element(blockClass, 'created-at', 'header')}>
                    <div class='flex-table-header'>
                    Device Location
                    <div class='flex-table-header-sort'>
                    <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.DeviceLocation.key} />
                    </div>
                    </div>
                </th>
                {/if}


                <th on:click='sort(sortColumns.UpdatedAt.key)' class={element(blockClass, 'created-at', 'header')}>
                    <div class='flex-table-header'>
                    Updated at
                    <div class='flex-table-header-sort'>
                    <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.UpdatedAt.key} />
                    </div>
                    </div>
                </th>
                <!-- <th on:click='sort(sortColumns.CreatedAt.key)' class={element(blockClass, 'created-at', 'header')}>
                    <div class='flex-table-header'>
                    Created at
                    <div class='flex-table-header-sort'>
                    <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.CreatedAt.key} />
                    </div>
                    </div>
                </th> -->

                <th class={element(blockClass, 'created-at', 'header')}>
                    <div class='flex-table-header'>
                    Group(s)
                    </div>
                </th>

                {#if showExtraDetails}
                <th on:click='sort(sortColumns.Firmware.key)' class={element(blockClass, 'firmware-version', 'header')}>
                    <div class='flex-table-header'>
                        Firmware version
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.Firmware.key} />
                        </div>
                    </div>
                </th>
                {/if}  

                <th on:click='sort(sortColumns.SerialNumber.key)' class={element(blockClass, 'serial-number', 'header')}>
                    <div class='flex-table-header'>
                        Serial number
                        <div class='flex-table-header-sort'>
                        <TableHeaderSortable {sortKey} {sortAsc} key={sortColumns.SerialNumber.key} />
                        </div>
                    </div>
                </th>

                
            </tr>
        </thead>
        <tbody>
            {#if filtered.length > 0}
            {#each filtered as item}
            <tr class={classes(element(blockClass, "row"), active(selected[item.id], true))}>

                <td class='enable'>
                    <Checkbox on:change='selectItem(event, item.id)' 
                        test_tag={`device-${item.id}`}
                        hideLabels={true} 
                        checked={selected[item.id]} />
                </td>
                
                {#if showExtraDetails}
                <td class={element(blockClass, 'device-running', 'column')}>
                    {#if isRunning(item)}
                    <div data-test='started' class='started'>
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                    {:elseif isDead(item)}
                    <div data-test='dead' class='dead'>
                        <i class="fas fa-circle-notch"></i>
                    </div>
                    {:else}
                    <div data-test='stopped' class='stopped'>
                        <i class="fas fa-circle-notch"></i>
                    </div>
                    {/if}
                </td>
                {/if}

                <td title={item.loggerName} class={element(blockClass, 'device-name', 'column')}>
                    {#if showExtraDetails}
                        <div class='alert-row' on:click="showDeviceInfo(item)">
                        {item.loggerName}
                        </div>
                    {:else}
                    {item.loggerName}
                    {/if}
                </td>

                {#if showExtraDetails}
                <td class={element(blockClass, 'device-name', 'column')}>
                    <div class='alert-row' on:click="showTriggers(item)">
                    {getLastAlertDisplay(alertStatus[item.serialNumber])}
                    </div>
                </td>
                {/if}
      
                {#if showExtraDetails}
                <td title={item.programName} class={element(blockClass, 'serial-number', 'column')}>
                    {item.programName}
                </td>
                {/if}

                {#if showExtraDetails}
                <td  class={element(blockClass, 'serial-number', 'column')}>
                    {getCommandText(item.command)}
                </td>
                {/if}

                {#if showExtraDetails}
                <td  class={element(blockClass, 'serial-number', 'column')}>
                    {getCommandStatus(item.command)}
                </td>
                {/if}

                {#if showExtraDetails}
                <td  class={element(blockClass, 'serial-number', 'column')}>
                    {handleNull(item.location)}
                </td>
                {/if}

 
                <td class={element(blockClass, 'updated-at', 'column')}>
                    {formatNullDateTime(item.deviceCommandTimestamp)}
                </td>

                
                <td  class={element(blockClass, 'serial-number', 'column')}>
                    {#if Array.isArray(deviceGroupNames[item.id]) && deviceGroupNames[item.id].length > 0}
                        {#each deviceGroupNames[item.id] as name}
                            <div title={name} class='group-name'>
                            {name}
                            </div>
                        {/each}
                    {:else}
                        <div class='group-name'>
                            ---
                        </div>
                    {/if}
                
                </td>

                {#if showExtraDetails}
                <td title={reverse(item.loggerVersion)} class={element(blockClass, 'serial-number', 'column')}>
                    {reverse(item.loggerVersion)}
                </td>
                {/if}

                <td title={item.serialNumber} class={element(blockClass, 'serial-number', 'column')}>
                    {item.serialNumber}
                </td>
            </tr>
            {/each}
            {:else}
            <TableNoResults columnSpan="12" />
            {/if}
        </tbody>
    </table>
</div>
</div>

{#if showDeleteWarning}
<Modal ref:modal {trapFocus} {initialFocusElement} on:result="deleteSelected(event)" on:hidden="set({ showDeleteWarning: false })">
    <DeleteDevice on:dismiss="refs.modal.dismiss(event)" 
        on:cancel="refs.modal.dismiss(event)"
        message='Delete device'
        secondaryMessage='Are you sure you want to delete the device?'
        buttonConfirmClass='confirm-btn-medium'
        buttonConfirmText='Delete device'>
    </DeleteDevice>
</Modal>
{/if}    

<script>
        import { DevicesEventType } from 'Services/constants';
    import { isRunning } from '@shanewwarren/aqlcloud-shared-types';
    import { ago } from 'time-ago';
    import { element } from 'Services/bem';
    import { classes } from 'Services/classes';
    import { active } from 'Services/helpers';
    import { getDeviceGroupNameMap } from '../../../services/model-helpers.ts';
    import { isDead } from 'Services/logger-state';
    import { formatNullDateTime, getLastAlertDisplay, reverse, formatDate, formatTime, getCommandStatus, getCommandText, handleNull } from 'Services/formatters';
    import DeleteDevice from './DeleteDevice';
    import {
    	ColumnType,
    	filterItems,
    	getSelectedCount,
    	getAllSelected,
    	selectItem,
    	selectAll,
    	sort,
    } from 'Services/table';
    import {
        Modal,
    	Button,
    	Checkbox,
    	TableHeaderSortable,
        TextInput,
        TableNoResults
    } from 'Shared';

    const SortableColumns = {
    	LoggerName: {
    		key: 'loggerName',
    		type: ColumnType.String,
    	},
    	SerialNumber: {
    		key: 'serialNumber',
    		type: ColumnType.Number,
    	},
    	CreatedAt: {
    		key: 'createdAt',
    		type: ColumnType.Date,
        },
    	UpdatedAt: {
    		key: 'deviceCommandTimestamp',
    		type: ColumnType.Date,
        },
        ProgramNet: {
    		key: 'programName',
    		type: ColumnType.String,
        },
    	Running: {
    		key: 'updatedAt',
    		type: ColumnType.Boolean,
        },
        Firmware: {
            key: 'loggerVersion',
            type: ColumnType.Version
        },
        CommandSent: {
            key: 'commandSent',
            type: ColumnType.String
        },
        CommandStatus: {
            key: 'commandStatus',
            type: ColumnType.String
        },
        DeviceLocation: {
            key: 'deviceLocation',
            type: ColumnType.String
        }    
    };



    export default {
    	components: {
    		Button,
    		Checkbox,
    		TableHeaderSortable,
            TextInput,
            DeleteDevice,
            Modal,
            TableNoResults
    	},
    	computed: {
    		selectedCount: ({ selected }) => getSelectedCount(selected),
    		allSelected: ({ filtered, selected }) => getAllSelected(filtered, selected),
    		filtered: ({ items, filter, sortKey, sortAsc, sortColumns }) =>
                filterItems(items, 'loggerName', filter, sortKey, sortAsc, sortColumns),
            deleteButtonDisabled: ({ selectedCount }) => selectedCount === 0 || selectedCount > 1,
            deviceGroupNames: ({ items, groups }) => {
                return getDeviceGroupNameMap(items, groups);
            }
    	},
    	data() {
    		return {
    			blockClass: 'device-table',
    			selected: {},
                alertStatus: {},
    			filter: '',
    			showCreateButton: true,
                showDeleteButton: true,
                showExtraDetails: false,
    			selectOnly: false,
    			sortKey: SortableColumns.LoggerName.key,
    			sortAsc: true,
                sortColumns: SortableColumns,
                showDeleteWarning: false,
                groups: []
    		};
    	},
    	helpers: {
    		ago,
    		classes,
    		active,
    		element,
            isRunning,
            isDead,
            reverse,
            formatDate,
            formatTime,
            getCommandStatus,
            getCommandText,
            handleNull,
            getLastAlertDisplay,
            formatNullDateTime
    	},
    	methods: {
            showDeviceInfo(e) {
                this.fire('event', {type: DevicesEventType.DeviceInfo, device: e});
            },
            showTriggers(e) {
                this.fire('event', {type: DevicesEventType.ViewTriggers, device: e});
            },
    		showDeleteConfirmation(e) {
    			this.set({ showDeleteWarning: true });
    		},
    		deleteSelected(payload) {
                if (payload === null || payload === undefined) {
                    return;
                }

                const { selected } = this.get();
                const ids = Object.keys(selected)
                                .filter(key => selected[key]);
                const id = ids[0];

                this.store.showProgress();
                this.store.disassociateDevice(id, payload).then(() => {
                    this.store.showNotification('Device deleted');
                    this.set({ selected: {} });
                }).catch((err) => {
                    this.store.showError(err.message);
                });
            },
    		selectItem,
    		selectAll,
    		sort,
    	},
    };
</script>

<style>
    .table-wrapper {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 24px;
    }

    .flex-table-header {
        display: flex;
    }

    .flex-table-header-sort {
        padding-left: 6px;
        margin-left: auto;
    }

    .alert-row {
        cursor: pointer;
    }
</style>