import { Validator, ValidatorType, IValidatorResult } from './validators';

export class Field<T> {
    private _key: string;
    private _validators: Validator<T>[];

    private _valid: boolean = true;
    public get valid(): boolean {
        return this._valid;
    }

    private _error: string;
    public get error(): string {
        return this._error;
    }

    private _initialized: boolean = false;
    public get initialized(): boolean {
        return this._initialized;
    }

    private _skipInitialization: boolean = false;

    public get type(): string {
        return "field";
    }

    constructor(field: IField<T>) {
        this._validators = field.validators;
        this._key = field.key;
        this._skipInitialization = field.skipInitialization ? field.skipInitialization : false;
    }

    public onupdate(current: any, previous: any, skipInitialization: boolean = false) {
        for (const validator of this._validators) {
            if ((!this._initialized && previous[this._key] !== undefined || previous[this._key] !== null || previous[this._key] === '') || skipInitialization) {
                this._initialized = true;
            }

            if (this._initialized || this._skipInitialization) {
                let result: IValidatorResult;
                if (validator.type === ValidatorType.Field) {
                    result = validator.validate(current[this._key], previous[this._key]);
                } else {
                    result = validator.validateFields(current, previous);
                }

                if (!result.valid) {
                    this._error = result.error;
                    this._valid = result.valid;
                    return false;
                }
            }
        }

        this._error = '';
        this._valid = true;
        return true;
    }
}

export interface IField<T> {
    key: string;
    validators: Validator<T>[];
    skipInitialization?: boolean;
}

export const FormField = <T>(formField: IField<T>) => new Field<T>(formField);


export interface Form {
    valid: boolean;
    type: string;
    values: { [key: string]: any };
    fields: {
        [key: string]: any;
    }
}

export interface Forms {
    valid: boolean;
    type: string;
    forms: Form[];
}
