<div  class='exceptions-section' on:click='navigateToDevicesPage(event)'>  
    <span title="{count} unacknowledged {pluralize('alert', count)}." class="fa-stack alert-icon-group">
        <i class="fas fa-square fa-stack-1x icon-square {alertClass}"></i>
        <i class='fas fa-stack-1x fa-bell {alertClass} icon-primary'></i>
    </span>
    {#if count > 0}
        <div class='count-container {countClass}'>
            <div class='count'>{count}</div>
        </div>
    {/if}
</div>


<script>
import { pluralize } from 'Services/helpers';
export default {
    computed: {
        count: ({$nonAcknowledgedAlertInstanceCount  }) => $nonAcknowledgedAlertInstanceCount,
        alertClass: ({ count }) => {
            return count === 0 ? '' : 'has-notify-items';
        },
        countClass: ({ count }) => {
            if (count / 10 < 1) {
                return 'single';
            } else if (count / 100 < 1) {
                return 'double';
            } else if (count / 1000 < 1) {
                return 'triple';
            } else {
                return 'quadruple'
            }
        }
    },
    helpers: {
        pluralize
    },
    data() {
        return {
            open: false,
            disable: false,
            promise: null
        }
    },
    oncreate() {
        const promise = this.store.getNonAcknowledgedAlertInstanceCount();
        this.set({ promise });
    },
    methods: {
        navigateToDevicesPage(event) {
            const { count } = this.get();
            if (count > 0) {
                this.fire('click');
            }
        }
    }
}
</script>