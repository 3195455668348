<Card className='modal-card'>
    <div class={blockClass}>
        <form>
            <fieldset>
                <div class='legend'>
                    <legend>
                        Select a custom range
                    </legend>
                </div>
                <div class='controls'>
                    <div class='date-container'>
                        <div class='flex-rows'>
                            <div class='inline date-label'>From:</div>
                            <Flatpicker 
                                test_tag='start-time'
                                className="width-14 code-field" 
                                rawValue={startDate}
                                on:change='dateTimeChange(event, "startDate")' />
                        </div>
                        <div class='flex-rows margin-l-3'>
                            <div class='inline date-label'>To:</div>
                            <Flatpicker 
                                test_tag='end-time'
                                className="width-14 code-field" 
                                rawValue={endDate}
                                on:change='dateTimeChange(event, "endDate")' />
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class='button-panel'>
                <Button disabled={!isValid} test_tag='submit' on:click='submit(event)' color='primary'>Save</Button>
                <Button test_tag='cancel' on:click='cancel(event)' className="cancel" color="normal">Cancel</Button>
            </div>
        </form>
    </div>
</Card>
        
<style>
    .date-label {
        margin-bottom: 0.5rem;
        margin-left: 1rem;
    }

    .date-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

<script>
import { Card, Button, Flatpicker } from 'Shared';

export default {
 components: {
     Card,
     Button,
     Flatpicker
 },
 computed: {
 },
 data() {
     return {
         blockClass: 'custom-range-dialog',
         startDate: new Date(),
         endDate: new Date(),
         isValid: true
     };
 },
 onupdate({ changed, current }) {
    if (changed.startDate || changed.endDate) {
        this.validate(current.startDate, current.endDate);
    }
 },
 methods: {
     validate(startDate, endDate) {
        if (!startDate || !endDate) {
            this.set({ isValid: false });
        } else if (endDate.getTime() < startDate.getTime()) {
            this.set({ isValid: false });
        } else {
            this.set({ isValid: true});
        }
     },
    dateTimeChange(event, key) {
        const dateNumber = Date.parse(event.target.value);
        const value = (isNaN(dateNumber)) ? undefined : new Date(dateNumber);
        (key === 'startDate') ? 
            this.set({ startDate: value }) :
            this.set({ endDate: value });
    },
     submit(e) {
         e.preventDefault();
         const { startDate, endDate } = this.get();
         this.fire('dismiss', { startDate, endDate });
     },
     cancel(e) {
         e.preventDefault();
         this.fire('cancel');
     },
 },
};
</script>