<div class='group-channel-table table'>
    <div class={element('group-channel-table', 'table-legend')}>
		<div class="margin-r-2">
			<GroupSelector max={1} on:groupselect="groupChange(event)" {groups} {selectedGroups} />
		</div>
        <div class={element('group-channel-table', 'filter')}>
            <TextInput bind:value="filter" 
				containerClassName={element('group-channel-table', 'search-filter')} 
				iconClass='fas fa-search' 
				placeholder='Search' />
            <div data-test='item-count' class={element('group-channel-table', 'count')}>
                {selectedCount} {pluralize('channel', selectedCount)} selected.
            </div>
        </div>
	</div>
	<div class='channel-list'>
		<div class='list-header'>
			<div class='list-header-column'>
				<Checkbox on:change='selectAll(event)' 
					hideLabels={true} 
					test_tag='select-all'
					checked={allSelected} />
			</div>
			<div class='list-header-column'>Channel Name</div>
			<div class='list-header-column'>Logger Name</div>
			<div class='list-header-column'>Group Name(s)</div>
		</div>
		<div class='list-body' style='height:300px; overflow-y: auto;'>
			{#if hasAnyChannels}
			{#each filtered as device}
				{#if device.activeSeries}
					
					{#each device.activeSeries.channels as channel, index}
						<div class='list-row {channel.status} {active(selectedChannels[channel.id], true)}'>
							<div class='list-column'>
								<Checkbox 
									test_tag={`channel-${channel.id}`}
									on:change='selectItem(event, channel.id)' 
									hideLabels={true} 
									checked={selectedChannels[channel.id]} />
							</div>
							<div title={channel.pointName} class='list-column'>{channel.pointName}</div>
							<div class='list-column'>
								{#if index === 0}
									<div title={device.loggerName}>{device.loggerName}</div>
								{/if}
							</div>
							<div class='list-column'>
								{#if index === 0}
									{#each device.groupNames as name}
										<div title={name} class='group-name'>
										{name}
										</div>
									{/each}
								{/if}
							</div>
						</div>
					{/each}
				{/if}
			{/each}
			{:else}
			<div class='no-search-results'>
				No channels were found.
			</div>
			{/if}
		</div>
	</div>
</div>

    
<script>
    import { element } from 'Services/bem';
	import { active, pluralize } from 'Services/helpers';
	import { allSelected, allSelectedClick, getGroupChannelResults, combineGroups } from 'Services/model-helpers';
    import { GroupSelector, Checkbox, TextInput } from 'Shared';

    export default {
    	components: {
    		Checkbox,
			TextInput,
			GroupSelector
    	},
    	computed: {
    		channelCount: ({ groups }) => getGroupChannelResults(groups).length,
    		selectedCount: ({ selectedChannels }) => {
    			return Object.keys(selectedChannels).filter(key => selectedChannels[key])
    				.length;
    		},
    		allSelected: ({ filtered, selectedChannels }) => {
    			return allSelected(filtered, selectedChannels);
    		},
    		filtered: ({ groups, selectedGroups, filter }) => {
				return combineGroups(selectedGroups.length === 0 ? groups : selectedGroups, new RegExp(filter, 'i'));
			},
			hasAnyChannels: ({ filtered }) => {
				let count = 0;
				filtered.forEach(device => {
					if (!device.activeSeries) return;
					device.activeSeries.channels.forEach((channel) => {
						count++;
					})
				});
				return count > 0;
			}
    	},
    	data() {
    		return {
    			groups: [],
    			selectedChannels: {},
				selectedGroups: [],
    			filter: '',
    		};
    	},
    	helpers: {
    		active,
    		element,
			pluralize
    	},
    	methods: {
			groupChange(event) {
				const { groups } = this.get();
				const selectedGroups = groups.filter(group => event.ids.includes(group.id));
				this.set({
					selectedGroups
				});
			},
    		deleteSelected(event) {},
    		selectItem(event, id) {
    			this.fire('select', {
    				type: 'channel',
    				id: id,
    				checked: event.target.checked,
    			});
    		},
    		selectAll(event) {
				const { filtered, selectedChannels } = this.get();
				const ids = allSelectedClick(filtered, selectedChannels, event.target.checked);
    			this.fire('selectAll', {
    				type: 'channel',
					ids
    			});
    		},
    	},
    };
</script>