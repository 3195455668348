import { CognitoIdentityCredentials, config } from 'aws-sdk/global';
import {
	CommandTag,
	ConfigBlock,
	DataBlock,
	LoggerStatus,
	Topics,
} from '@shanewwarren/aqlcloud-shared-types';

import { AlertTag } from '@shanewwarren/aqlcloud-shared-types/dist/types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { IoTClient } from './lib/iot-client';

export interface IAqlBrokerOptions {
	region: string;
	host: string;
	clientId?: string;
	baseReconnectTimeMs?: number;
	maximumReconnectTimeMs?: number;
	debug?: boolean;
}

export type DataHandler = (serialNumber: string, data: DataBlock) => void;
export type StatusHandler = (serialNumber: string, data: LoggerStatus) => void;
export type SessionHandler = (serialNumber: string, data: ConfigBlock) => void;
export type TriggerHandler = (serialNumber: string, data: AlertTag) => void;
export type CommandHandler = (serialNumber: string, data: CommandTag) => void;

export class AqlBroker {
	private devices: number[] = [];
	private iot: IoTClient | null;
	private options: IAqlBrokerOptions;
	private onData: DataHandler;
	private onStatus: StatusHandler;
	private onSession: SessionHandler;
	private onTrigger: TriggerHandler;
	private onCommand: CommandHandler;

	constructor(
		clientOptions: IAqlBrokerOptions,
		onData: DataHandler,
		onStatus: StatusHandler,
		onSession: SessionHandler,
		onTrigger: TriggerHandler,
		onCommand: CommandHandler
	) {
		this.options = clientOptions;
		this.onData = onData;
		this.onStatus = onStatus;
		this.onSession = onSession;
		this.onTrigger = onTrigger;
		this.onCommand = onCommand;
	}

	public connect(session: CognitoUserSession | null) {
		if (!session || !session.isValid()) {
			return;
		}

		const credentials: CognitoIdentityCredentials = config.credentials as CognitoIdentityCredentials;

		this.disconnect();

		this.iot = IoTClient.create(true, {
			region: this.options.region,
			host: this.options.host,
			debug: this.options.debug,
			accessKeyId: credentials.accessKeyId,
			secretAccessKey: credentials.secretAccessKey,
			sessionToken: credentials.sessionToken,
		});

		this.iot.attachConnectHandler(this.onConnect.bind(this));
		this.iot.attachCloseHandler(this.onClose.bind(this));
		this.iot.attachMessageHandler(this.onMessage.bind(this));

		this.iot.updateWebSocketCredentials(
			credentials.accessKeyId,
			credentials.secretAccessKey,
			credentials.sessionToken,
			credentials.expireTime
		);
	}

	public disconnect() {
		if (this.iot) {
			if (this.devices.length > 0) {
				this.devices.forEach(device => {
					this.removeDevice(device);
				})
			}

			this.iot.disconnect();
			this.iot = null;
		}
	}

	public addDevice(serialNumber: number) {
		this.devices.push(serialNumber);
		if (this.iot) {
			const statusTopic = Topics.toTopic(serialNumber, Topics.MQTTTopic.Status);
			const sessionTopic = Topics.toTopic(
				serialNumber,
				Topics.MQTTTopic.Session
			);
			const dataTopic = Topics.toTopic(serialNumber, Topics.MQTTTopic.Data);
			const triggerTopic = Topics.toTopic(
				serialNumber,
				Topics.MQTTTopic.Trigger
			);

			const commandTopic = Topics.toTopic(serialNumber, Topics.MQTTTopic.Command);

			this.iot.subscribe(statusTopic);
			this.iot.subscribe(dataTopic);
			this.iot.subscribe(sessionTopic);
			this.iot.subscribe(triggerTopic);
			this.iot.subscribe(commandTopic);
		}
	}

	public removeDevice(serialNumber) {
		if (!this.devices || this.devices.length === 0) {
			return;
		}

		this.devices = this.devices.filter(
			deviceSerialNumber => deviceSerialNumber !== serialNumber
		);
		if (this.iot) {
			const statusTopic = Topics.toTopic(serialNumber, Topics.MQTTTopic.Status);
			const sessionTopic = Topics.toTopic(
				serialNumber,
				Topics.MQTTTopic.Session
			);
			const dataTopic = Topics.toTopic(serialNumber, Topics.MQTTTopic.Data);
			const triggerTopic = Topics.toTopic(
				serialNumber,
				Topics.MQTTTopic.Trigger
			);
			const commandTopic = Topics.toTopic(
				serialNumber,
				Topics.MQTTTopic.Command
			);
			this.iot.unsubscribe(statusTopic);
			this.iot.unsubscribe(dataTopic);
			this.iot.unsubscribe(sessionTopic);
			this.iot.unsubscribe(triggerTopic);
			this.iot.unsubscribe(commandTopic);
		}
	}

	private onConnect() {
		// console.log('CONNECTED');
	}

	private onClose() {
		// console.log('CLOSE');
	}

	private onMessage(topicPayload: string, payload: any) {
		const { serialNumber, topic } = Topics.getTopic(topicPayload);
		const serialNumberString = serialNumber.toString();
		switch (topic) {
			case Topics.MQTTTopic.Status:
				const loggerStatus = LoggerStatus.fromBuffer(payload);
				this.onStatus(serialNumberString, loggerStatus);
				break;

			case Topics.MQTTTopic.Data:
				const dataBlock = DataBlock.fromBuffer(payload);
				this.onData(serialNumberString, dataBlock);
				break;
			case Topics.MQTTTopic.Session:
				const sessionBlock = ConfigBlock.fromBuffer(payload);
				this.onSession(serialNumberString, sessionBlock);
				break;
			case Topics.MQTTTopic.Trigger:
				const alertBlock = AlertTag.fromBuffer(payload);
				this.onTrigger(serialNumberString, alertBlock);
				break;
			case Topics.MQTTTopic.Command:
				const commandBlock = CommandTag.fromBuffer(payload);
				this.onCommand(serialNumberString, commandBlock);
				break;
			default:
			// console.log('Unknown topic:', topic);
		}
	}
}
