

export interface IValidatorResult {
    error: string;
    valid: boolean;
}

export const IsValid: IValidatorResult = {
    error: '',
    valid: true
};

export const NotValid = (error: string): IValidatorResult => ({
    error,
    valid: false
});

export enum ValidationErrors {
    NoValue = "Must have a value.",
    Integer = "Must be an integer.",
    PositiveInteger = "Must be a positive integer.",
    NoChannelsSelected = "Must select at least one channel.",
    NoDevicesSelected = "Must select at least one device.",
    NoContactsSelected = "Must select at least one contact.",
    EmptyString = "Must specify a value.",
    CannotBeNull = "Must specify a value.",
    NotValidDate = "Must specify a valid date."
}

export enum ValidatorType {
    Field = 'field',
    Fields = 'fields'
}

export class Validator<T> {
    public get type(): ValidatorType {
        return ValidatorType.Field;
    }

    public validate(value: T, prevValue: T): IValidatorResult {
        return IsValid;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        return IsValid;
    }
}

export class _NoContactsValidator extends Validator<any[]> {
    public validate(value: any[], prevValue: any[]): IValidatorResult {
        if (value.length === 0) {
            return NotValid(ValidationErrors.NoContactsSelected);
        }

        return IsValid;
    }
}

export const NoContactsValidator = () => new _NoContactsValidator();

export type StringIdSelect = { [id: string]: boolean };
export class _StringItemsValidator extends Validator<StringIdSelect> {
    public validate(value: StringIdSelect, prevValue: StringIdSelect): IValidatorResult {
        if (!value) {
            return NotValid(ValidationErrors.NoDevicesSelected);
        }

        const keys = Object.keys(value);
        if (keys.length === 0) {
            return NotValid(ValidationErrors.NoDevicesSelected);
        }

        if (!keys.some(key => value[key])) {
            return NotValid(ValidationErrors.NoDevicesSelected);
        }

        return IsValid;
    }
}

export const StringItemsValidator = () => new _StringItemsValidator();

export type NumberIdSelect = { [id: string]: boolean };
export class _NumberItemsValidator extends Validator<NumberIdSelect> {
    public validate(value: NumberIdSelect, prevValue: NumberIdSelect): IValidatorResult {
        if (!value) {
            return NotValid(ValidationErrors.NoChannelsSelected);
        }

        const keys = Object.keys(value);
        if (keys.length === 0) {
            return NotValid(ValidationErrors.NoChannelsSelected);
        }

        if (!keys.some(key => value[key])) {
            return NotValid(ValidationErrors.NoChannelsSelected);
        }

        return IsValid;
    }
}

export const NumberItemsValidator = () => new _NumberItemsValidator();

export class _IntegerValidator extends Validator<any> {
    public validate(value: any, prevValue: any): IValidatorResult {
        if (Number.isInteger(value)) {
            return IsValid;
        }
        return NotValid(ValidationErrors.Integer);
    }
}

export const IntegerValidator = () => new _IntegerValidator();

export class _PositiveIntegerValidator extends _IntegerValidator {
    public validate(value: any, prevValue: any): IValidatorResult {
        const result = super.validate(value, prevValue);
        if (!result.valid) {
            return result;
        }

        if (value <= 0) {
            return NotValid(ValidationErrors.PositiveInteger);
        }

        return IsValid;
    }
}

export const PositiveIntegerValidator = () => new _PositiveIntegerValidator();


export class _EmptyStringValidator extends Validator<string> {
    public validate(value: any, prevValue: any): IValidatorResult {
        if (!value || value.length === 0) {
            return NotValid(ValidationErrors.EmptyString);
        }
        return IsValid;
    }
}

export const EmptyStringValidator = () => new _EmptyStringValidator();

export class _DateValidator extends Validator<Date> {
    public validate(value: any, prevValue: any): IValidatorResult {
        const dateNumber: number = Date.parse(value);
        if (isNaN(dateNumber)) {
            return NotValid(ValidationErrors.NotValidDate);
        }
        return IsValid;
    }
}

export const DateValidator = () => new _DateValidator();




export function validate(component, changed, current, previous, fireEvent?: boolean, validateOnCreate?: boolean) {
    if (changed && (previous || validateOnCreate) && changed.values) {
        const fields = current.form.fields;
        const values = current.form.values;
        const prevValues = previous ? previous.form.values : {};

        let isValid = true;
        Object.keys(fields).forEach(key => {
            if (fields[key].type === 'forms') {
                isValid = fields[key].forms.every(form => form.valid) && isValid;
            } else {
                isValid = fields[key].onupdate(values, prevValues, validateOnCreate) && isValid;
            }
        });

        const form = current.form;
        form.valid = isValid;

        component.set({ form });

        if (fireEvent) {
            component.fire('valid', isValid);
        }
    }
}


// Form
    // type => "form"
// Field
    // type => "field"
// Forms
    // type => "forms"