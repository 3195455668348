{#if $toolbarOpen}
<div transition:fly="{ x: -250 }" class="toolbar-body open" data-test="toolbar">
	<header class="header {className}">
		<div class="menu-heading">
			<i
				class="fa fa-angle-left close-toolbar"
				data-test="close-toolbar"
				on:click="onScrimPress(event)"
			></i>
			<div class="menu-heading-text">Menu</div>
		</div>
		<nav>
			<ul>
                {#each options as option}
                    {#if !option.restrict || $fullAccess}
                        <li>
                            <BaseLink
                                on:click="onScrimPress()"
                                activeClass="menu-selected"
                                url={option.url}
                                active={active === option.url}
                                test_tag={option.test_tag}
                            >
                                <i class="fas {option.icon}"></i>{option.value}
                            </BaseLink>
                        </li>
                    {/if}
                {/each}
			</ul>
		</nav>
	</header>
</div>
{/if} {#if $toolbarOpen}
<div on:click="onScrimPress()">
	<slot name="scrim">
		<Scrim />
	</slot>
</div>
{/if}

<script>
	import { fly } from 'Services/transitions';
	import { BaseLink, Scrim } from 'Shared';

	export default {
		transitions: { fly },
		components: {
			BaseLink,
			Scrim,
		},
		data() {
			return {
                active: '',
				className: '',
				options: [
					{
						url: '/app/dashboard',
						test_tag: 'dashboard',
						icon: 'fas fa-chart-area',
                        value: 'Dashboard',
					},

					{
						url: '/app/devices',
						test_tag: 'devices',
						icon: 'fas fa-hdd',
						value: ' Devices',
					},

					{
						url: '/app/groups',
						test_tag: 'groups',
						icon: 'fas fa-folder',
						value: ' Groups',
					},

					{
						url: '/app/alerts',
						test_tag: 'alerts',
						icon: 'fas fa-bell',
						value: ' Alerts',
					},

					{
						url: '/app/reports',
						test_tag: 'reports',
						icon: 'fas fa-alarm-clock',
						value: ' Reports',
					},

					{
						url: '/app/log',
						test_tag: 'log',
						icon: 'fas fa-server',
                        value: ' Communication Log',
                        restrict: true
					},

					{
						url: '/app/accounts',
						test_tag: 'accounts',
						icon: 'fas fa-plusress-card',
                        value: ' Accounts',
                        restrict: true
					},
				],
			};
		},
		methods: {
			logout() {
				const { store, router } = this.get();
				store.personita.logout();
				router.route('/login');
			},
			close(evt) {
				if (evt) {
					evt.preventDefault();
				}
			},
			onScrimPress(evt) {
				if (evt) {
					evt.preventDefault();
				}
				this.store.toggleToolbar(false);
			},
		},
	};
</script>
