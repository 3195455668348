import { ITrigger, IUpdateAlert } from '@shanewwarren/aqlcloud-shared-types';
import { StringItemsValidator, StringIdSelect } from '../validators';
import { Form, FormField, Field } from '../field';

export interface DeviceSelect {
    deviceIds: StringIdSelect;
}

export interface DeviceSelectForm extends Form {
    values: {
        deviceIds: StringIdSelect
    },
    fields: {
        deviceIds: Field<StringIdSelect>;
    }
}

export const fromModel = (model?: ITrigger): DeviceSelectForm => {
    const deviceIds = {};
    if (model) {
        model.devices.forEach(device => deviceIds[device.id] = true);
    }

    return {
        valid: false,
        type: 'form',
        values: {
            deviceIds
        },
        fields: {
            deviceIds: FormField({
                key: 'deviceIds',
                validators: [StringItemsValidator()]
            })
        }
    };
}

export function toModel(model: IUpdateAlert, form: DeviceSelectForm) {
    let trigger: any = {};
    if (model.trigger) {
        trigger = model.trigger;
    }

    trigger.deviceIds = Object.keys(form.values.deviceIds)
        .filter(key => form.values.deviceIds[key])
        .map(key => key);

    model.trigger = trigger;

    return model;
}