import { EmptyStringValidator, IValidatorResult, IsValid, NoContactsValidator, NotValid, Validator, ValidatorType } from '../validators';
import { Field, Form, FormField, Forms } from '../field';
import { IContact, INotification, IUpdateAlert, IUpdateNotificationAck, NotificationType } from '@shanewwarren/aqlcloud-shared-types';
import { fromModel as fromAckModel, toModel as toAckModel } from './ack';

export interface NotificationForm extends Form {
    values: {
        type: NotificationType;
        recipients: IContact[];
        cc: IContact[];
        subject: string;
        message: string;
        includeUnitName: boolean;
        includeChannelName: boolean;
        includeUnitDateTime: boolean;
        includeSerialNumber: boolean;
        includeLastDataValue: boolean;
        includeUnitLocation: boolean;
        acks: any[];
    },
    fields: {
        type: Field<NotificationType>;
        recipients: Field<IContact[]>;
        cc: Field<IContact[]>;
        subject: Field<string>;
        message: Field<string>;
        includeUnitName: Field<boolean>;
        includeChannelName: Field<boolean>;
        includeUnitDateTime: Field<boolean>;
        includeSerialNumber: Field<boolean>;
        includeLastDataValue: Field<boolean>;
        includeUnitLocation: Field<boolean>;
        acks: Forms;
    }
}


class _NotificationTypeValidator extends Validator<NotificationType> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values['type'] === NotificationType.Email) {
            if (!values['subject'] || values['subject'].length === 0) {
                return NotValid('Must specify an email subject.');
            }
        }

        if (values['type'] === NotificationType.SMS) {
            if (!values['message'] || values['message'].length === 0) {
                return NotValid('Must specify a message body.');
            }
        }

        if (!values['recipients'] || values['recipients'].length === 0) {
            return NotValid('Must specify at least one recipient.');
        }

        return IsValid;
    }
}

const NotificationTypeValidator = () => new _NotificationTypeValidator();

class _MessageValidator extends Validator<NotificationType> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values['type'] === NotificationType.SMS) {
            if (!values['message'] || values['message'].length === 0) {
                return NotValid('Must specify a message body.');
            }
        }
        return IsValid;
    }
}

const MessageValidator = () => new _MessageValidator();

class _SubjectValidator extends Validator<NotificationType> {
    public get type(): ValidatorType {
        return ValidatorType.Fields;
    }

    public validateFields(values: any, prevValues: any): IValidatorResult {
        if (values['type'] === NotificationType.Email) {
            if (!values['subject'] || values['subject'].length === 0) {
                return NotValid('Must specify an email subject.');
            }
        }
        return IsValid;
    }
}

const SubjectValidator = () => new _SubjectValidator();


export const fromModel = (model?: INotification): NotificationForm => {

    let type: NotificationType = NotificationType.SMS;
    let recipients: IContact[] = [];
    let cc: IContact[] = [];
    let subject: string | null = '';
    let message: string | null = '';
    let includeUnitName: boolean = true;
    let includeChannelName: boolean = true;
    let includeUnitDateTime: boolean = true;
    let includeSerialNumber: boolean = true;
    let includeLastDataValue: boolean = true;
    let includeUnitLocation: boolean = true;
    let acks: any[] = []


    if (model) {
        type = model.type;
        recipients = model.recipients;
        cc = model.cc;
        subject = model.subject;
        message = model.message;
        includeUnitName = model.includeUnitName;
        includeChannelName = model.includeChannelName;
        includeUnitDateTime = model.includeUnitDateTime;
        includeSerialNumber = model.includeSerialNumber;
        includeLastDataValue = model.includeLastDataValue;
        includeUnitLocation = model.includeUnitLocation;
        acks = model.acks ? model.acks.map(fromAckModel) : [];
    }

    return {
        type: 'form',
        valid: false,
        values: {
            type,
            recipients,
            cc,
            subject,
            message,
            includeUnitName,
            includeChannelName,
            includeUnitDateTime,
            includeSerialNumber,
            includeLastDataValue,
            includeUnitLocation,
            acks
        },
        fields: {
            type: FormField({
                key: 'type',
                validators: [NotificationTypeValidator()]
            }),
            recipients: FormField({
                key: 'recipients',
                validators: [NoContactsValidator()]
            }),
            cc: FormField({
                key: 'cc',
                validators: []
            }),
            subject: FormField({
                key: 'subject',
                validators: [SubjectValidator()]
            }),
            message: FormField({
                key: 'message',
                validators: [MessageValidator()]
            }),
            includeUnitName: FormField({
                key: 'includeUnitName',
                validators: []
            }),
            includeChannelName: FormField({
                key: 'includeChannelName',
                validators: []
            }),
            includeUnitDateTime: FormField({
                key: 'includeUnitDateTime',
                validators: []
            }),
            includeSerialNumber: FormField({
                key: 'includeSerialNumber',
                validators: []
            }),
            includeLastDataValue: FormField({
                key: 'includeLastDataValue',
                validators: []
            }),
            includeUnitLocation: FormField({
                key: 'includeUnitLocation',
                validators: []
            }),
            acks: {
                type: 'forms',
                valid: false,
                forms: acks
            }
        }
    };
}

export function toModel(model: IUpdateAlert, form: NotificationForm) {
    model.notification = {
        type: form.values.type,
        recipients: form.values.recipients.map(c => c.id),
        cc: form.values.cc.map(c => c.id),
        subject: form.values.type === 'sms' ? '' : form.values.subject,
        message: form.values.message,
        includeUnitName: form.values.includeUnitName,
        includeChannelName: form.values.includeChannelName,
        includeUnitDateTime: form.values.includeUnitDateTime,
        includeSerialNumber: form.values.includeSerialNumber,
        includeLastDataValue: form.values.includeLastDataValue,
        includeUnitLocation: form.values.includeUnitLocation
    };

    if (form.values.acks && form.values.acks.length > 0) {
        model.notification.acks = form.values.acks.map((ack) => {
            const ackModel: IUpdateNotificationAck = {
                enabled: false,
                minutes: 0,
                appendAckFailureMessage: false,
                recipients: []
            };
            return toAckModel(ackModel, ack);
        })
    }

    return model;
}
