<svelte:window on:keyup="onKeyup(event)" />
<Portal>
<div ref:modal class="svelte-modal" tabindex="-1" data-center="{ center }" data-hidden="{ hidden }" style="z-index: { zIndex }; opacity: { modalStyle.opacity }; background: {multiple ? 'transparent' : 'inherit'};">
    <div ref:content class="content" style="transform: scale({ contentStyle.scale });">
        <slot></slot>
    </div>

    <div on:click="onScrimPress()">
        <slot name="scrim">
            <Scrim/>
        </slot>
    </div>
</div>
</Portal>

<script>
        import { spring } from 'svelte-extras';
		import Scrim from './Scrim.html';
		import Portal from './Portal.html';
        import FocusTrap from 'focus-trap';
        import makeModalStackable from './make-modal-stackable';

        // TODO: write a smaller, less "featured" focusTrap. It really just needs to trap focus
        const makeFocusTrap = ({ rootElement }) => {
        	return FocusTrap(rootElement, {
        		initialFocus: rootElement,
        		fallbackFocus: rootElement,
        		escapeDeactivates: false,
        		returnFocusOnDeactivate: true,
        		clickOutsideDeactivates: false,
        	});
        };

        /* TODO: maybe make a way to accept custom transitions */
        // which might conflict with this todo:
        /* TODO: be fancy and take a touch/click/element position to transition in from */
        const STYLE = {
        	modal: {
        		open: {
        			opacity: 1,
        		},
        		hidden: {
        			opacity: 0,
        		},
        	},
        	content: {
        		open: {
        			scale: 1,
        		},
        		hidden: {
        			scale: 0.9,
        		},
        	},
        };
        const DEFAULTS = {
        	initiallyHidden: false,
        	initialFocusElement: false,
        	center: true,
        	zIndexBase: 9999,
        	pressScrimToDismiss: true,
        	escToDismiss: true,
        	trapFocus: true,
        };
        const FIRES = {
        	opening: 'opening',
        	opened: 'opened',

        	result: 'result',
        	dismissed: 'dismissed',
        	closed: 'closed',

        	hiding: 'hiding',
        	hidden: 'hidden',
        };
        const ONS = {
        	open: 'open',
        	dismiss: 'dismiss',
        	close: 'close',
        };
        [STYLE, DEFAULTS, FIRES, ONS].forEach(Object.freeze);

        export default {
        	tag: 'svelte-modal',

        	setup(Modal) {
        		Object.assign(Modal, {
        			DEFAULTS,
        			FIRES,
        			ONS,
        		});
        	},

        	components: {
				Scrim,
				Portal
        	},

        	data() {
        		return Object.assign(
        			{
        				hidden: true,
        				hiding: false,
        				opening: false,
        				inForeground: false, // to handle stacking of multiple modals open at once
        				modalStyle: STYLE.modal.hidden,
						contentStyle: STYLE.content.hidden,
						multiple: false
        			},
        			DEFAULTS
        		);
        	},

        	computed: {
        		transitioning: ({ hiding, opening }) => hiding || opening,
        		open: ({ hidden, transitioning }) => !hidden && !transitioning,
        		zIndex: ({ zIndexBase, inForeground }) =>
        			inForeground ? zIndexBase : zIndexBase - 1,
        	},

        	oncreate() {
        		this.on(ONS.open, () => this.open());
        		this.on(ONS.dismiss, e => this.dismiss(e));
        		this.on(ONS.close, e => this.close(e));

        		const rootElement = this.refs.modal;

        		this.focusTrap = makeFocusTrap({
        			rootElement,
        		});
        		this.on(FIRES.hiding, () => this.focusTrap.deactivate());
        		this.on('destroy', () => this.focusTrap.deactivate());

        		makeModalStackable(this);

        		this.on('opening', () => {
        			const { initiallyHidden, trapFocus } = this.get();
        			if (trapFocus) {
        				this.focusTrap.activate();
        			}
        			setTimeout(() => {
        				/* focusTrap seems unable to focus the element
							putting activate() in the setTimeout does not help
							Focusing it manually works just fine,
							and we need to manually focus anyway when trapFocus is false
							also, I don't think focusTrap needs to concern itself with focusing elements
						*/
        				this.focusInitialFocusElement();
        			});
        		});

        		const { initiallyHidden } = this.get();
        		if (!initiallyHidden) {
        			this.open();
				}
				
				const length = document.querySelectorAll('.svelte-modal').length;
				if (length > 1) {
					this.set({ multiple: true });
				}
        	},

        	methods: {
        		spring(key, end, options) {
        			options = options || {
        				stiffness: 0.5,
        				damping: 1,
					};
					try {
						return spring.call(this, key, end, options);
					} catch(err) {
						// ignore...
					}
        		},

        		focusInitialFocusElement() {
        			const { initialFocusElement } = this.get();
        			initialFocusElement && initialFocusElement.focus();
        		},

        		onKeyup(event) {
        			const { escToDismiss, inForeground } = this.get();
        			const shouldDismiss =
        				event.key.toLowerCase() === 'escape' && escToDismiss && inForeground;
        			if (shouldDismiss) {
        				this.dismiss();
        			}
        		},

        		onScrimPress() {
        			const { pressScrimToDismiss } = this.get();
        			if (pressScrimToDismiss) {
        				this.dismiss();
        			}
        		},

        		open() {
        			const { open, opening } = this.get();
        			if (open || opening) {
        				return;
        			}

        			this.set({
        				opening: true,
        				hiding: false,
        				hidden: false,
        			});
        			this.fire(FIRES.opening);

        			Promise.all([
        				this.spring('modalStyle', STYLE.modal.open),
        				this.spring('contentStyle', STYLE.content.open),
        			]).then(() => {
        				this.set({
        					opening: false,
        				});
        				this.fire(FIRES.opened);
					});

        			return this;
        		},

        		hide(reason, result) {
        			const { hidden, hiding } = this.get();
        			if (hidden || hiding) {
        				return;
        			}

        			this.set({
        				opening: false,
        				hiding: true,
        			});

        			this.fire(FIRES.result, result);
        			this.fire(reason, result);
        			this.fire(FIRES.hiding);

        			Promise.all([
        				this.spring('modalStyle', STYLE.modal.hidden),
        				this.spring('contentStyle', STYLE.content.hidden),
        			]).then(() => {
        				this.set({
        					hiding: false,
        					hidden: true,
        				});
        				this.fire(FIRES.hidden);
        			});

        			return this;
        		},

        		close(result) {
        			return this.hide(FIRES.closed, result);
        		},

        		dismiss(result) {
        			return this.hide(FIRES.dismissed, result);
        		},

        		background() {
        			this.focusTrap.pause();
        			this.set({
        				inForeground: false,
        			});
        		},

        		foreground(modal) {
        			this.focusTrap.unpause();
        			this.focusInitialFocusElement();
        			this.set({
        				inForeground: true,
        			});
        		},
        	},
        };
</script>

<style>
    .svelte-modal {
    	position: fixed;
    	top: 0;
    	left: 0;
    	right: 0;
    	height: 100%;
    	display: flex;
    	align-items: flex-start;
    	justify-content: center;
    }

    [data-center='true'] {
    	align-items: center;
    }

    [data-hidden='true'] {
    	visibility: hidden;
    }

    .content {
    	max-width: 100vw;
    	max-height: 100vh;
    	overflow: visible;
    	z-index: 9999;
    }
</style>