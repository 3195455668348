import { IAccount, IUpdateCompanyInfo } from '@shanewwarren/aqlcloud-shared-types';
import { EmptyStringValidator } from '../validators';
import { FormField, Field, Form } from '../field';


export interface CompanyInfoForm extends Form {
    values: {
        keyContactName: string;
        companyName: string;
        addressLineOne: string | undefined;
        addressLineTwo: string | undefined;
        city: string | undefined;
        state: string | undefined;
        zip: string | undefined;
        primaryPhone: string | undefined;
        alternatePhone: string | undefined;
    },
    fields: {
        keyContactName: Field<string>;
        companyName: Field<string>;
        addressLineOne: Field<string>;
        addressLineTwo: Field<string>;
        city: Field<string>;
        state: Field<string>;
        zip: Field<string>;
        primaryPhone: Field<string>;
        alternatePhone: Field<string>;
    }
}

export const fromModel = (model: IAccount): CompanyInfoForm => {

    let keyContactName = model.keyContactName;
    let companyName = model.companyName;
    let addressLineOne = model.address ? model.address.addressLineOne : '';
    let addressLineTwo = model.address ? model.address.addressLineTwo : '';
    let city = model.address ? model.address.city : '';
    let state = model.address ? model.address.state : '';
    let zip = model.address ? model.address.zip : '';
    let primaryPhone = model.address ? model.address.primaryPhone : '';
    let alternatePhone = model.address ? model.address.alternatePhone : '';

    return {
        type: 'form',
        valid: false,
        values: {
            keyContactName,
            companyName,
            addressLineOne,
            addressLineTwo,
            city,
            state,
            zip,
            primaryPhone,
            alternatePhone,
        },
        fields: {
            keyContactName: FormField({
                key: 'keyContactName',
                validators: [EmptyStringValidator()]
            }),
            companyName: FormField({
                key: 'companyName',
                validators: [EmptyStringValidator()]
            }),
            addressLineOne: FormField({
                key: 'addressLineOne',
                validators: [EmptyStringValidator()]
            }),
            addressLineTwo: FormField({
                key: 'addressLineTwo',
                validators: [EmptyStringValidator()]
            }),
            city: FormField({
                key: 'city',
                validators: [EmptyStringValidator()]
            }),
            state: FormField({
                key: 'state',
                validators: [EmptyStringValidator()]
            }),
            zip: FormField({
                key: 'zip',
                validators: [EmptyStringValidator()]
            }),
            primaryPhone: FormField({
                key: 'primaryPhone',
                validators: [EmptyStringValidator()]
            }),
            alternatePhone: FormField({
                key: 'alternatePhone',
                validators: [EmptyStringValidator()]
            })
        }
    };
}

export function toModel(form: CompanyInfoForm) {
    const model: IUpdateCompanyInfo = {
        keyContactName: form.values.keyContactName,
        companyName: form.values.companyName,
        addressLineOne: form.values.addressLineOne,
        addressLineTwo: form.values.addressLineTwo,
        city: form.values.city,
        state: form.values.state,
        zip: form.values.zip,
        primaryPhone: form.values.primaryPhone,
        alternatePhone: form.values.alternatePhone
    };
    return model;
}
