export function clearClasses(selector) {
	const el = getElement(selector);
	for (let index = 0; index < el.classList.length; index++) {
		const item = el.classList.item(index);
		el.classList.remove(item);
	}
}

export function addClass(selector, className) {
	const el = getElement(selector);
	el.classList.add(className);
}

function getElement(selector) {
	return document.querySelector(selector);
}

export function classes(...classes: string[]) {
	return classes.join(' ');
}


    
export function applyBodyClass(className: string | undefined) {
	if (className) {
		clearClasses('body');
		addClass('body', className);
	}
}
