import { device as Device } from 'aws-iot-device-sdk';

export interface IoTClientOptions {
	region: string;
	host: string;
	accessKeyId: string;
	secretAccessKey: string;
	sessionToken: string;
	clientId?: string;
	baseReconnectTimeMs?: number;
	maximumReconnectTimeMs?: number;
	debug?: boolean;
}

/**
 * Singleton class to hold mqtt device client instance
 */
export class IoTClient {
	public client: Device;

	public static create(createNewClient = false, options: IoTClientOptions) {
		if (createNewClient && IoTClient.instance) {
			IoTClient.instance.disconnect();
			IoTClient.instance = null;
		}

		if (IoTClient.instance) {
			return IoTClient.instance;
		}

		IoTClient.instance = new IoTClient(createNewClient, options);
		return IoTClient.instance;
	}

	public static getInstance(): IoTClient | null {
		return IoTClient.instance;
	}

	private static instance: IoTClient | null;

	/**
	 * Constructor
	 *
	 * @params {boolean} createNewClient - Whether or not to use existing client instance
	 */
	private constructor(createNewClient = false, options: IoTClientOptions) {
		this.initClient(options);
		if (options.debug) {
			this.attachDebugHandlers();
		}
	}

	/**
	 * Disconnect client
	 */
	public disconnect() {
		// So when I just saw this, my one logger wasn't working properly which means
		// that it wasn't communicating, should probably need to reconnect again (somehow).
		console.log('disconnect');
		this.client.end(true, () => {
			console.log('disconnected');
		});
	}

	/**
	 * Update device client with AWS identity credentials after logging in.
	 *
	 * @param {string} accessKeyId - Access Key Id
	 * @param {string} secretAccessKey - Secret Access Key
	 * @param {string} sessionToken - Session Token
	 */
	public updateWebSocketCredentials(
		accessKeyId: string,
		secretAccessKey: string,
		sessionToken: string,
		expirationDate: Date
	) {
		this.client.updateWebSocketCredentials(
			accessKeyId,
			secretAccessKey,
			sessionToken,
			expirationDate
		);
		
		this.client.on("error", (err) => console.log(err));
	}

	/**
	 * Attach a message handler
	 *
	 * @param {IoTClient~onMessageCallback} onNewMessageHandler - Callback that handles a new message
	 * @callback IoTClient~onMessageCallback
	 *
	 * @param {string} topic - Message topic
	 * @param {string} jsonPayload - Json encoded message payload
	 */
	public attachMessageHandler(
		onNewMessageHandler: (topic: string, payload: any) => void
	) {
		this.client.on('message', onNewMessageHandler);
	}

	/**
	 * Attach a connect handler
	 *
	 * @param {IoTClient~onConnectHandler} onConnectHandler - Callback that handles a new connection
	 *
	 * @callback IoTClient~onConnectHandler
	 * @param {Object} connack - Connack object
	 */
	public attachConnectHandler(onConnectHandler: () => void) {
		this.client.on('connect', () => {
			console.log('connected');
			onConnectHandler();
		});
	}

	/**
	 * Attach a close handler
	 *
	 * @param {IoTClient~onCloseHandler} onCloseHandler - Callback that handles closing connection
	 *
	 * @callback IoTClient~onCloseHandler
	 * @param {Object} err - Connection close error
	 */
	public attachCloseHandler(onCloseHandler: () => void) {
		this.client.on('close', () => {
			console.log('close');
			onCloseHandler();
		});
	}

	/**
	 * Publish to an MQTT topic
	 *
	 * @param {string} topic - Topic to publish to
	 * @param {string} message - JSON encoded payload to send
	 */
	public publish(topic, message) {
		this.client.publish(topic, message);
	}

	/**
	 * Subscribe to an MQTT topic
	 *
	 * @param {string} topic - Topic to subscribe to
	 */
	public subscribe(topic) {
		// console.log('subscribe', topic);
		this.client.subscribe(topic);
	}

	/**
	 * Unsubscribe from MQTT topic
	 *
	 * @param {string} topic - Topic to unsubscribe from
	 */
	public unsubscribe(topic) {
		// console.log('unsubscribe', topic);
		this.client.unsubscribe(topic);
		// console.log('unsubscribed from topic', topic);
	}

	/**
	 * Instantiate AWS IoT device object
	 * Note that the credentials must be initialized with empty strings;
	 * When we successfully authenticate to the Cognito Identity Pool,
	 * the credentials will be dynamically updated.
	 *
	 * @params {Object} options - Options to pass to DeviceSdk
	 */
	private initClient(options: IoTClientOptions) {
		const uniqueId = (new Date()).getTime();
		const clientId = `logic-beach-user-${uniqueId}`;

		this.client = new Device({
			region: options.region,

			// AWS IoT Host endpoint
			host: options.host,

			// clientId created earlier
			clientId: options.clientId || clientId,

			// Connect via secure WebSocket
			protocol: 'wss',

			// Set the maximum reconnect time to 500ms; this is a browser application
			// so we don't want to leave the user waiting too long for reconnection after
			// re-connecting to the network/re-opening their laptop/etc...
			baseReconnectTimeMs: options.baseReconnectTimeMs || 250,
			maximumReconnectTimeMs: options.maximumReconnectTimeMs || 500,

			// Enable console debugging information
			debug: options.debug,

			// AWS access key ID, secret key and session token must be
			// initialized with empty strings
			accessKeyId: options.accessKeyId,
			secretKey: options.secretAccessKey,
			sessionToken: options.sessionToken,

			autoResubscribe: true,
		});
	}

	/**
	 * Attach reconnect, offline, error, message debug handlers
	 */
	private attachDebugHandlers() {
		this.client.on('reconnect', () => {
			console.log('reconnect');
		});

		this.client.on('offline', () => {
			console.log('offline');
		});

		this.client.on('error', err => {
			console.log('iot client error', err);
		});

		this.client.on('message', (topic, message) => {
			console.log(topic);
		});
	}
}
