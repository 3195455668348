{#if hide}
    <div class="svelte-scrim" style="opacity: 0;"></div>
{:else}
    <div class="svelte-scrim"></div>
{/if}

<script>
    export default {
        tag: 'svelte-scrim',
        oncreate() {
            const length = document.querySelectorAll('.svelte-scrim').length;
            if (length > 1) {
                this.set({ hide: true });
            }
        },
        data() {
            return {
                hide: false
            }
        }
    }
</script>

<style>
    .svelte-scrim {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100vh;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
</style>