<svelte:component this={_component} {params} {router} {active} />

<script>
    import Navaid from 'navaid';
    import AccountRoutes from './routes';
    import { applyBodyClass } from 'Services/classes';
    import { loadComponent, trackRoute } from 'Services/router';

    export default {
        data() {
            return {
                baseUrl: '/',
                routes: [],
                bodyClass: 'account',
                _uri: location.pathname,
                _router: null,
                _component: undefined,
            }
        },
        computed: {
            active: ({ _uri }) => {
                _uri.split('/')[1] || 'home';
            }
        },
        oncreate() {
            const { bodyClass, routes, baseUrl } = this.get();
            applyBodyClass(bodyClass);

            const onDraw = this.draw.bind(this);
            const onTrack = this.track.bind(this);
            const router = Navaid(baseUrl)
                .on(AccountRoutes.Login, () => import('./Login.html').then(onDraw))
                .on(AccountRoutes.Changelog, () => import('./Changelog.html').then(onDraw))
                .on(AccountRoutes.Register, () => import('./Register.html').then(onDraw))
                .on(AccountRoutes.ForgotPassword, () => import('./ForgotPassword.html').then(onDraw))
                .on(AccountRoutes.ResetPassword, () => import('./ResetPassword.html').then(onDraw))
                .on(AccountRoutes.VerificationCode, () => import('./VerificationCode.html').then(onDraw))
                .on(AccountRoutes.NewPasswordRequired, () => import('./NewPasswordRequired.html').then(onDraw));
            
            this.set({ router });

            router.listen();
                
            addEventListener('replacestate', onTrack);
            addEventListener('pushstate', onTrack);
            addEventListener('popstate', onTrack); 
        }, 
        ondestroy() {
            const { router } = this.get();
            if (router) {
                router.unlisten;
            }
        },
        methods: {
            draw(m, p) {
                const { component, params } = loadComponent(m, p);
                this.set({ _component: component, params });
            },
            track(obj) {
                this.set({ _uri: trackRoute(obj) });
	        }
        }
    }
</script>