<div>
    {#await promise}
        <div class='spinner-block'>
            <Spinner />
        </div>
	{:then items}
		<div class='form-container'>
			<Notification className='inline-form-notification'
				open={!fields.deviceIds.valid}
				color="error">{fields.deviceIds.error}</Notification>
		</div>
        <DeviceTable bind:selected='values.deviceIds' 
            showCreateButton={false} 
            showDeleteButton={false} 
            selectOnly={true}
            {router} 
			groups={$groups}
            items={$devices} />
    {/await}
</div>

<script>
    import { Notification, Spinner } from 'Shared';
    import DeviceTable from '../../Devices/DeviceTable';
    import { validate } from 'Services/forms/validators';

    export default {
    	components: {
    		DeviceTable,
    		Notification,
    		Spinner,
    	},
    	oncreate() {
    		this.set({
    			promise: Promise.all([this.store.getDevices(), this.store.getGroups()]),
    		});
    	},
    	onupdate({ changed, current, previous }) {
    		validate(this, changed, current, previous, true, current.isEditing);
    	},
    	computed: {
    		form: ({ selectForm }) => selectForm,
    		values: ({ form }) => form.values,
    		fields: ({ form }) => form.fields,
    	},
    	data() {
    		return {
    			isEditing: false,
    			selected: {},
    		};
    	},
    };
</script>