<input  class="{className} {id}  date-picker-field flatpickr flatpickr-input active" 
        type="text" 
        :placeholder
        data
        on:change
        data-test={test_tag}
        data-input>

<script>
import Flatpickr from 'flatpickr';
import { v4 } from 'uuid';

let count = 0;
export default {
    computed: {
        value: ({ rawValue }) => {
            if (!rawValue) {
                return '';
            }
            return 
        }
    },
    oncreate() {
        const { id, rawValue } = this.get();
        const value = rawValue ? rawValue : '';

        Flatpickr(`.${id}`, {
            enableTime: true,
            altFormat: 'm/d/y h:i:S K',
            dateFormat: 'm/d/y h:i:S K',
            defaultDate: value,
            altInputClass: 'test-me'
        });
    },
    data() {
        return {
            className: '',
            placeholder: 'Select Date..',
            value: '',
            id: `flatpickr-${count++}`,
            test_tag: ''
        };
    },
}
</script>