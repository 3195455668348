const start = require('./main').default;
if (window.Sentry) {
	window.Sentry.init({
		dsn: 'https://b0120cf2854c498bb9296700438c0bf7@sentry.io/1390137',
	});
}

// TODO: potentially log these types of events to Sentry.
// window.onerror = function(message, file, line, col, error) {
// 	alert('!Error occurred: ' + error.message);
// 	return false;
// };
// window.addEventListener('error', function(e) {
// 	alert('!!Error occurred: ' + e.error.message);
// 	return false;
// });

// window.addEventListener('unhandledrejection', function(e) {
// 	alert('!!!Error occurred: ' + e.reason.message);
// });

let config = {};
if (window.global && window.global.AppConfig) {
	config = window.global.AppConfig;
} else if (global.AppConfig) {
	config = global.AppConfig;
}

start('#root', config);
