<Card className='modal-card'>
    <div class={blockClass}>
        <form>
            <fieldset>
                <div class='legend'>
                    <legend>
                        Add contact
                        <div class='secondary'>Select one or more contacts.</div>
                    </legend>
                </div>
                <div class='controls'>
                    {#await promise}
                        <Spinner />
                    {:then items}
                        <ContactsTable bind:selected='selected' {router} items={contacts} selectOnly={true} />    
                    {/await}
                </div>
            </fieldset>
            <div class='button-panel'>
                <Button test_tag='dialog-submit' 
					on:click='submit(event)' 
					color="primary">Add</Button>
                <Button test_tag='dialog-cancel' 
					on:click='cancel(event)' 
					className="cancel" 
					color="normal">Cancel</Button>
            </div>
        </form>

    </div>
</Card>


<script>
 import { Card, Button, Spinner } from 'Shared';
 import ContactsTable from '../Accounts/ContactsTable.html';

 export default {
 	components: {
 		Card,
 		Button,
 		Spinner,
 		ContactsTable,
 	},
 	oncreate() {
		 const { filterPhoneNumber } = this.get();
		 const promise = this.store.getContacts();
		 promise.then((items) => {
			this.set({
				contacts: filterPhoneNumber ? items.filter(item => !!item.phoneNumber) : items
			});
		 })
 		this.set({
 			promise
 		});
 	},
 	computed: {
 	},
 	data() {
 		return {
 			blockClass: 'add-contact',
 			disabled: true,
 			error: false,
 			selected: {},
			showMessage: false,
			filterPhoneNumber: false,
			 message: '',
			contacts: []
 		};
 	},
 	methods: {
 		submit(e) {
 			e.preventDefault();
 			const { selected } = this.get();
 			this.fire('dismiss', selected);
 		},
 		cancel(e) {
 			e.preventDefault();
 			this.fire('cancel');
 		},
 	},
 };
</script>